import React from 'react'

import IndeterminateCheckbox from '../components/IndeterminateCheckbox'
import IndeterminateCheckboxComplete from '../components/IndeterminateCheckboxComplete'
import IndeterminateCheckboxDropdown from '../components/IndeterminateCheckboxDropdown'

export default function addSelectionColumn({
  hooks,
  selectAll,
  hasPagination,
  hasCompleteSelect,
  onSelectAll,
  onSelectPage,
  onSelectRow,
  onSelectIdList,
  getIsSelectedAll,
  getData,
}) {
  hooks.visibleColumns.push((columns) => [
    {
      id: 'selection',
      width: '50px',
      disableSortBy: true,
      Header: ({
        getToggleAllRowsSelectedProps,
        getToggleAllPageRowsSelectedProps,
      }) =>
        selectAll ? (
          hasCompleteSelect ? (
            <IndeterminateCheckboxDropdown
              dark={false}
              onSelectAll={onSelectAll}
              onSelectPage={onSelectPage}
              onSelectIdList={onSelectIdList}
              getIsSelectedAll={getIsSelectedAll}
              getData={getData}
              {...(hasPagination
                ? getToggleAllPageRowsSelectedProps()
                : getToggleAllRowsSelectedProps())}
            />
          ) : (
            <IndeterminateCheckbox
              dark={false}
              {...(hasPagination
                ? getToggleAllPageRowsSelectedProps()
                : getToggleAllRowsSelectedProps())}
            />
          )
        ) : null,
      Cell: ({ row }) => {
        return hasCompleteSelect ? (
          <IndeterminateCheckboxComplete
            original={row.original}
            onSelectRow={onSelectRow}
          />
        ) : (
          <IndeterminateCheckbox
            {...row.getToggleRowSelectedProps()}
            onClick={(e) => {
              e.stopPropagation()
              if (row.getToggleRowSelectedProps().onClick)
                row.getToggleRowSelectedProps().onClick(e)
            }}
          />
        )
      },
    },
    ...columns,
  ])
}
