import { handleActions, combineActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  isUpdating: false,
  horusIntegration: false,
  homologationCode: null,
  id: null,
}

const beginLoading = combineActions(
  types.LOAD_RISK_ANALYSIS,
  types.LOAD_NOTICE_PROCESS_STEPS_RISK_ANALYSIS
)

const stopLoading = combineActions(
  types.LOAD_RISK_ANALYSIS_SUCCESS,
  types.LOAD_RISK_ANALYSIS_FAIL,
  types.LOAD_NOTICE_PROCESS_STEPS_RISK_ANALYSIS_SUCCESS,
  types.LOAD_NOTICE_PROCESS_STEPS_RISK_ANALYSIS_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_NOTICE_PROCESS_STEPS_RISK_ANALYSIS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      id: data.id,
      horusIntegration: data.horusIntegration,
      homologationCode: data?.homologationCode,
      isLoading: false,
    }),
    [types.UPDATE_NOTICE_PROCESS_STEPS_RISK_ANALYSIS]: (state) => ({
      ...state,
      isUpdating: true,
    }),
    [types.UPDATE_NOTICE_PROCESS_STEPS_RISK_ANALYSIS_FAIL]: (state) => ({
      ...state,
      isUpdating: false,
    }),
    [types.UPDATE_NOTICE_PROCESS_STEPS_RISK_ANALYSIS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      horusIntegration: data?.horusIntegration,
      homologationCode: data?.homologationCode,
      isUpdating: false,
    }),
    [types.SET_NOTICE_PROCESS_STEPS_RISK_ANALYSIS_HORUS_INTEGRATION]: (
      state,
      { horusIntegration }
    ) => ({
      ...state,
      horusIntegration,
    }),
    [types.SET_NOTICE_PROCESS_STEPS_RISK_ANALYSIS_HOMOLOGATION_CODE]: (
      state,
      { homologationCode }
    ) => ({
      ...state,
      homologationCode,
    }),
    [types.CLEAR_STATE]: () => {
      return { ...INITIAL_STATE }
    },
  },
  INITIAL_STATE
)

export default reducer
