import React from 'react'

import { SkipLinkStyle } from './styles'

function SkipLink() {
  return (
    <>
      <SkipLinkStyle href="#conteudo">Saltar para o conteúdo</SkipLinkStyle>
      <SkipLinkStyle href="#rodape">Saltar para o rodapé</SkipLinkStyle>
    </>
  )
}

export default SkipLink
