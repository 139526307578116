import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

import { loadProfileUser } from '~/views/Profile/store/actions'

import { AvatarIcon } from '~/styles/Icons'

import { Content, HeaderStyle, Logo, Nav, NavLink, NavMobile } from './styles'

import { Container, AccessibilityDropdown } from '~/components'

import LogoItau from '~/assets/images/logo-fit.svg'

import ProfileDropdown from './components/ProfileDropdown'
import SkipLink from './components/SkipLink'
import useShouldShowNoticesTab from './services/useShouldShowNoticesTab'

function Header() {
  const dispatch = useDispatch()
  const user = useShallowEqualSelector((state) => state.profile.user)

  useEffect(() => {
    dispatch(loadProfileUser())
  }, [dispatch])

  const shouldShowNoticesTab = useShouldShowNoticesTab({ profile: user })

  return (
    <HeaderStyle>
      <SkipLink />
      <Container>
        <Content>
          <h1>
            <Logo className="accessibility_invert" src={LogoItau} alt="Itaú Social" width="116" height="50" />
          </h1>

          <Nav>
            <NavLink to="/home" activeClassName="active">
              Início
              <span />
            </NavLink>
            {shouldShowNoticesTab ? (
              <NavLink to="/editais" activeClassName="active">
                Editais
                <span />
              </NavLink>
            ) : null}
            {[user.profileType].includes('ADMIN') ? (
              <NavLink to="/relatorios" activeClassName="active">
                Relatórios
                <span />
              </NavLink>
            ) : null}
            {user.profileType === 'ADMIN' ? (
              <NavLink to="/usuarios" activeClassName="active">
                Usuários
                <span />
              </NavLink>
            ) : null}
          </Nav>

          <ProfileDropdown
            name={user.nameOrCompany}
            avatar={user.profileImageUrl ? user.profileImageUrl : AvatarIcon}
          />
          <AccessibilityDropdown />
        </Content>

        <NavMobile>
          <NavLink to="/home/minhas-inscricoes" activeClassName="active">
            Início
            <span />
          </NavLink>
          {shouldShowNoticesTab ? (
            <NavLink to="/editais" activeClassName="active">
              Editais
              <span />
            </NavLink>
          ) : null}
          {[user.profileType].includes('ADMIN') ? (
              <NavLink to="/relatorios" activeClassName="active">
                Relatórios
                <span />
              </NavLink>
            ) : null}
          {user.profileType === 'ADMIN' ? (
              <NavLink to="/usuarios" activeClassName="active">
                Usuários
                <span />
              </NavLink>
            ) : null}
        </NavMobile>
      </Container>
    </HeaderStyle>
  )
}

export default Header
