import { combineActions, handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  brazilRegions: [],
  processSteps: [],
  questions: [],
  evaluators: [],
  users: [],
  filterEvaluators: [],
  filterSelectedEvaluators: [],
  noticeProcessSteps: [],
  subscriptionStepsStatus: [],
}

const beginLoading = combineActions(
  types.GET_BRAZIL_REGIONS,
  types.GET_PROCESS_STEPS,
  types.GET_SUBSCRIPTION_FORM_QUESTIONS,
  types.GET_EVALUATORS,
  types.SEARCH_USERS,
  types.FILTER_EVALUATORS,
  types.FILTER_SELECTED_EVALUATORS,
  types.GET_NOTICE_PROCESS_STEPS,
  types.GET_SUBSCRIPTION_STEPS_STATUS
)

const stopLoading = combineActions(
  types.GET_BRAZIL_REGIONS_SUCCESS,
  types.GET_BRAZIL_REGIONS_FAIL,
  types.GET_PROCESS_STEPS_SUCCESS,
  types.GET_PROCESS_STEPS_FAIL,
  types.GET_SUBSCRIPTION_FORM_QUESTIONS_SUCCESS,
  types.GET_SUBSCRIPTION_FORM_QUESTIONS_FAIL,
  types.GET_EVALUATORS_SUCCESS,
  types.GET_EVALUATORS_FAIL,
  types.SEARCH_USERS_SUCCESS,
  types.SEARCH_USERS_FAIL,
  types.FILTER_EVALUATORS_SUCCESS,
  types.FILTER_EVALUATORS_FAIL,
  types.FILTER_SELECTED_EVALUATORS_SUCCESS,
  types.FILTER_SELECTED_EVALUATORS_FAIL,
  types.GET_NOTICE_PROCESS_STEPS_SUCCESS,
  types.GET_NOTICE_PROCESS_STEPS_FAIL,
  types.GET_SUBSCRIPTION_STEPS_STATUS_SUCCESS,
  types.GET_SUBSCRIPTION_STEPS_STATUS_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.GET_BRAZIL_REGIONS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: brazilRegions },
        },
      }
    ) => ({
      ...state,
      brazilRegions,
    }),
    [types.GET_PROCESS_STEPS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: processSteps },
        },
      }
    ) => ({
      ...state,
      processSteps,
    }),
    [types.GET_NOTICE_PROCESS_STEPS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: noticeProcessSteps },
        },
      }
    ) => ({
      ...state,
      noticeProcessSteps,
    }),
    [types.GET_SUBSCRIPTION_STEPS_STATUS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: subscriptionStepsStatus },
        },
      }
    ) => ({
      ...state,
      subscriptionStepsStatus,
    }),
    [types.GET_SUBSCRIPTION_FORM_QUESTIONS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: questions },
        },
      }
    ) => ({
      ...state,
      questions,
    }),
    [types.GET_EVALUATORS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: evaluators },
        },
      }
    ) => ({
      ...state,
      evaluators,
    }),
    [types.SET_EVALUATORS]: (state, { evaluators }) => ({
      ...state,
      evaluators,
    }),
    [types.SEARCH_USERS]: (state, { users }) => ({
      ...state,
      users,
    }),
    [types.FILTER_EVALUATORS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: filterEvaluators },
        },
      }
    ) => ({
      ...state,
      filterEvaluators,
    }),
    [types.FILTER_SELECTED_EVALUATORS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: filterSelectedEvaluators },
        },
      }
    ) => ({
      ...state,
      filterSelectedEvaluators,
    }),
    [types.CLEAR_SELECTED_EVALUATORS]: (state) => ({
      ...state,
      filterSelectedEvaluators: [],
    }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
