import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Content, ContentLink } from './styles'

function ButtonTab(props) {
  return (
    <Content
      {...props}
      noText={!props.children}
      className={classnames(props.className, { active: props.active })}
    >
      {props.right && props.children}
      {props.icon && props.iconHover && (
        <span>
          <img src={props.icon} aria-hidden alt="" />
          <img src={props.iconHover} aria-hidden alt="" />
        </span>
      )}
      {!props.right && props.children}
    </Content>
  )
}

function ButtonTabLink(props) {
  return (
    <ContentLink
      {...props}
      noText={!props.children}
      className={classnames(props.className, { active: props.active })}
    >
      {props.right && props.children}
      {props.icon && props.iconHover && (
        <span>
          <img src={props.icon} aria-hidden alt="" />
          <img src={props.iconHover} aria-hidden alt="" />
        </span>
      )}
      {!props.right && props.children}
    </ContentLink>
  )
}

ButtonTab.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  children: PropTypes.string,
  iconHover: PropTypes.string,
  icon: PropTypes.string,
  right: PropTypes.bool,
  active: PropTypes.bool,
  size: PropTypes.number,
}

ButtonTabLink.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  href: PropTypes.string.isRequired,
  children: PropTypes.string,
  iconHover: PropTypes.string,
  icon: PropTypes.string,
  right: PropTypes.bool,
  active: PropTypes.bool,
  size: PropTypes.number,
}

ButtonTab.defaultProps = {
  type: 'button',
}

export { ButtonTab, ButtonTabLink }
