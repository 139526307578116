import React from 'react'

import Checkbox from '~/components/Checkbox'

const IndeterminateCheckboxComplete = ({ original, onSelectRow }) => {
  return (
    <Checkbox
      aria-label="Selecionar linha"
      dark
      checked={!!original?.isSelected}
      onChange={() => {
        if (onSelectRow) onSelectRow(original)
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    />
  )
}

export default IndeterminateCheckboxComplete
