import styled from 'styled-components'

export const CountStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CircleStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
  line-height: 18px;
  ${({ size }) => size && `height: ${size}px; width: ${size}px;`}
  ${({ countSize }) => countSize && `font-size: ${countSize}px;`}
  ${({ color }) => color && `background: ${color};`};
`

export const TextStyle = styled.span`
  margin-left: 5px;
  ${({ textFont }) => textFont && `font-family: ${textFont};`}
  ${({ textSize }) => textSize && `font-size: ${textSize}px;`}
  ${({ textColor }) => textColor && `color: ${textColor};`}
  ${({ textLineHeight }) =>
    textLineHeight && `line-height: ${textLineHeight}px;`}
`
