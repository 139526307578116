import './custom-theme/bootstrap.min.css'
import { createGlobalStyle } from 'styled-components'
import { ArrowDownWhiteIcon, ClockWhiteIcon, EqualIcon, EqualLightIcon, MinusLightIcon, PlusWhiteIcon, SearchLightIcon } from './Icons'

export default createGlobalStyle`
  [data-theme="accessibility-dark"] {
    * {
      box-shadow: unset !important;
    }

    img {filter: grayscale(1) contrast(120%);}
    img[src*="light"], img[src*="branco"], img[src*="white"] { filter: grayscale(1) contrast(120%); }
    .accessibility_invert { filter: grayscale(1) contrast(120%) invert(1); }

    p, h1, h2, h3, h4, h5, h6, span, label, small {
      color: var(--color-white) !important;
      opacity: 1;
      background: transparent;
      border-color: var(--color-white);
    }

    .tox-mbtn__select-label, .tox-tbtn__select-label {
      color: var(--color-black) !important;
    }

    .tox-collection__item {
      p, h1, h2, h3, h4, h5, h6, pre {
        color: var(--color-black) !important;
      }
    }

    .required {
      color: var(--color-red) !important;
      p {
        color: var(--color-red) !important;
      }
      img {
        filter: initial;
      }
    }

    .risk-box {
      background-color: var(--color-accessibilityGrey) !important;
      div {
        background-color: var(--color-transparent);
      }
      hr {
        background-color: var(--color-white);
      }

      input[type="checkbox"], input[type="radio"] {
        ::after {
          background-color: var(--color-accessibilityGrey);
          border-color: var(--color-white);
        }
        &:hover, &:focus {
          ::after {
            border-color: var(--color-white);
          }
        }

        ::before {
          background-color: var(--color-accessibilityGrey);
        }
      }
    }

    .button {
      color: var(--color-white) !important;
    }

    .form-value {
      border-color: var(--color-accessibilityGrey);
    }

    a {
      opacity: 1 !important;
      color: var(--color-accessibilityLink) !important;

      > span {
        background: var(--color-accessibilityLink);
      }
    }

    body, header, footer, div {
      background-color: var(--color-black);
      border-color: var(--color-black);
    }

    .row, div[md], div[xs], div[lg], div[sm], .menu-sides, .menu, .input-group {
      background: var(--color-transparent) !important;
      .inline {
        background: var(--color-transparent) !important;
        border-radius: 18px;
      }
    }

    .subscription-page {
      .box, .inline {
        background: var(--color-black) !important;
      }
    }

    .open-text-container, .ball {
      border-color: var(--color-accessibilityGrey);
    }

    .warning-section {
      border-color: var(--color-orange);
      div {
        background-color: var(--color-orange);
      }
    }

    .box-round {
      background-color: var(--color-accessibilityGrey);
    }

    footer {
      div {
        background-color: var(--color-accessibilityGrey);
      }

      background-color: var(--color-accessibilityGrey);

      hr {
        background-color: var(--color-white);
      }
    }

    .file-card {
      background-color: var(--color-accessibilityGrey);

      .icon {
        background-color: var(--color-black) !important;
      }

      &--unit {
        width: 366px;
      }

      div {
        background-color: var(--color-transparent);
      }
    }

    .box, .menu {
      border: 4px solid var(--color-accessibilityGrey);
      border-radius: 18px;
    }

    .box--no-border {
      border: none;
    }

    .box--no-bg {
      background-color: var(--color-transparent);
    }

    .menu-bottom, .menu-right {
      border-radius: 18px;
    }

    .vertical-line {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .card {
      div {
        background-color: var(--color-black);
      }

      background-color: var(--color-black);

      .card-header {
        background-color: var(--color-accessibilityGrey);
      }

      border: 4px solid var(--color-accessibilityGrey);
      border-radius: 18px;
    }

    .card-box {
      .card-header {
        background-color: var(--color-accessibilityGrey);

        div {
          background-color: var(--color-transparent);
        }
      }

      border: 4px solid var(--color-accessibilityGrey);
      border-radius: 18px;
    }

    hr {
      background-color: var(--color-accessibilityGrey);
      opacity: 1;
    }

    input, textarea {
      background-color: var(--color-black);
      color: var(--color-white);
      border-color: var(--color-white);

      ::placeholder { color: var(--color-white); opacity: .6 !important; }
      :focus { border-color: var(--color-white); }

      &.input-search {
        background-image: url(${SearchLightIcon});
      }

      &.error {
        border-color: var(--color-red) !important;
      }
    }

    .input-file {
      background-color: var(--color-accessibilityGrey);
      border-color: var(--color-accessibilityGrey);

      :hover {
        background-color: var(--color-accessibilityGrey);
        border-color: var(--color-accessibilityGrey);
        color: var(--color-black) !important;

        img {
          filter: grayscale(1) contrast(120%);

          &[src*="light"], &[src*="branco"] {
            filter: grayscale(1) contrast(120%) invert(1);
          }
        }
      }

      img {
        filter: grayscale(1) contrast(120%) invert(1);

        &[src*="light"], &[src*="branco"] {
          filter: grayscale(1) contrast(120%);
        }
      }
    }

    .input-search-list {
      ul {
        border: 1px solid var(--color-white) !important;
        background: var(--color-accessibilityGrey);
      }
    }

    .select__control {
      .select__placeholder, .select__single-value {
        color: var(--color-white) !important;
      }
      .select__indicators img {
        filter: invert(1);
      }
      background-color: var(--color-black);
      border-color: var(--color-white) !important;
    }

    .select__menu {
      border: 1px solid var(--color-white) !important;
      background-color: var(--color-accessibilityGrey);
      .select__menu-list {
        background-color: var(--color-accessibilityGrey);
        .select__option {
          background-color: var(--color-accessibilityGrey);
          color: var(--color-white) !important;

          :hover {
            background-color: var(--color-black);
          }

          &.select__option--is-selected {
            background-color: var(--color-black);
          }
        }
      }
    }

    .dropdown-button {
      background-image: url(${ArrowDownWhiteIcon});
    }

    input[type="checkbox"], input[type="radio"] {
      ::after {
        background-color: var(--color-black);
      }
      &:hover, &:focus {
        ::after {
          border-color: var(--color-accessibilityGrey);
        }
      }

      ::before {
        background-color: var(--color-black);
      }
    }

    .dropdown {
      background-color: var(--color-accessibilityGrey);
      border-color: var(--color-accessibilityGrey);
      li {
        color: var(--color-white);
        border-color: var(--color-white);
        button {
          background-color: var(--color-black);
          :hover {
            color: var(--color-white) !important;
          }
        }
        > button { background-color: transparent !important; }
      }
      li:hover {
        background-color: transparent;
      }
      div { background-color: transparent }
    }
    .accessibility-dropdown-button {
      > button {
        background-color: var(--color-accessibilityGrey);
      }
      ul {
        li {
          button {
            :hover {
              color: var(--color-black) !important;
              background-color: var(--color-accessibilityGrey);

              img {
                filter: grayscale(1) contrast(120%);

                &[src*="light"], &[src*="branco"] {
                  filter: grayscale(1) contrast(120%) invert(1);
                }
              }
            }

            img {
              filter: grayscale(1) contrast(120%) invert(1);

              &[src*="light"], &[src*="branco"] {
                filter: grayscale(1) contrast(120%);
              }
            }
          }
        }
      }
    }
    .accessibility-button > button {
      background-color: var(--color-accessibilityGrey);
      color: var(--color-white);

      :hover {
        color: var(--color-black) !important;
        border-color: var(--color-accessibilityGrey);

        img {
          filter: grayscale(1) contrast(120%);

          &[src*="light"], &[src*="branco"] {
            filter: grayscale(1) contrast(120%) invert(1);
          }
        }
      }

      img {
        filter: grayscale(1) contrast(120%) invert(1);

        &[src*="light"], &[src*="branco"] {
          filter: grayscale(1) contrast(120%);
        }
      }
    }
    .pagination-button {
      &.active {
        background-color: var(--color-accessibilityGrey);
      }
      border-color: var(--color-accessibilityGrey);
      color: var(--color-white);
    }

    thead tr {
      background-color: var(--color-transparent);

      th {
        background-color: var(--color-transparent);
        color: var(--color-white);
      }
    }

    tbody tr {
      border-color: var(--color-transparent);

      td {
        color: var(--color-white) !important;
        background-color: var(--color-accessibilityGrey);

        input[type="checkbox"], input[type="radio"] {
          ::after {
            background-color: var(--color-accessibilityGrey);
            border-color: var(--color-white);
          }
          &:hover, &:focus {
            ::after {
              border-color: var(--color-white);
            }
          }

          ::before {
            background-color: var(--color-accessibilityGrey);
          }
        }

        .icon {
          background-color: var(--color-black) !important;
        }
      }

      div {
        background-color: var(--color-transparent);
      }
    }

    .notice-table {
      border-color: var(--color-accessibilityGrey);

      td {
        background-color: var(--color-transparent);
        input[type="checkbox"], input[type="radio"] {
          ::after {
            background-color: var(--color-black);
          }
          &:hover, &:focus {
            ::after {
              border-color: var(--color-accessibilityGrey);
            }
          }

          ::before {
            background-color: var(--color-black);
          }
        }
      }
    }

    .ReactModal__Content {
      .container {
        background: none;
        color: var(--color-white);

        button:hover {
          color: var(--color-white);
        }

        span, hr {
          background-color: var(--color-accessibilityGrey);
        }

        span {
          &.MuiTouchRipple-root, &.MuiIconButton-label {
            background: transparent;
          }
        }

        div {
          border-color: var(--color-accessibilityGrey);
        }

        .header {
          background: var(--color-accessibilityGrey);
        }

        .close {
          background-color: var(--color-black);
        }

        .tox-editor-container span {
          background-color: var(--color-white);
        }
      }
    }

    .icon {
      background: var(--color-accessibilityGrey) !important;
      border-radius: 50%;
      color: var(--color-white);

      :hover {
        img {
          filter: grayscale(1) contrast(120%);

          &[src*="light"], &[src*="branco"] {
            filter: grayscale(1) contrast(120%);
          }
        }
      }

      img {
        filter: grayscale(1) contrast(120%) invert(1);

        &[src*="light"], &[src*="branco"] {
          filter: grayscale(1) contrast(120%);
        }
      }
    }

    .tab-menu {
      .title {
        color: var(--color-white);
      }

      a {
        &.active {
          background: var(--color-white);

          div {
            color: var(--color-black);
            background: none;
          }
        }

        span {
          background: var(--color-accessibilityGrey);
        }
      }

      .link-expansive {
        button {
          background: none;
        }

        div {
          color: var(--color-accessibilityLink);
          background: none;

          a {
            &.active {
              color: var(--color-black) !important;
            }
          }
        }
      }
    }

    .outside {
      fill: var(--color-white) !important;
    }

    .MuiDialog-container {
      background-color: rgb(42, 42, 42);
      .MuiPickersDay-current span p {
        color: var(--color-accessibilityLink) !important;
      }
      .MuiPickersDay-daySelected, .MuiToolbar-root {
        background-color: var(--color-accessibilityGrey);
      }
      .MuiPickersCalendarHeader-iconButton {
        background-color: var(--color-black);
      }
      .MuiPickersYear-root {
        color: var(--color-white) !important;
        &.MuiPickersYear-yearSelected {
          color: var(--color-accessibilityLink) !important;
        }
      }
    }



    .MuiInputBase-root {
      border-color: var(--color-white) !important;

      .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-white) !important;
      }

      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: var(--color-white) !important;
        }
      }

      input {
        ::placeholder {
          color: var(--color-white);
        }
      }
    }

    .time-picker {
      input {
        background: url(${ClockWhiteIcon});
        background-size: 36px 33px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center left 12px;
      }
      ul {
        background-color: var(--color-accessibilityGrey);
        li {
          background-color: var(--color-accessibilityGrey);
          color: var(--color-white) !important;
        }
      }
    }

    .picker {
      ul {
        background-color: var(--color-accessibilityGrey);
        li {
          background-color: var(--color-accessibilityGrey);
          color: var(--color-white) !important;
        }
      }
    }

    .MuiCircularProgress-root {
      background-color: var(--color-transparent);
      .MuiCircularProgress-svg {
        color: var(--color-white);
      }
    }

    .equal-icon {
      background: url(${EqualLightIcon});
    }

    .tabMenuExpansive {
      .expand-icon {
        background: var(--color-accessibilityGrey);
        background-image: url(${PlusWhiteIcon});
        background-repeat: no-repeat;
        background-size: 15px 15px;

        &.isOpen {
          background: var(--color-accessibilityGrey);
          background-image: url(${MinusLightIcon});
          background-repeat: no-repeat;
          background-size: 15px 15px;
        }
      }

      :hover, :focus {
        .expand-icon {
          background: var(--color-accessibilityGrey);
          background-image: url(${PlusWhiteIcon});
          background-repeat: no-repeat;
          background-size: 15px 15px;

          &.isOpen {
            background: var(--color-accessibilityGrey);
            background-image: url(${MinusLightIcon});
            background-repeat: no-repeat;
            background-size: 15px 15px;
          }
        }
      }
    }
  }

  [data-theme="accessibility-light"] {
    * {
      box-shadow: unset !important;
    }

    img {filter: grayscale(1) contrast(120%);}
    img[src*="light"], img[src*="branco"], img[src*="white"] { filter: grayscale(1) contrast(120%) invert(1); }
    .accessibility_invert { filter: grayscale(1) contrast(120%); }

    p, h1, h2, h3, h4, h5, h6, span, label, small {
      color: var(--color-white) !important;
      opacity: 1;
      background: transparent;
      border-color: var(--color-white);
    }

    .tox-mbtn__select-label, .tox-tbtn__select-label {
      color: var(--color-white) !important;
    }

    .tox-collection__item {
      p, h1, h2, h3, h4, h5, h6, pre {
        color: var(--color-white) !important;
      }
    }

    .required {
      color: var(--color-red) !important;
      p {
        color: var(--color-red) !important;
      }
      img {
        filter: initial;
      }
    }

    .risk-box {
      background-color: var(--color-accessibilityGrey) !important;
      color: var(--color-black) !important;
      div {
        background-color: var(--color-transparent);
      }
      hr {
        background-color: var(--color-white);
      }

      input[type="checkbox"], input[type="radio"] {
        ::after {
          background-color: var(--color-accessibilityGrey);
          color: var(--color-black) !important;
          border-color: var(--color-white);
        }
        &:hover, &:focus {
          ::after {
            border-color: var(--color-white);
          }
        }

        ::before {
          background-color: var(--color-accessibilityGrey);
          color: var(--color-black) !important;
        }
      }
    }

    .button {
      color: var(--color-white) !important;
    }

    .form-value {
      border-color: var(--color-accessibilityGrey);
    }

    a {
      opacity: 1 !important;
      color: var(--color-accessibilityLink) !important;

      > span {
        background: var(--color-accessibilityLink);
      }
    }

    body, header, footer, div {
      background-color: var(--color-black);
      border-color: var(--color-black);
    }

    .row, div[md], div[xs], div[lg], div[sm], .menu-sides, .menu, .input-group {
      background: var(--color-transparent) !important;
      .inline {
        background: var(--color-transparent) !important;
        border-radius: 18px;
      }
    }

    .subscription-page {
      .box, .inline {
        background: var(--color-black) !important;
      }
    }

    .open-text-container, .ball {
      border-color: var(--color-accessibilityGrey);
    }

    .warning-section {
      border-color: var(--color-orange);
      div {
        background-color: var(--color-orange);
      }
    }

    .box-round {
      background-color: var(--color-accessibilityGrey);
      color: var(--color-black) !important;
    }

    footer {
      div {
        background-color: var(--color-accessibilityGrey);
        color: var(--color-black) !important;
      }

      background-color: var(--color-accessibilityGrey);
      color: var(--color-black) !important;

      hr {
        background-color: var(--color-white);
      }
    }

    .file-card {
      background-color: var(--color-accessibilityGrey);
      color: var(--color-black) !important;

      .icon {
        background-color: var(--color-white) !important;
      }

      &--unit {
        width: 366px;
      }

      div {
        background-color: var(--color-transparent);
      }

      button {
        background-color: var(--color-black);
      }
    }

    .box, .menu {
      border: 4px solid var(--color-accessibilityGrey);
      border-radius: 18px;
    }

    .box--no-border {
      border: none;
    }

    .box--no-bg {
      background-color: var(--color-transparent);
    }

    .menu-bottom, .menu-right {
      border-radius: 18px;
    }

    .vertical-line {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .card {
      div {
        background-color: var(--color-black);
      }

      background-color: var(--color-black);

      .card-header {
        background-color: var(--color-accessibilityGrey);
        color: var(--color-black) !important;
      }

      border: 4px solid var(--color-accessibilityGrey);
      border-radius: 18px;
    }

    .card-box {
      .card-header {
        background-color: var(--color-accessibilityGrey);
        color: var(--color-black) !important;

        div {
          background-color: var(--color-transparent);
        }
      }

      border: 4px solid var(--color-accessibilityGrey);
      border-radius: 18px;
    }

    hr {
      background-color: var(--color-accessibilityGrey);
      color: var(--color-black) !important;
      opacity: 1;
    }

    input, textarea {
      background-color: var(--color-black);
      color: var(--color-white);
      border-color: var(--color-white);

      ::placeholder { color: var(--color-white); opacity: .6 !important; }
      :focus { border-color: var(--color-white); }

      &.error {
        border-color: var(--color-red) !important;
      }
    }

    .input-file {
      background-color: var(--color-accessibilityGrey);
      border-color: var(--color-accessibilityGrey);
      color: var(--color-white) !important;

      :hover {
        background-color: var(--color-accessibilityGrey);
        border-color: var(--color-accessibilityGrey);
        color: var(--color-black) !important;

        img {
          filter: grayscale(1) contrast(120%) invert(1);

          &[src*="light"], &[src*="branco"], &[src*="white"] {
            filter: grayscale(1) contrast(120%);
          }
        }
      }

      img {
        filter: grayscale(1) contrast(120%);

        &[src*="light"], &[src*="branco"], &[src*="white"] {
          filter: grayscale(1) contrast(120%) invert(1);
        }
      }
    }

    .input-search-list {
      ul {
        border: 1px solid var(--color-white) !important;
        background: var(--color-accessibilityGrey);
      }
    }

    .select__control {
      .select__placeholder, .select__single-value {
        color: var(--color-white) !important;
      }
      .select__indicators img {
        filter: invert(1);
      }
      background-color: var(--color-black);
      border-color: var(--color-white) !important;
    }

    .select__menu {
      border: 1px solid var(--color-white) !important;
      background-color: var(--color-accessibilityGrey);
      color: var(--color-black) !important;
      .select__menu-list {
        background-color: var(--color-accessibilityGrey);
        color: var(--color-black) !important;
        .select__option {
          background-color: var(--color-accessibilityGrey);
          color: var(--color-black) !important;

          :hover {
            background-color: var(--color-white);
          }

          &.select__option--is-selected {
            background-color: var(--color-white);
          }
        }
      }
    }

    input[type="checkbox"], input[type="radio"] {
      ::after {
        background-color: var(--color-black);
      }
      &:hover, &:focus {
        ::after {
          border-color: var(--color-accessibilityGrey);
        }
      }

      ::before {
        background-color: var(--color-black);
      }
    }

    .dropdown {
      background-color: var(--color-accessibilityGrey);
      border-color: var(--color-accessibilityGrey);
      li {
        color: var(--color-white);
        border-color: var(--color-white);
        button {
          background-color: var(--color-black);
          :hover {
            color: var(--color-white) !important;
          }
        }
        > button { background-color: transparent !important; }
      }
      li:hover {
        background-color: transparent;
      }
      div { background-color: transparent }
    }
    .accessibility-dropdown-button {
      > button {
        background-color: var(--color-accessibilityGrey);
      }
      ul {
        li {
          button {
            color: var(--color-black) !important;
            :hover {
              color: var(--color-white) !important;
              background-color: var(--color-accessibilityGrey);

              img {
                filter: grayscale(1) contrast(120%);

                &[src*="light"], &[src*="branco"], &[src*="white"] {
                  filter: grayscale(1) contrast(120%) invert(1);
                }
              }
            }

            img {
              filter: grayscale(1) contrast(120%) invert(1);

              &[src*="light"], &[src*="branco"], &[src*="white"] {
                filter: grayscale(1) contrast(120%);
              }
            }
          }
        }
      }
    }
    .accessibility-button {
      button {
        background-color: var(--color-accessibilityGrey);
        color: var(--color-white) !important;
        border-color: var(--color-accessibilityGrey);

        :hover {
          color: var(--color-black) !important;
          border-color: var(--color-accessibilityGrey);

          img {
            filter: grayscale(1) contrast(120%) invert(1);

            &[src*="light"], &[src*="branco"], &[src*="white"] {
              filter: grayscale(1) contrast(120%);
            }
          }
        }
      }

      color: var(--color-white) !important;
      p {
        color: var(--color-white) !important;
      }
    }
    .pagination-button {
      &.active {
        background-color: var(--color-accessibilityGrey);
        color: var(--color-white) !important;
      }
      border-color: var(--color-accessibilityGrey);
      color: var(--color-white);
    }

    thead tr {
      background-color: var(--color-transparent);

      th {
        background-color: var(--color-transparent);
        color: var(--color-white);
      }
    }

    tbody tr {
      border-color: var(--color-transparent);

      td {
        background-color: var(--color-accessibilityGrey);
        color: var(--color-white) !important;

        .accessibility-button {
          a {
            background-color: var(--color-black);
          }
        }

        input[type="checkbox"], input[type="radio"] {
          ::after {
            background-color: var(--color-accessibilityGrey);
            border-color: var(--color-white);
          }
          &:hover, &:focus {
            ::after {
              border-color: var(--color-white);
            }
          }

          ::before {
            background-color: var(--color-accessibilityGrey);
          }
        }

        .icon {
          background-color: var(--color-white) !important;
        }
      }

      div {
        background-color: var(--color-transparent);
      }
    }

    .notice-table {
      border-color: var(--color-accessibilityGrey);

      td {
        background-color: var(--color-transparent);
        color: var(--color-white) !important;
        input[type="checkbox"], input[type="radio"] {
          ::after {
            background-color: var(--color-black);
          }
          &:hover, &:focus {
            ::after {
              border-color: var(--color-accessibilityGrey);
            }
          }

          ::before {
            background-color: var(--color-black);
          }
        }
      }
    }

    .ReactModal__Content {
      .container {
        background: none;
        color: var(--color-white);

        button:hover {
          color: var(--color-white);
        }

        span, hr {
          background-color: var(--color-accessibilityGrey);
        }

        span {
          &.MuiTouchRipple-root, &.MuiIconButton-label {
            background: transparent;
          }
        }

        div {
          border-color: var(--color-accessibilityGrey);
        }

        .header {
          background: var(--color-accessibilityGrey);
        }

        .close {
          background-color: var(--color-black);
        }

        .tox-editor-container span {
          background-color: var(--color-white);
        }
      }
    }

    .tox-editor-container {
      border: 1px solid;
    }

    .icon {
      background: var(--color-accessibilityGrey) !important;
      border-radius: 50%;
      color: var(--color-black) !important;

      img {
        filter: grayscale(1) contrast(120%) invert(1);

        &[src*="light"], &[src*="branco"], &[src*="white"] {
          filter: grayscale(1) contrast(120%);
        }
      }
    }

    .tab-menu {
      .title {
        color: var(--color-white);
      }

      a {
        &.active {
          background: var(--color-white);

          div {
            color: var(--color-black);
            background: none;
          }
        }

        span {
          background: var(--color-accessibilityGrey);
        }
      }

      .link-expansive {
        button {
          background: none;
        }

        div {
          color: var(--color-accessibilityLink);
          background: none;

          a {
            &.active {
              color: var(--color-black) !important;
            }
          }
        }
      }
    }

    .outside {
      fill: var(--color-white) !important;
    }

    .MuiDialog-container {
      background-color: rgb(42, 42, 42);
      .MuiPickersDay-current span p {
        color: var(--color-accessibilityLink) !important;
      }
      .MuiPickersDay-daySelected, .MuiToolbar-root {
        background-color: var(--color-accessibilityGrey);
      }
      .MuiPickersCalendarHeader-iconButton {
        background-color: var(--color-black);
      }
      .MuiPickersYear-root {
        color: var(--color-white) !important;
        &.MuiPickersYear-yearSelected {
          color: var(--color-accessibilityLink) !important;
        }
      }
    }

    .MuiInputBase-root {
      border-color: var(--color-white) !important;

      .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-white) !important;
      }

      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: var(--color-white) !important;
        }
      }

      input {
        ::placeholder {
          color: var(--color-white);
        }
      }
    }

    .time-picker {
      /* input {
        background-size: 36px 33px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center left 12px;
      } */
      ul {
        background-color: var(--color-accessibilityGrey);
        li {
          background-color: var(--color-accessibilityGrey);
        }
      }
    }

    .picker {
      ul {
        background-color: var(--color-accessibilityGrey);
        li {
          background-color: var(--color-accessibilityGrey);
        }
      }
    }

    .MuiCircularProgress-root {
      background-color: var(--color-transparent);
      .MuiCircularProgress-svg {
        color: var(--color-white);
      }
    }

    .equal-icon {
      background: url(${EqualIcon});
    }

    .alert {
      p {
        color: var(--color-black) !important;
      }
    }

    .tabMenuExpansive {
      .expand-icon {
        background: var(--color-white);
        background-image: url(${PlusWhiteIcon});
        background-repeat: no-repeat;
        background-size: 15px 15px;

        &.isOpen {
          background: var(--color-white);
          background-image: url(${MinusLightIcon});
          background-repeat: no-repeat;
          background-size: 15px 15px;
        }
      }

      :hover, :focus {
        .expand-icon {
          background: var(--color-white);
          background-image: url(${PlusWhiteIcon});
          background-repeat: no-repeat;
          background-size: 15px 15px;

          &.isOpen {
            background: var(--color-white);
            background-image: url(${MinusLightIcon});
            background-repeat: no-repeat;
            background-size: 15px 15px;
          }
        }
      }
    }
  }
`
