import styled from 'styled-components'
import { Link as LinkComponent } from 'react-router-dom'



export const FooterStyle = styled.footer`
  background: var(--color-dark);
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 55px 0 50px 0;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 798px) {
    flex-direction: column;
  }
`

export const Logo = styled.img`
  flex-shrink: 0;
  width: 116px;
  height: 50px;
  align-self: initial;

  @media (min-width: 798px) {
    margin-right: 99px;
  }
`

export const Links = styled.div`
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 798px) {
    margin: 30px 0 10px 0;
  }

  @media (max-width: 510px) {
    margin-top: 50px;
    flex-direction: column;
  }
`

export const Link = styled(LinkComponent)`
  color: var(--color-white);
  font-family: 'Itau Display Regular';
  font-size: 18px;
  line-height: 23px;
  position: relative;
  padding: 3px;

  span {
    display: none;
    position: absolute;
    background: var(--color-yellow);
    height: 5px;
    width: 100%;
    top: 29px;
    left: 0;
  }

  &:hover,
  &:focus,
  &.active {
    text-decoration: none;
    color: var(--color-yellow);
  }

  & + & {
    margin-left: 30px;

    @media (max-width: 510px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`

export const Website = styled.a`
  flex-shrink: 0;
  margin-left: 14px;
  display: flex;
  width: 201px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  padding-right: 0px;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-white);

  > img {
    margin-left: 10px;
    height: 40px;
    width: 40px;
  }

  &:hover,
  &:focus,
  &.active {
    text-decoration: none;
    color: var(--color-yellow);
  }

  @media (max-width: 798px) {
    width: auto;
    margin-right: -50px;
    padding-left: 0;
    margin-left: 0;
  }
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  justify-content: space-between;

  @media (max-width: 510px) {
    flex-direction: column;
  }
`

export const Copyright = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-darkGray);
`

export const BottomLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 510px) {
    margin-top: 20px;
  }
`

export const BottomLinksDivisor = styled.span`
  border-left: 2px solid var(--color-darkGray);
  height: 30px;
  margin: 0 20px;
`

export const BottomLink = styled.a`
  font-family: 'Itau Display Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-darkGray);
  text-decoration: underline;

  &:hover,
  &:focus,
  &.active {
    text-decoration: underline;
    color: var(--color-white);
  }

  @media (max-width: 510px) {
    width: 130px;

    &:first-of-type {
      text-align: right;
    }
  }
`
