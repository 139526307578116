import React from 'react'
import PropTypes from 'prop-types'

import { Content } from './styles'

function P(props) {
  return <Content {...props} />
}

P.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  font: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  line: PropTypes.number,
}

export default P
