import client from '~/boot/client'

export async function getEvaluations(evaluatorId) {
  try {
    const response = await client.get(
      `/api/evaluators/${evaluatorId}/subscriptions`
    )
    return response.data.data
  } catch (e) {
    return null
  }
}

export async function getActiveEvaluations() {
  try {
    const response = await client.get(
      `/api/home/my-evaluations`
    )
    return response.data.data
  } catch (e) {
    return null
  }
}

export async function getPastEvaluations({
  term = '',
  sortBy = [],
  pageIndex = 0,
  pageSize = 10
}) {
  try {
    const response = await client.get(
      `/api/home/my-evaluations/search/past?&pageIndex=${pageIndex}&pageSize=${pageSize}&term=${term}&sortBy=${JSON.stringify(sortBy)}`
    )
    return response.data.data
  } catch (e) {
    return null
  }
}
