export default function getHeaderProps(column) {
  return column.getHeaderProps([
    {
      ...(column.header && column.header),
      style: {
        color: column.header && column.header.color,
        ...(column.header && column.header.style && column.header.style),
      },
    },
  ])
}
