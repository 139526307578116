import React from 'react'
import fonts from '~/utils/fonts'
import colors from '~/utils/colors'
import { DownloadIcon, DownloadLightIcon } from '~/styles/Icons'
import { CardInfo, StyledInline } from './styles'
import {
  ButtonCircleIconLinkExternal,
  Col,
  H4,
  T3,
} from '~/components'

const FileCard = ({ cardInfo, extension, filename, downloadUrl, ...props }) => {
  return (
    <Col lg={12} {...props}>
      <CardInfo>{cardInfo}</CardInfo>
      <StyledInline className="file-card px-3 mb-3">
        <H4
          font={fonts.displayXBold}
          color={colors.gray}
          className="pr-3"
          style={{ textTransform: 'uppercase' }}
        >
          {extension}
        </H4>
        <T3
          font={fonts.displayRegular}
          style={{
            marginRight: 'auto',
            maxWidth: '60%',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {filename}
        </T3>
        <ButtonCircleIconLinkExternal
          color={colors.white}
          icon={DownloadIcon}
          iconHover={DownloadLightIcon}
          aria-label={`Efetuar download do arquivo ${filename}`}
          href={downloadUrl}
          target="_blank"
          rel="noopener noreferrer"
          download
        />
      </StyledInline>
    </Col>
  )
}

export default FileCard
