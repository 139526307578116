import store from '~/boot/store'

import { loadOrCreateNoticeEvaluationCriteriaForm } from '../store/actions'

const loadOrCreateForm = (id) => {
  if (id) {
    store.dispatch(loadOrCreateNoticeEvaluationCriteriaForm(id))
  }
}

export default loadOrCreateForm
