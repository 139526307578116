import types from './types'
import history from '~/utils/history'

export function loadNotices(params) {
  return async (dispatch) => {
    try {
      const { payload } = await dispatch({
        type: types.LOAD_NOTICES,
        payload: {
          request: {
            url: `/api/notices?${params ? '&' + params : ''}`,
            method: 'GET',
          },
        },
      })

      console.log('payload', payload.data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function loadNotice(id) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.LOAD_NOTICE,
        payload: {
          request: {
            url: `/api/notices/${id}`,
            method: 'GET',
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function storeNotice() {
  return async (dispatch) => {
    try {
      const {
        payload: { data },
      } = await dispatch({
        type: types.STORE_NOTICE,
        payload: {
          request: {
            url: '/api/notices',
            method: 'POST',
            data: { status: 'DRAFT' },
          },
        },
      })

      if (!data) return alert('Error ao criar edital')

      if (data.data.id) history.push(`/editais/${data.data.id}/dados-gerais`)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function deleteNotice(noticeId) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.DELETE_NOTICE,
        payload: {
          request: {
            url: `/api/notices/${noticeId}`,
            method: 'DELETE',
          },
        },
      })

      console.log('payload', data)
      if (!data.payload.data.success) return alert('Erro ao deletar edital')

      history.push(`/editais`)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function searchNoticeByNamePublish(term, sortBy) {
  return async (dispatch) => {
    if (!term) return

    try {
      return await dispatch({
        type: types.SEARCH_NOTICES_BY_NAME_PUBLISH,
        payload: {
          request: {
            url: `/api/search-notices-by-name-publish?term=${term}&sortBy=${JSON.stringify(
              sortBy
            )}`,
            method: 'GET',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function getBrazilRegions() {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: types.GET_BRAZIL_REGIONS,
        payload: {
          request: {
            url: `/api/brazil-regions`,
            method: 'GET',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function getProcessSteps() {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: types.GET_PROCESS_STEPS,
        payload: {
          request: {
            url: `/api/process-steps`,
            method: 'GET',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function setNotices(notices) {
  return { type: types.SET_NOTICES, notices }
}

export function setNotice(notice) {
  return { type: types.SET_NOTICE, notice }
}

export function clearState() {
  return { type: types.CLEAR_STATE }
}
