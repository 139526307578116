import React from 'react'

import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'
import {
  Bottom,
  BottomLink,
  BottomLinks,
  BottomLinksDivisor,
  Content,
  Copyright,
  FooterStyle,
  Link,
  Links,
  Logo,
  Website,
} from './styles'
import { Container, HorizontalRule } from '~/components'

import LogoItau from '~/assets/images/logo-fit-negativo.svg'
import { ExternalLight } from '~/styles/Icons'

function Footer(props) {
  const user = useShallowEqualSelector((state) => state.profile.user)

  return (
    <FooterStyle {...props}>
      <Container>
        <Content>
          <Logo
            src={LogoItau}
            alt="Logo na cor branca do Itaú Social"
            width="116"
            height="50"
          />
          <Links>
            <Link to="/home/minhas-inscricoes">
              Início
              <span />
            </Link>
            {user.noticesOccupations.includes('MANAGER') ? (
              <Link to="/editais">
                Editais
                <span />
              </Link>
            ) : null}
            {[user.profileType].includes('ADMIN') ? (
              <Link to="/relatorios">
                Relatórios
                <span />
              </Link>
            ) : null}
            {/*<Link to="/editais">
              Editais
              <span />
            </Link>
            {/*
            <Link to="/relatorios">
              Relatórios
              <span />
            </Link>*/}
            {user.profileType === 'ADMIN' ? (
              <Link to="/usuarios">
                Usuários
                <span />
              </Link>
            ) : null}
          </Links>

          <Website
            href="https://www.itausocial.org.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Site institucional
            <img
              src={ExternalLight}
              width="40"
              height="40"
              alt="Ir para o site institucional"
              aria-hidden={true}
            />
          </Website>
        </Content>
      </Container>

      <HorizontalRule height={1} color={'yellow'} />

      <Container>
        <Bottom>
          <Copyright>Todos os direitos © 2024</Copyright>
          <BottomLinks>
            <BottomLink
              href="https://projetodw-producao.s3.sa-east-1.amazonaws.com/AEdu5dx8cnw8Bq6PA5nD7QkppynyFWWzp8tM33GjKVSNvcHWeFYuZgvfwBQm/termo-de-uso-politica-de-privacidade.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termos de uso
            </BottomLink>
            <BottomLinksDivisor />
            <BottomLink
              href="https://projetodw-producao.s3.sa-east-1.amazonaws.com/AEdu5dx8cnw8Bq6PA5nD7QkppynyFWWzp8tM33GjKVSNvcHWeFYuZgvfwBQm/termo-de-uso-politica-de-privacidade.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de privacidade
            </BottomLink>
          </BottomLinks>
        </Bottom>
      </Container>
    </FooterStyle>
  )
}

export default Footer
