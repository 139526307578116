import { combineReducers } from 'redux'

// Feedback
import feedback from '../views/Feedback/store/reducer'

// Home
import home from '../views/Home/store/reducer'
import homeMyRelatedNotices from '../views/Home/subpages/MyRelatedNotices/store/reducer'
import homeMyRelatedSubscriptions from '../views/Home/subpages/MyRelatedSubscriptions/store/reducer' // Home
import homeMyEvaluations from '../views/Home/subpages/MyEvaluations/store/reducer' // Home

// Notice Management
import noticeManagement from '../views/NoticeManagement/store/reducer'
import noticeManagementDashboard from '../views/NoticeManagement/subpages/Dashboard/store/reducer'
import noticeManagementStepsManagement from '../views/NoticeManagement/subpages/StepsManagement/store/reducer'
import noticeManagementSubscriptions from '../views/NoticeManagement/subpages/Subscriptions/store/reducer'
import noticeManagementRiskAnalysis from '../views/NoticeManagement/subpages/RiskAnalysis/store/reducer'
import noticeManagementDocumentAnalysis from '../views/NoticeManagement/subpages/DocumentAnalysis/store/reducer'
import noticeManagementEvaluation from '../views/NoticeManagement/subpages/Evaluation/store/reducer'
import noticeManagementSelection from '../views/NoticeManagement/subpages/Selection/store/reducer'
import noticeManagementViewEvaluation from '../views/NoticeManagement/subpages/ViewEvaluation/store/reducer'

// Notice
import notice from '../views/Notices/store/reducer'
import noticeGeneralData from '../views/Notices/GeneralData/store/reducer'
import noticeProcessSteps from '../views/Notices/ProcessSteps/store/reducer'
import noticeProcessStepsDocumentAnalysis from '../views/Notices/ProcessSteps/subpages/DocumentAnalysis/store/reducer'
import noticeProcessStepsRiskAnalysis from '../views/Notices/ProcessSteps/subpages/RiskAnalysis/store/reducer'
import noticeManagersAndConsultants from '../views/Notices/ManagersAndConsultants/store/reducer'
import noticeForms from '../views/Notices/SubscriptionForm/store/reducer'
import noticeSubscriptionFormInfo from '../views/Notices/SubscriptionForm/Info/store/reducer'
import noticeSubscriptionFormIntroPage from '../views/Notices/SubscriptionForm/IntroPage/store/reducer'
import noticeSubscriptionFormQuestionnaire from '../views/Notices/SubscriptionForm/NoticeQuestionnaire/store/reducer'
import noticeProcessStepsEvaluationCriteriaForm from '../views/Notices/ProcessSteps/subpages/EvaluationCriteriaForm/store/reducer'

// User
import profile from '../views/Profile/store/reducer'
import user from '../views/Users/User/store/reducer'

//  Subscriptions
import subscription from '../views/Subscription/store/reducer'

//  Evaluation
import noticeEvaluation from '../views/NoticeEvaluation/store/reducer'
import noticeEvaluationEvaluation from '../views/NoticeEvaluation/subpages/Evaluation/store/reducer'

// Reports
import reports from '../views/Reports/store/reducer'

// Users
import users from '../views/Users/store/reducer'

//ModalIncompleteProfile
import modalIncompleteProfile from '../layout/ModalIncompleteProfile/store/reducer'

export default combineReducers({
  // feedback
  feedback,

  // Home
  home,
  homeMyRelatedNotices,
  homeMyRelatedSubscriptions,
  homeMyEvaluations,

  // Notice Management
  noticeManagement,
  noticeManagementDashboard,
  noticeManagementStepsManagement,
  noticeManagementSubscriptions,
  noticeManagementRiskAnalysis,
  noticeManagementDocumentAnalysis,
  noticeManagementEvaluation,
  noticeManagementSelection,
  noticeManagementViewEvaluation,

  // Notice
  notice,
  noticeGeneralData,
  noticeForms,
  noticeSubscriptionFormInfo,
  noticeSubscriptionFormIntroPage,
  noticeSubscriptionFormQuestionnaire,
  noticeProcessSteps,
  noticeProcessStepsDocumentAnalysis,
  noticeProcessStepsRiskAnalysis,
  noticeManagersAndConsultants,
  noticeProcessStepsEvaluationCriteriaForm,

  // User
  profile,
  user,

  // Subscriptions
  subscription,

  // Evaluation
  noticeEvaluation,
  noticeEvaluationEvaluation,

  // Reports
  reports,

  //USERS
  users,

  //MODAL_INCOMPLETE_PROFILE
  modalIncompleteProfile,
})
