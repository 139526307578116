import { combineActions, handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  pendingUpdatesCount: 0,
  subscription: {},
  subscriptionForm: [],
  participantSubscription: {},
}

const beginLoading = combineActions(
  types.GET_SUBSCRIPTION_FORM,
  types.PUBLISH_USER_SUBSCRIPTION,
  types.ACCEPT_TERM_USER_SUBSCRIPTION,
  types.CANCEL_USER_SUBSCRIPTION,
  types.SAVE_USER_SUBSCRIPTION_ANSWERS,
  types.UPLOAD_USER_SUBSCRIPTION_FILE,
  types.DELETE_USER_SUBSCRIPTION_FILE,
  types.SAVE_PARTICIPANT_SUBSCRIPTION_ANSWERS,
  types.SET_INVISIBLE_FILE_TO_SUBSCRIPTION
)

const stopLoading = combineActions(
  types.GET_SUBSCRIPTION_FORM_SUCCESS,
  types.GET_SUBSCRIPTION_FORM_FAIL,
  types.PUBLISH_USER_SUBSCRIPTION_SUCCESS,
  types.PUBLISH_USER_SUBSCRIPTION_FAIL,
  types.ACCEPT_TERM_USER_SUBSCRIPTION_SUCCESS,
  types.ACCEPT_TERM_USER_SUBSCRIPTION_FAIL,
  types.CANCEL_USER_SUBSCRIPTION_SUCCESS,
  types.CANCEL_USER_SUBSCRIPTION_FAIL,
  types.SAVE_USER_SUBSCRIPTION_ANSWERS_SUCCESS,
  types.SAVE_USER_SUBSCRIPTION_ANSWERS_FAIL,
  types.UPLOAD_USER_SUBSCRIPTION_FILE_SUCCESS,
  types.UPLOAD_USER_SUBSCRIPTION_FILE_FAIL,
  types.DELETE_USER_SUBSCRIPTION_FILE_SUCCESS,
  types.DELETE_USER_SUBSCRIPTION_FILE_FAIL,
  types.SAVE_PARTICIPANT_SUBSCRIPTION_ANSWERS_SUCCESS,
  types.SAVE_PARTICIPANT_SUBSCRIPTION_ANSWERS_FAIL,
  types.SET_INVISIBLE_FILE_TO_SUBSCRIPTION_SUCCESS,
  types.SET_INVISIBLE_FILE_TO_SUBSCRIPTION_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.GET_SUBSCRIPTION_FORM_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      subscriptionForm: data,
    }),
    [types.PUBLISH_USER_SUBSCRIPTION_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
    }),
    [types.ACCEPT_TERM_USER_SUBSCRIPTION_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          subscription: {
            ...state.subscription.subscription,
            termAndPolicyAccepted: data.termAndPolicyAccepted,
          },
        },
      }
    },
    [types.CANCEL_USER_SUBSCRIPTION_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      subscription: data,
    }),
    [types.UPLOAD_USER_SUBSCRIPTION_FILE_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: files },
        },
      }
    ) => ({
      ...state,
      subscription: {
        ...state.subscription,
        files,
      },
    }),
    [types.DELETE_USER_SUBSCRIPTION_FILE_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: files },
        },
      }
    ) => ({
      ...state,
      subscription: {
        ...state.subscription,
        files,
      },
    }),
    [types.SAVE_USER_SUBSCRIPTION_ANSWERS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => {
      const updatedPendingUpdatesCount =  state.pendingUpdatesCount - 1
      const newErrors = {
        ...state.subscription.errors,
        ...data.errors,
      }

      return {
        ...state,
        pendingUpdatesCount: updatedPendingUpdatesCount,
        subscription: {
          ...state.subscription,
          answers: updatedPendingUpdatesCount > 0 ? state.subscription.answers : JSON.parse(data.answers.answers),
          errors: updatedPendingUpdatesCount > 0 ? state.subscription.errors : newErrors,
        },
      }
    },
    [types.SET_USER_SUBSCRIPTION_ANSWERS]: (state, { data }) => {
      const questionId = Object.keys(data)[0]
      const questions = state.subscriptionForm.reduce((acc, x) => {
        acc.push(...x.questions)
        return acc
      }, [])
      const question = questions.find(x => x.id === parseInt(questionId))

      return {
        ...state,
        subscription: {
          ...state.subscription,
          answers: { ...state.subscription.answers, ...data },
          errors: {
            ...state.subscription.errors,
            [questionId]: data[questionId] ? false : question.requiredQuestion ? { error: 'Esta pergunta é obrigatória' } : false,
          },
        },
      }
    },
    [types.SAVE_USER_SUBSCRIPTION_ANSWERS]: (state) => ({
      ...state,
      pendingUpdatesCount: state.pendingUpdatesCount + 1,
    }),
    [types.SAVE_PARTICIPANT_SUBSCRIPTION_ANSWERS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      participantSubscription: {
        ...state.participantSubscription,
        answers: JSON.parse(data.answers.answers),
        errors: data.errors,
        audits: data.audits,
        files: data.files,
      },
    }),
    [types.SET_INVISIBLE_FILE_TO_SUBSCRIPTION_SUCCESS]: (
      state
      // {
      //   payload: {
      //     data: { data },
      //   },
      // }
    ) => ({
      ...state,
    }),
    [types.SET_PARTICIPANT_SUBSCRIPTION]: (
      state,
      { participantSubscription }
    ) => ({
      ...state,
      participantSubscription,
    }),
    [types.SET_SUBSCRIPTION_FORM]: (state, { subscriptionForm }) => ({
      ...state,
      subscriptionForm,
    }),
    [types.SET_SUBSCRIPTION]: (state, { subscription }) => ({
      ...state,
      subscription,
    }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
