const breakpoints = {
  lg: '1230px',
  md: '1022px',
  sm: '798px',
  xs: '510px',
}

export default breakpoints

export const breakpointsNumbers = {
  lg: 1230,
  md: 1022,
  sm: 798,
  xs: 510,
}
