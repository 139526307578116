import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD_NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE'),
    ...async('UPDATE_NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE'),
    'SET_NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE',
    'CLEAR_STATE',
  ],
  'NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE'
)
