import styled from 'styled-components'


import { Button } from '../Button'

export const DropdownStyle = styled.div`
  position: relative;
  width: auto;
  padding: 2px 0;
  display: flex;
  justify-content: end;
`

export const MainButton = styled.button`
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  width: auto;

  > img {
    height: 22px;
    width: 22px;
  }

  > span {
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 20px;
    max-height: 40px;
    width: 112px;
    margin: auto 0;
  }

  @media (max-width: 798px) {
    span {
      display: none;
    }
  }

  @media (max-width: 1022px) {
    width: auto;
  }

  @media (max-width: 510px) {
    width: auto;
  }
`

export const List = styled.div`
  display: none;
  /* display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')}; */
  position: absolute;
  flex-shrink: 0;
  right: 0;
  bottom: 0;
  top: 26px;
  z-index: 2;
  flex-direction: column;
  width: 220px;
  height: fit-content;
  border: 4px solid var(--color-light);
  border-radius: 10px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  background: var(--color-white);
  color: #333333;

  &.opened {
    display: flex;
  }

  > span {
    flex-shrink: 0;
    display: flex;
    height: 4px;
    background: var(--color-yellow);
    width: 160px;
    margin: 0;
    border: 0;
    margin: -4px 16px 5px 16px;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    width: 100%;
    padding: 5px 16px;
    display: flex;
    align-items: center;
    color: #333333;

    :first-child {
      border-bottom: 2px solid var(--color-light);
      margin-bottom: 5px;
      padding-bottom: 5px;
      text-align: center;
      justify-content: center;

      :hover, :focus {
        background: var(--color-transparent);
        cursor: default;
      }
    }

    &.no-hover {
      :hover, :focus {
        background: var(--color-transparent);
        cursor: default;
      }
    }

    > div {
      width: 100%;
    }

    img {
      margin-right: 2px;
    }

    :hover,
    :focus {
      background: var(--color-yellow);
      cursor: pointer;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
  width: 100%;
`;

export const FontButton = styled(Button)`
  > button {
    padding: 10px 20px;
    min-height: unset;
  }
`;
