import React from 'react'
import PropTypes from 'prop-types'

import colors from '~/utils/colors'

import { VerticalLineStyle } from './styles'

function VerticalLine(props) {
  return <VerticalLineStyle {...props} />
}

VerticalLine.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
  center: PropTypes.bool,
}

VerticalLine.defaultProps = {
  color: colors.black,
  width: 5,
}

export default VerticalLine
