import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'

import colors from '~/utils/colors'
import { fetchSelectedNotices, searchNotices } from '../../../services/requests'

import { ButtonCircleIcon, Inline, SearchInput, T4, Table } from '~/components'
import { TrashIcon, TrashLightIcon } from '~/styles/Icons'

function Notices({ param, setParam }) {
  const [notices, setNotices] = useState([])
  const [noticesSelected, setNoticesSelected] = useState([])
  const [selecteds, setSelecteds] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (param) {
      if (!Array.isArray(param)) {
        const select = param.split(',')
        setSelecteds(select)
        setParam(select)
        return
      }
      return setSelecteds(param)
    }
    return setSelecteds([])

  }, [param, setParam])

  useEffect(() => {
    fetchSelectedNotices(selecteds, setNoticesSelected)
  }, [selecteds, setNoticesSelected])

  const changeSelected = useCallback(
    (value) => {
      const treatedParam = !Array.isArray(param) ? param.split(',') : param
      setParam(treatedParam.filter((item) => Number(item) !== Number(value.id)).join(','))
    },
    [param, setParam]
  )

  const addSelected = useCallback(
    (value) => {
      const selected = !!selecteds.find((selected) => Number(selected) === Number(value.id))
      if (!selected) {
        setNoticesSelected([...noticesSelected, value])
        setParam(
          [...selecteds, value.id].sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
        )
      }
    },
    [setParam, selecteds, noticesSelected]
  )

  const funcSearchNotices = useCallback(
    () => {
      return searchNotices(setNotices, search)
    },
    [search]
  )

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        color: colors.dark,
        style: {
          minHeight: '50px',
        },
      },
      {
        accessor: 'remove',
        align: 'right',
        style: {
          minHeight: '50px',
        },
        Cell: ({ row: { original } }) => {
          return (
            <ButtonCircleIcon
              aria-label="Remover"
              icon={TrashIcon}
              iconHover={TrashLightIcon}
              color={colors.white}
              onClick={() => changeSelected(original)}
            />
          )
        },
      },
    ],
    [changeSelected]
  )

  return (
    <>
      <div className="my-5 mx-3 px-3">
        <SearchInput
          label="Pesquise por nome"
          aria-label="Pesquise por nome"
          makeSearch={funcSearchNotices}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          data={notices}
        >
          {notices.map((item, key) => {
            return (
              <li
                key={key}
                onClick={() => {
                  setSearch('')
                  addSelected(item)
                }}
              >
                <button>
                  <T4 color={colors.darkGray}>{item.name}</T4>
                </button>
              </li>
            )
          })}
        </SearchInput>
      </div>
      <div className="my-5 mx-6 px-3">
        {noticesSelected.length ? (
          <Table
            hasHeader={false}
            columns={columns}
            data={noticesSelected}
          />
        ) : (
          <T4 color={colors.darkGray} className="text-center">
            Busque por um edital para adicionar ao filtro
          </T4>
        )}
      </div>
      <Inline center className="mt-3 mb-5">
        <ButtonCircleIcon
          aria-label="Limpar filtro"
          icon={TrashIcon}
          iconHover={TrashLightIcon}
          onClick={() => {
            setParam([])
            setSearch('')
            setNoticesSelected([])
          }}
        >
          Limpar
        </ButtonCircleIcon>
      </Inline>
    </>
  )
}

export default memo(Notices)
