import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { Content } from './styles'

function Checkbox({
  innerRef,
  children,
  className,
  active,
  disabled,
  id,
  style,
  dark,
  activeDark,
  inverted,
  useFormik,
  inputStyle,
  forceChildren,
  labelStyle,
  size,
  ...props
}) {
  return (
    <Content
      className={classnames(className, { active: active })}
      style={style}
      dark={dark}
      activeDark={activeDark}
      disabled={disabled}
      size={size}
    >
      {!forceChildren && inverted && children && (
        <label style={labelStyle} htmlFor={id} className="pr-1">
          {children}
        </label>
      )}
      <input
        {...props}
        disabled={disabled}
        id={id}
        type="checkbox"
        style={inputStyle}
        className={classnames({ dark: dark })}
      />
      {!forceChildren && !inverted && children && (
        <label htmlFor={id} style={labelStyle} className={classnames({ dark: dark })}>
          {children}
        </label>
      )}
      {forceChildren && children}
    </Content>
  )
}

Checkbox.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  dark: PropTypes.bool,
  activeDark: PropTypes.bool,
  inverted: PropTypes.bool,
  innerRef: PropTypes.object,
  value: PropTypes.any,
  size: PropTypes.number,
}

Checkbox.defaultProps = {
  inverted: false,
}

export default Checkbox
