import styled from 'styled-components'

export const WarningStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ radius }) => radius + 'px'};
  background: ${({ color }) => color};
  min-height: 70px;
  padding: 20px;
`
