import React, {
  Children,
  cloneElement,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react'
import queryString from 'query-string'
import colors from '~/utils/colors'

import { ButtonCircleIcon, Count, Inline, T3, T4 } from '~/components'

import { FiltersStyle } from './styles'

import { TrashIcon, TrashLightIcon } from '~/styles/Icons'

import Filter from './Filter'

function FiltersComponent({
  history,
  resultsCount,
  children,
  extraParams = {},
  paramsToIgnore = [],
  resetPageIndexSortyBy = true,
  onClean = () => {},
  ...props
}) {
  const [params, setParams] = useState(
    queryString.parse(history.location.search) || {}
  )

  const [paramsCount, setParamsCount] = useState({})

  const onRemove = useCallback(
    (params, cb = () => {}) => {
      let finalParams = {}

      if (resetPageIndexSortyBy) {
        finalParams = {
          ...params,
          ...extraParams,
          pageIndex: null,
          sortBy: null,
        }
      } else {
        finalParams = { ...params, ...extraParams }
      }

      const newSearch = queryString.stringify(finalParams, {
        arrayFormat: 'comma',
        skipNull: true,
        skipEmptyString: true,
      })

      history.push({
        search: newSearch,
      })

      if (cb) {
        cb(params)
      }
    },
    [history, extraParams, resetPageIndexSortyBy]
  )

  const onCleanCb = useCallback(() => {
    const { pageIndex, sortBy } = queryString.parse(history.location.search)
    if (pageIndex || sortBy) {
      const obj = resetPageIndexSortyBy
        ? {}
        : {
            pageIndex: pageIndex,
            sortBy: sortBy,
          }
      const search = queryString.stringify(
        { ...obj, ...extraParams },
        {
          arrayFormat: 'comma',
          skipNull: true,
          skipEmptyString: true,
        }
      )
      history.push({
        search: search,
      })
    } else if (`` !== history.location.search) {
      history.push({
        search: '',
      })
    }

    onClean()
  }, [onClean, history, extraParams, resetPageIndexSortyBy])

  const filtersCount = useMemo(() => {
    const keys = Object.keys(paramsCount)
    return keys.reduce((accumulator, key) => {
      return accumulator + paramsCount[key]
    }, 0)
  }, [paramsCount])

  const parameters = Object.keys(params).filter(
    (key) =>
      key !== 'pageIndex' &&
      key !== 'sortBy' &&
      !paramsToIgnore.find((p) => p === key)
  )

  return parameters.length ? (
    <FiltersStyle {...props}>
      <Inline right={20} verticalMiddle bottom={10} className="mb-1">
        <Inline noWrap verticalMiddle right={5}>
          <T3>Filtros Ativos:</T3>
          <Count
            color={colors.yellow}
            size={30}
            textSize={16}
            count={filtersCount}
          />
        </Inline>

        <T4 color={colors.darkGray}>
          {resultsCount || '0'} Resultados encontrados
        </T4>

        <ButtonCircleIcon
          aria-label="Limpar filtros"
          icon={TrashIcon}
          iconHover={TrashLightIcon}
          onClick={onCleanCb}
        >
          Limpar Filtros
        </ButtonCircleIcon>
      </Inline>

      <Inline right={10} bottom={10}>
        {Children.map(children, (child) => {
          return cloneElement(child, {
            params,
            setParams,
            onRemove,
            setParamsCount,
          })
        })}
      </Inline>
    </FiltersStyle>
  ) : null
}

const Filters = memo(FiltersComponent)

Filters.Filter = Filter

export default Filters
