import styled from 'styled-components'

export const Content = styled.h3`
  font-family: ${({ font }) => font};
  color: ${({ color }) => color};
  font-size: 26px;
  line-height: 33px;
  min-height: 33px;
  margin: 0;
`
