import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Editor as TinyEditor } from '@tinymce/tinymce-react'

import { handleUpload } from '~/utils/handleUpload'

const styleTextInSane = `
p {
  color: black;
}
figure {
  margin: 0;
}
`

function Editor({
  id,
  initialValue,
  onBlur,
  onEditorChange,
  onUploadImage,
  initOptions = {},
  className,
  ...props
}) {
  const handleEditorChange = (content, editor) =>
    onEditorChange && onEditorChange(editor)

  const hanldeSetValueFile = useCallback(
    async (blobInfo, success) => {
      const { filename, blob } = blobInfo

      const re = /(?:\.([^.]+))?$/

      if (
        re.exec(filename())[1] !== 'png' &&
        re.exec(filename())[1] !== 'jpeg' &&
        re.exec(filename())[1] !== 'jpg'
      )
        return alert(
          'Tipo de documento não suportado, por favor fazer upload de arquivos com extensões: .png, .jpeg ou .jpg'
        )

      const uploadedImage = await handleUpload(blob())

      if (uploadedImage.success) {
        if (onUploadImage) {
          onUploadImage(uploadedImage?.file?.id)
        }
        return success(uploadedImage.file.fullUrl)
      }
    },
    [onUploadImage]
  )

  return (
    <div className={classnames(className)}>
      <>
        <TinyEditor
          apiKey="d4haz8ruymm6yc5pi9xgrebungyp5hdgnxohy39cqv9quz2s"
          initialValue={initialValue}
          content={initialValue}
          onBlur={(content, editor) => onBlur && onBlur(editor)}
          id={id}
          init={{
            statusbar: false,
            entity_encoding: 'raw',
            language: 'pt_BR',
            height: 500,
            referrer_policy: 'origin',
            help: { title: 'Help', items: 'help' },
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount image importcss',
            ],
            content_style: styleTextInSane,
            toolbar:
              'undo redo | formatselect | image | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
            menubar: 'file | edit | view | insert | format | table | tools',
            selector: 'textarea',
            image_caption: true,
            images_upload_handler: hanldeSetValueFile,
            image_list: [],
            ...initOptions,
          }}
          onEditorChange={(content, editor) =>
            handleEditorChange(content, editor)
          }
        />
      </>
    </div>
  )
}

Editor.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onEditorChange: PropTypes.func,
  initialValue: PropTypes.string,
  initOptions: PropTypes.object,
}

export default Editor
