import styled from 'styled-components'
import breakpoints from '~/utils/breakpoints'


import EditaisRow from '../Row'
import EditaisT4 from '../T4'

export const Row = styled(EditaisRow)`
  outline: 0;
  display: flex;
  margin-left: 0px;
  flex-direction: row;
  align-items: center;
  position: relative;

  min-height: 20px;

  margin-right: 20px;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

    ${({ size }) => size && `height: ${size}px; width: ${size}px;`}
    > img {
      ${({ size }) => size && `height: ${size}px; width: ${size}px;`}
      display: block;
    }
  }
`

export const Label = styled.label`
  display: flex;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
`

export const Box = styled.div`
  max-width: 220px;
  padding: 20px;
  border-radius: 5px;
  background-color: var(--color-dark);
  position: absolute;
  align-self: flex-end;
  z-index: 9;

  @media (max-width: calc(${breakpoints.md} - 1px)) {
    right: -10px;
  }
`

export const T4 = styled(EditaisT4)`
  color: var(--color-yellow);
`
