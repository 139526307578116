import styled from 'styled-components'

import { KeyboardDatePicker } from '@material-ui/pickers'
import breakpoints from '~/utils/breakpoints'

export const Container = styled.div`
  margin-bottom: 30px;
  .quick-reg-datepicker .MuiInput-underline:before {
    content: none !important;
  }
  .quick-reg-datepicker .MuiInput-underline:after {
    content: none !important;
  }

  @media (max-width: calc(${breakpoints.md} - 1px)) {
    width: 100%;
  }

  .MuiFormControl-marginNormal {
    @media (max-width: calc(${breakpoints.md} - 1px)) {
      width: 100%!important;
    }
  }
`

export const DatePicker = styled(KeyboardDatePicker)`
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #f3eddf !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #f3eddf !important;
  }

  .MuiOutlinedInput-adornedEnd {
    font-family: 'Itau Text Regular' !important;
    font-weight: bold !important;
    border-radius: 0px !important;
    font-size: 16px;
    line-height: 21px;
    border: 2px solid #f3eddf !important;

    .MuiSvgIcon-root {
      font-size: 2.1rem;
    }

    .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
      p {
        display: none;
      }
    }
  }
`
