import styled from 'styled-components'


import { TrashIcon } from '~/styles/Icons'

export const FileStyle = styled.div`
  flex-shrink: 0;
  background: var(--color-light);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-left: 30px;
  flex-grow: 1;
  max-width: 100%;

  p {
    color: var(--color-darkGray);
    font-family: 'Itau Text Regular';
    font-size: 16px;
    line-height: 21px;
    width: calc(100% - 30px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    height: 30px;
    width: 30px;
    background: var(--color-white);
    border-radius: 50px;
    background-image: url(${TrashIcon});
    background-repeat: no-repeat;
    background-position: center center;
  }
`
