import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
  TextareaStyle,
  LabelStyle,
  TextareaGroupStyle,
  CaracterCountStyle,
} from './styles'

function Textarea({
  defaultValue,
  labelInfo,
  label,
  id,
  className,
  active,
  showCount,
  maxLength,
  onChange,
  onBlur,
  value,
  error,
  ...props
}) {
  const [textareaValue, setTextareaValue] = useState(
    defaultValue || props.value
  )

  useEffect(() => {
    showCount && setTextareaValue(value)
  }, [showCount, value])

  return (
    <TextareaGroupStyle>
      {label && <LabelStyle htmlFor={id}>{label}</LabelStyle>}

      <TextareaStyle
        {...props}
        id={id}
        className={classnames(className, { active: active }, { error: error })}
        maxLength={maxLength}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={error}
        defaultValue={defaultValue}
      ></TextareaStyle>

      {showCount && (
        <CaracterCountStyle>
          {String(textareaValue || '').length}
          {maxLength && ` / ${maxLength}`} caracteres
        </CaracterCountStyle>
      )}
    </TextareaGroupStyle>
  )
}

Textarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  active: PropTypes.bool,
  height: PropTypes.number,
  showCount: PropTypes.bool,
  error: PropTypes.bool,
}

Textarea.defaultProps = {
  height: 100,
}

export default Textarea
