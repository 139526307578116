import React from 'react'
import PropTypes from 'prop-types'

import { Content } from './styles'

function T2(props) {
  return <Content {...props} />
}

T2.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  font: PropTypes.string,
  color: PropTypes.string,
}

export default T2
