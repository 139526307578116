import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD_NOTICE_SUBSCRIPTION_FORM_INFO'),
    ...async('CREATE_NOTICE_SUBSCRIPTION_FORM_INFO'),
    ...async('UPDATE_NOTICE_SUBSCRIPTION_FORM_INFO'),
    'SET_NOTICE_SUBSCRIPTION_FORM_INFO',
    'CLEAR_STATE',
  ],
  'NOTICE_SUBSCRIPTION_FORM_INFO'
)
