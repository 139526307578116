import styled from 'styled-components'


export const TextareaGroupStyle = styled.div`
  position: relative;
`

export const TextareaStyle = styled.textarea`
  outline: 0;
  display: flex;
  font-family: 'Itau Text Regular';
  font-size: 16px;
  line-height: 21px;
  padding: 14px 20px 15px 20px;
  border: 2px solid var(--color-light);
  height: ${({ height }) => height + 'px'};
  width: 100%;
  resize: vertical;

  &:focus,
  &.active {
    border-color: var(--color-yellow);
  }

  ${({ error }) =>
    error &&
    `
    border-color: var(--color-red);
  `}

  ::placeholder {
    color: var(--color-gray);
  }

  &:disabled {
    background-color: rgba(243, 237, 223, 0.7);
  }
`

export const LabelStyle = styled.label`
  display: flex;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 10px;
`

export const CaracterCountStyle = styled.small`
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-gray);
  position: absolute;
  right: 0;
  padding-top: 5px;
`
