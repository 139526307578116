import { combineActions, handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  reports: [],
  pageIndex: 1,
  pageSize: 1,
  pageCount: 1,
  resultsCount: 1,
  stats: null,
}

const beginLoading = combineActions(types.SEARCH_REPORTS, types.STATS)

const stopLoading = combineActions(
  types.SEARCH_REPORTS_SUCCESS,
  types.SEARCH_REPORTS_FAIL,
  types.STATS_SUCCESS,
  types.STATS_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.SEARCH_REPORTS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: result },
        },
      }
    ) => {
      return {
        ...state,
        reports: result.reports,
        pageIndex: result.pageIndex,
        pageSize: result.pageSize,
        pageCount: result.pageCount,
        resultsCount: result.resultsCount,
      }
    },
    [types.STATS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: stats },
        },
      }
    ) => ({
      ...state,
      stats,
    }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
