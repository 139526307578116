import client from '~/boot/client'

export async function getNotice(id) {
  try {
    const response = await client.get(`/api/notices/${id}`)
    return response.data.data
  } catch (e) {
    return null
  }
}

export async function noticeSubscriptions(id) {
  try {
    const response = await client.get(`/api/subscriptions/${id}`)
    if (response && response.data) {
      return response.data.data
    }
  } catch (e) {
    return null
  }
}
