import { handleActions, combineActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  brazilRegions: [],
  cnaes: [],
  data: {
    data: {
      dirty: true,
      name: '',
      image: null,
      type: 'PARTNERSHIP',
      target: 'PJ',
      description: '',
      institutionalWebsite: '',
      disclosure: 'OPENED',
      registrationDeadlines: 'DEFINED',
      hasAmount: true,
      hasLimitAmountPerProject: true,
      allowCancelFinishedInscriptionDuringWhileOpen: true,
    },
    regions: {
      dirty: true,
      data: [],
    },
    cnaes: {
      dirty: true,
      data: [],
    },
    subscriptionDates: {
      dirty: true,
      start: {
        noticeId: null,
        date: new Date(),
        hour: '23:59',
        type: 'START',
      },
      end: {
        noticeId: null,
        date: new Date(),
        hour: '23:59',
        type: 'END',
      },
    },
    values: {
      dirty: true,
      hasAmount: {
        noticeId: null,
        amount: null,
        type: 'INVESTMENT',
        isPublic: true,
      },
      hasLimitAmountPerProject: {
        noticeId: null,
        amount: null,
        type: 'PROJECT',
        isPublic: false,
      },
    },
    files: {
      dirty: true,
      data: [],
    },
    faqs: {
      dirty: true,
      data: [],
    },
  },
}

const beginLoading = combineActions(
  types.GET_BRAZIL_REGIONS,
  types.LOAD
  // types.GET_CNAES,
  // types.SEND_DRAFT_GENERAL_DATA
)

const stopLoading = combineActions(
  types.LOAD_SUCCESS,
  types.LOAD_FAIL,
  types.GET_BRAZIL_REGIONS_SUCCESS,
  types.GET_BRAZIL_REGIONS_FAIL
  // types.GET_CNAES_SUCCESS,
  // types.GET_CNAES_FAIL,
  // types.SEND_DRAFT_SUCCESS,
  // types.SEND_DRAFT_GENERAL_DATA_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      data: !data.data ? INITIAL_STATE.data : data,
    }),
    [types.GET_BRAZIL_REGIONS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: brazilRegions },
        },
      }
    ) => ({
      ...state,
      brazilRegions,
    }),
    [types.GET_CNAES_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: cnaes },
        },
      }
    ) => ({
      ...state,
      cnaes,
    }),
    [types.SEND_DRAFT_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      data,
    }),
    [types.SET_VALUE_TO_STATE]: (state, { obj }) => ({
      ...state,
      ...obj,
    }),
    [types.SET_DATA_TO_STATE]: (state, data ) => ({
      ...state,
      data: data.data,
    }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
