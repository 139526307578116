import StyleGuideRoutes from '~/views/StyleGuide/routes'
import UsersRoutes from '~/views/Users/routes'
import ReportsRoutes from '~/views/Reports/routes'
import NoticesRoutes from '~/views/Notices/routes'
import HomeRoutes from '~/views/Home/routes'
import NoticeManagementRoutes from '~/views/NoticeManagement/routes'
import SubscriptionsRoutes from '~/views/Subscription/routes'
import EnrollmentFormRoutes from '~/views/EnrollmentForm/routes'
import NoticeEvaluationRoutes from '~/views/NoticeEvaluation/routes'

const routes = [
  ...StyleGuideRoutes,
  ...UsersRoutes,
  ...ReportsRoutes,
  ...NoticesRoutes,
  ...HomeRoutes,
  ...NoticeManagementRoutes,
  ...SubscriptionsRoutes,
  ...NoticeEvaluationRoutes,
  ...EnrollmentFormRoutes,
]

export default routes
