import axios from 'axios'
import { setIsOpen } from '~/layout/ModalIncompleteProfile/store/actions'

import baseConfig from '~/utils/baseConfig'

export const client = axios.create({
  baseURL: baseConfig.url,
  responseType: 'json',
  withCredentials: true,
})

client.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.response?.status === 403) {
      return (window.location = '/acesso-proibido')
    }

    if (error?.response?.status === 412) {
      require('./store').default.dispatch(setIsOpen(true))
    }

    LoginIfNotAuthenticated(error)
    throw error
  }
)

function LoginIfNotAuthenticated(error) {
  if (error?.response && error?.response?.status === 401) {
    const path = window.location.pathname
    const search = encodeURIComponent(window.location.search)
    window.location = `${baseConfig.url}/api/login?path=${path}&search=${search}`
  }
}

export default client
