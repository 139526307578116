import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  height: ${({ size }) => size + 'px'};
  width: ${({ size }) => size + 'px'};
  background: ${({ color }) => color};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`
