import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD'),
    ...async('GET_BRAZIL_REGIONS'),
    ...async('GET_CNAES'),
    ...async('SEND_DRAFT'),
    'SET_VALUE_TO_STATE',
    'SET_DATA_TO_STATE',
    'CLEAR_STATE',
  ],
  'NOTICE_GENERAL_DATA'
)
