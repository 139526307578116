export function onKeyPress(e, key, callback) {
  if (e.keyCode === key) {
    callback()
  }
}

export function onShiftTabPress(e, callback) {
  if (e.shiftKey && e.keyCode === 9) {
    callback()
  }
}
