import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD_DOCUMENT_ANALYSIS'),
    ...async('LOAD_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS'),
    ...async('UPDATE_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS'),
    ...async('LOAD_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DOCUMENTS'),
    ...async('UPDATE_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DOCUMENTS'),
    'SET_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DESCRIPTION',
    'SET_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_HORUS_INTEGRATION',
    'CLEAR_STATE',
  ],
  'NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS'
)
