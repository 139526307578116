import React from 'react'
const Users = React.lazy(() => import('./'))
const User = React.lazy(() => import('./User'))

const userRoutes = [
  {
    path: '/usuarios',
    component: Users,
    exact: true,
  },
  {
    path: '/usuarios/:id',
    component: User,
    exact: true,
  },
]

export default userRoutes
