import React from 'react'

import colors from '~/utils/colors'

import { CheckIcon, CheckLightIcon } from '~/styles/Icons'

import { Button, Modal, T3 } from '~/components'

function ModalFilter({ isOpen, toggle }) {
  return (
    <Modal id="modal-search-notice" md isOpen={isOpen} toggle={toggle}>
      <Modal.Header type="normal" title={'Atenção'} />

      <Modal.Body>
        <T3 color={colors.darkGray}>Funcionalidade ainda não disponível.</T3>
      </Modal.Body>

      <Modal.Footer>
        <Button
          aria-label="Concluir"
          icon={CheckLightIcon}
          iconHover={CheckIcon}
          className="mb-3"
          onClick={toggle}
        >
          Concluído
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalFilter
