import types from './types'

export function loadNoticeSubscriptionFormQuestions(noticeId) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.LOAD_SUBSCRIPTION_FORM_QUESTIONS,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/subscription-form/questions/multiple-choice`,
            method: 'GET',
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function loadNoticeEvaluationCriteriaForm(noticeId) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.LOAD_EVALUATION_CRITERIA_FORM,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/process-steps/evaluation-criterias`,
            method: 'GET',
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function loadOrCreateNoticeEvaluationCriteriaForm(noticeId) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.LOAD_EVALUATION_CRITERIA_FORM,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/process-steps/evaluation-criterias`,
            method: 'GET',
          },
        },
      })

      if (!data.payload.data.data) {
        dispatch(updateNoticeEvaluationCriteriaForm(noticeId, { groups: [] }))
      }

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function updateNoticeEvaluationCriteriaForm(noticeId, form) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.UPDATE_EVALUATION_CRITERIA_FORM,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/process-steps/evaluation-criterias`,
            method: 'PUT',
            data: form,
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function partialUpdateNoticeEvaluationCriteriaForm(noticeId, form) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.UPDATE_EVALUATION_CRITERIA_FORM,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/process-steps/evaluation-criterias`,
            method: 'PATCH',
            data: form,
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function setNoticeEvaluationCriteriaForm(form) {
  return { type: types.SET_EVALUATION_CRITERIA_FORM, form }
}

export function clearState() {
  return { type: types.CLEAR_STATE }
}
