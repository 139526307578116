import types from './types'

export function loadMySubscriptions() {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: types.LOAD_MY_SUBSCRIPTIONS,
        payload: {
          request: {
            url: `/api/home/my-subscriptions`,
            method: 'GET',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function clearState() {
  return { type: types.CLEAR_STATE }
}
