import types from './types'

export function getProcessSteps() {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: types.GET_PROCESS_STEPS,
        payload: {
          request: {
            url: `/api/process-steps`,
            method: 'GET',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function getNoticeSubFormQuestions(noticeId) {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: types.GET_REPORT_FORM_QUESTIONS,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/subscription-form/questions/multiple-choice`,
            method: 'GET',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function searchReports(params) {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: types.SEARCH_REPORTS,
        payload: {
          request: {
            url: `/api/participant-and-subscription/report?step=documentAnalysis&pageSize=8&${
              params ? '&' + params : ''
            }`,
            method: 'GET',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function getFilterEvaluators(noticeId, name) {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: types.FILTER_EVALUATORS,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/evaluators?name=${name ? name : ''}`,
            method: 'GET',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function getFilterSelectedEvaluators(noticeId, ids) {
  const id = ids ? ids.join(',') : ''
  return async (dispatch) => {
    try {
      return await dispatch({
        type: types.FILTER_SELECTED_EVALUATORS,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/evaluators?ids=${id ? id : ''}`,
            method: 'GET',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function clearFilterSelectedEvaluators() {
  return { type: types.CLEAR_SELECTED_EVALUATORS }
}
