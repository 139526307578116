import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
  OptionsStyle,
  LinkStyle,
  ExternalLinkStyle,
  ButtonStyle,
} from './styles'

function Options(props) {
  return <OptionsStyle {...props} />
}

Options.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  light: PropTypes.bool,
  center: PropTypes.bool,
  mobileCenter: PropTypes.bool,
}

function Link(props) {
  return (
    <LinkStyle
      {...props}
      className={classnames(props.className, { active: props.active })}
      active={null}
      right={String(props.right)}
    >
      {props.icon && String(props.right) === 'false' && (
        <img src={props.icon} alt="" aria-hidden />
      )}
      {props.children}
      {props.icon && String(props.right) === 'true' && (
        <img src={props.icon} alt="" aria-hidden />
      )}
    </LinkStyle>
  )
}

Link.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  active: PropTypes.bool,
  right: PropTypes.bool,
  onClick: PropTypes.func,
}

function ExternalLink(props) {
  return (
    <ExternalLinkStyle
      {...props}
      className={classnames(props.className, { active: props.active })}
      active={null}
    >
      {props.icon && !props.right && (
        <img src={props.icon} alt="" aria-hidden />
      )}
      {props.children}
      {props.icon && props.right && <img src={props.icon} alt="" aria-hidden />}
    </ExternalLinkStyle>
  )
}

ExternalLink.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  active: PropTypes.bool,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  right: PropTypes.bool,
}

function Button(props) {
  return (
    <ButtonStyle
      {...props}
      className={classnames(props.className, { active: props.active })}
      active={null}
    >
      {props.icon && !props.right && (
        <img src={props.icon} alt="" aria-hidden />
      )}
      {props.children}
      {props.icon && props.right && <img src={props.icon} alt="" aria-hidden />}
    </ButtonStyle>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  right: PropTypes.bool,
}

Options.Link = Link
Options.ExternalLink = ExternalLink
Options.Button = Button

export default Options
