import styled from 'styled-components'

export const Content = styled.h2`
  font-family: ${({ font }) => font};
  color: ${({ color }) => color};
  font-size: 36px;
  line-height: 47px;
  min-height: 47px;
  margin: 0;
`
