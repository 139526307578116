import { async, types } from '~/utils/typeCreator'

export default types(
  [
    'SET_SUBSCRIPTION',
    'SET_SUBSCRIPTION_FORM',
    'SET_PARTICIPANT_SUBSCRIPTION',
    'SET_USER_SUBSCRIPTION_ANSWERS',
    ...async('GET_SUBSCRIPTION_FORM'),
    ...async('PUBLISH_USER_SUBSCRIPTION'),
    ...async('ACCEPT_TERM_USER_SUBSCRIPTION'),
    ...async('CANCEL_USER_SUBSCRIPTION'),
    ...async('SAVE_USER_SUBSCRIPTION_ANSWERS'),
    ...async('UPLOAD_USER_SUBSCRIPTION_FILE'),
    ...async('DELETE_USER_SUBSCRIPTION_FILE'),
    ...async('SAVE_PARTICIPANT_SUBSCRIPTION_ANSWERS'),
    ...async('SET_INVISIBLE_FILE_TO_SUBSCRIPTION'),
    'CLEAR_STATE',
  ],
  'SUBSCRIPTION'
)
