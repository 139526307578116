import client from '~/boot/client'

export async function getSubscriptionByNoticeId(noticeId) {
  try {
    const response = await client.get(`/api/subscriptions/${noticeId}/notice`)
    return response.data.data
  } catch (e) {
    return null
  }
}

export async function getSubscriptionByNoticeIdAndOrder(noticeId, order) {
  try {
    const response = await client.get(
      `/api/subscriptions/${noticeId}/notice?order=${order}`
    )
    return response.data.data
  } catch (e) {
    return null
  }
}

export async function getSubscriptionByNoticeIdActive(noticeId) {
  try {
    const response = await client.get(
      `/api/subscriptions/${noticeId}/notice?active=${true}`
    )
    return response.data.data
  } catch (e) {
    return null
  }
}

export function createSubscription(noticeId) {
  return client.post(`/api/subscriptions/${noticeId}`)
}

export async function isUserManagerEvaluatorOrConsultant(noticeId) {
  try {
    const response = await client.get(
      `/api/notices/${noticeId}/users-notice-profiles/user`
    )
    return response.data.data
  } catch (e) {
    return null
  }
}

export async function publishSubscription(subscriptionId) {
  try {
    const response = await client.post(
      `/api/subscriptions/${subscriptionId}/publish`
    )
    return response.data.data
  } catch (e) {
    return null
  }
}

export async function getBanks() {
  try {
    const response = await client.get(`/api/banks`)
    return response.data.data
  } catch (e) {
    return null
  }
}

export async function getAddressByCEP(cep) {
  try {
    const response = await client.get(
      `/api/address?cep=${cep}`
    )
    return response.data.data
  } catch (e) {
    return null
  }
}
