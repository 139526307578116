import { handleActions, combineActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  info: {
    formName: null,
    creationDate: null,
    lastModification: null,
    createdBy: null,
    userName: null,
  },
}

const beginLoading = combineActions(
  types.LOAD_NOTICE_SUBSCRIPTION_FORM_INFO,
  types.CREATE_NOTICE_SUBSCRIPTION_FORM_INFO,
  types.UPDATE_NOTICE_SUBSCRIPTION_FORM_INFO
)

const stopLoading = combineActions(
  types.LOAD_NOTICE_SUBSCRIPTION_FORM_INFO_SUCCESS,
  types.LOAD_NOTICE_SUBSCRIPTION_FORM_INFO_FAIL,
  types.CREATE_NOTICE_SUBSCRIPTION_FORM_INFO_SUCCESS,
  types.CREATE_NOTICE_SUBSCRIPTION_FORM_INFO_FAIL,
  types.UPDATE_NOTICE_SUBSCRIPTION_FORM_INFO_SUCCESS,
  types.UPDATE_NOTICE_SUBSCRIPTION_FORM_INFO_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_NOTICE_SUBSCRIPTION_FORM_INFO_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      info: data,
      isLoading: false,
    }),
    [types.CREATE_NOTICE_SUBSCRIPTION_FORM_INFO_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      info: data,
      isLoading: false,
    }),
    [types.UPDATE_NOTICE_SUBSCRIPTION_FORM_INFO_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      info: data,
      isLoading: false,
    }),
    [types.SET_NOTICE_SUBSCRIPTION_FORM_INFO]: (state, { info }) => ({
      ...state,
      info,
    }),
    [types.CLEAR_STATE]: () => {
      return { ...INITIAL_STATE }
    },
  },
  INITIAL_STATE
)

export default reducer
