import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Content } from './styles'

import { CloseIcon } from '~/styles/Icons'

function ButtonTag(props) {
  return (
    <Content
      {...props}
      className={classnames(props.className, { active: props.active }, 'button')}
    >
      {props.children}
      <img className='accessibility_invert' src={CloseIcon} aria-hidden alt="" />
    </Content>
  )
}

ButtonTag.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  children: PropTypes.string,
  active: PropTypes.bool,
}

ButtonTag.defaultProps = {
  type: 'button',
}

export default ButtonTag
