import { handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: false,
  subscription: null,
}

const reducer = handleActions(
  {
    [types.SET_SUBSCRIPTION]: (state, { subscription }) => ({
      ...state,
      subscription,
    }),
    [types.CLEAR_STATE]: () => {
      return { ...INITIAL_STATE }
    },
  },
  INITIAL_STATE
)

export default reducer
