import store from '~/boot/store'

import { updateNoticeSubscriptionFormInfo } from '../../Info/store/actions'

import { setNoticeSubscriptionFormQuestionnaire, partialUpdateNoticeSubscriptionFormQuestionnaire } from '../store/actions'

const updateQuestionnaire = (callback) => {
  const notice = store.getState().notice.notice
  const questionnaire = store.getState().noticeSubscriptionFormQuestionnaire
    .noticeQuestionnaire

  const newQuestionnaire = callback ? callback(questionnaire) : questionnaire
  const filteredNewQuestionnaire = newQuestionnaire.filter(x => !x.delete).map(x => ({ ...x, dirty: false, questions: x?.questions.filter(y => !y.delete).map(z => ({ ...z, dirty: false })) }))
  const alteredSections = newQuestionnaire.filter(section => section.dirty)
  const alteredQuestions = alteredSections[0]?.questions.filter(question => question.dirty)
  const dataToUpdate = {
    ...alteredSections[0],
    questions:  alteredQuestions
  }

  if (notice && notice.id) {
    store.dispatch(updateNoticeSubscriptionFormInfo(notice.id))
    store.dispatch(setNoticeSubscriptionFormQuestionnaire(filteredNewQuestionnaire))
    if (alteredSections.length)
      store.dispatch(
        partialUpdateNoticeSubscriptionFormQuestionnaire(notice.id, dataToUpdate)
      )
  }
}

export default updateQuestionnaire
