import React from 'react'
import { Col, Container, H1, Row, T2 } from '~/components'

const Forbidden = () => {
  return (
    <Container>
      <Row>
        <Col>
          <H1 className="mt-5" style={{ textAlign: 'center' }}>
            Permissão Negada
          </H1>
          <T2 className="mt-3" style={{ textAlign: 'center' }}>
            Você não possui permissão para executar essa ação ou acessar esse
            recurso
          </T2>
        </Col>
      </Row>
    </Container>
  )
}

export default Forbidden
