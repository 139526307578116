import styled from 'styled-components'
import { CheckboxOnIcon, CheckboxOnBlackIcon } from '~/styles/Icons'
import colors from '~/utils/colors'

export const Content = styled.div`
  display: flex;
  align-items: center;

  > label {
    margin-left: 10px;
    font-family: 'Itau Display Regular';
    font-size: 14px;
    line-height: 18px;
  }

  input {
    position: relative;
    cursor: pointer;

    width: ${({ size }) => (size ? size + 'px' : '30px')};
    height: ${({ size }) => (size ? size + 'px' : '30px')};
  }

  input:before {
    content: '';
    display: block;
    position: absolute;

    top: 0;
    left: 0;

    width: ${({ size }) => (size ? size + 'px' : '30px')};
    height: ${({ size }) => (size ? size + 'px' : '30px')};

    ${({ dark }) =>
      dark
        ? `
          background-color: var(--color-light);
          `
        : `
          background-color: var(--color-white);
          `}
  }

  input:after {
    content: '';
    display: block;
    position: absolute;

    overflow: hidden;

    top: 0;
    left: 0;

    width: ${({ size }) => (size ? size + 'px' : '30px')};
    height: ${({ size }) => (size ? size + 'px' : '30px')};

    outline: 0;
    flex-shrink: 0;
    appearance: none;

    border: 5px solid;
    border-radius: 5px;

    ${({ dark }) =>
      dark
        ? `
          border-color: var(--color-white);
          background-color: var(--color-light);
          `
        : `
          border-color: var(--color-light);
          background-color: var(--color-white);
          `}
  }

  > input:hover:after,
  > input:focus:after,
  &.active input:after {
    ${({ dark, activeDark }) =>
      dark || activeDark
        ? `
          border-color: var(--color-dark);
          `
        : `
          border-color: var(--color-yellow);
          `}
  }

  > input:checked::after {
    ${({ dark, activeDark }) =>
      dark || activeDark
        ? `
          border: none;
          background-image: url(${CheckboxOnBlackIcon});
          `
        : `
          border: none;
          background-image: url(${CheckboxOnIcon});
          `}
    background-repeat: no-repeat;
    background-size: ${({ size }) => (size ? size + 'px' : '30px')};
    background-position: center center;
  }

  > input::-ms-check {
    border: 5px solid;
    border-radius: 5px;
    flex-shrink: 0;

    color: transparent;

    ${({ dark }) =>
      dark
        ? `
          border-color: var(--color-white);
          `
        : `
          border-color: var(--color-light);
          `}
  }

  > input:hover::-ms-check,
  > input:focus::-ms-check,
  &.active input::-ms-check {
    ${({ dark, disabled, activeDark }) =>
      dark || activeDark
        ? `border-color: var(--color-dark);`
        : `border-color: ${disabled ? colors.light : colors.yellow};`}
  }

  > input:checked::-ms-check {
    ${({ dark, activeDark }) =>
      dark || activeDark
        ? `
          border: none;
          background-image: url(${CheckboxOnBlackIcon});
          `
        : `
          border: none;
          background-image: url(${CheckboxOnIcon});
          `}
    background-repeat: no-repeat;
    background-size: ${({ size }) => (size ? size + 'px' : '30px')};
    background-position: center center;
  }
`
