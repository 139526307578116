import styled from 'styled-components'



import { Link } from 'react-router-dom'

import { ArrowRightIcon, BulletsIcon } from '~/styles/Icons'

export const FormCardStyle = styled(Link)`
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border-radius: 10px 10px 20px 10px;
  width: 270px;
  min-height: 260px;
  position: relative;
  padding-bottom: 37px;
  color: var(--color-dark);
  padding: 35px 20px 40px 20px;

  box-shadow: 0 10px 20px 0 rgba(102, 102, 102, 0.05);

  &:hover,
  &:focus,
  &:active,
  &:link,
  &:visited {
    color: var(--color-dark);
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &.active {
    transform: translateY(-10px);
    box-shadow: none;
  }
`

export const ArrowButton = styled.div`
  background: var(--color-yellow);
  background-image: url(${ArrowRightIcon});
  background-size: 24px 24px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 10px;
  right: 10px;
`

export const FormCardHeader = styled.div`
  width: 100%;
  height: 94px;
`

export const Status = styled.p`
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-white);
  position: absolute;
  top: -15px;
  left: 20px;
  height: 30px;
  padding: 5px 20px 7px 20px;
  background: var(--color-gray);
  border-radius: 5px;
  ${({ isOn }) =>
    isOn &&
    `
    background: var(--color-green);
  `}
`

export const Name = styled.p`
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-dark);
`

export const Title = styled.h4`
  font-family: 'Itau Text Regular';
  font-size: 18px;
  line-height: 23px;
  color: var(--color-darkGray);
  margin-top: 10px;

  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 46px;
`

export const FormCardBody = styled.div`
  width: 100%;
`

export const FormCardLabel = styled.p`
  margin-top: 20px;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-dark);
`

export const FormCardValue = styled.p`
  margin-top: 5px;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-darkGray);
`

export const FormCardBy = styled.p`
  margin-top: 5px;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-darkGray);
  position: absolute;
  bottom: 15px;
  left: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 18px;
  width: 200px;

  span {
    color: var(--color-dark);
  }
`

export const FormCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const FormCardHr = styled.hr`
  background: var(--color-light);
  border-radius: 10px;
  height: 2px;
  width: 100%;
  border: 0;
  margin: 0;
`

export const Bullets = styled.div`
  background: var(--color-white);
  background-image: url(${BulletsIcon});
  background-size: 26.67px 26.67px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 10px;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 5px;
  right: 5px;
`
