import React from 'react'

import isUserManagerOrConsultant from './middlewares/isUserManagerOrConsultant'
import isEvaluator from './middlewares/isEvaluator'
import isActiveEvaluations from './middlewares/isActiveEvaluations'
import getNotice from './middlewares/getNotice'

const HomeMyRelatedNotices = React.lazy(() =>
  import('./subpages/MyRelatedNotices')
)
const MyRelatedSubscriptions = React.lazy(() =>
  import('./subpages/MyRelatedSubscriptions')
)
const MyEvaluations = React.lazy(() => import('./subpages/MyEvaluations'))
const NoticeData = React.lazy(() => import('./subpages/NoticeData'))

const homeRoutes = [
  {
    path: '/home/meus-editais',
    component: HomeMyRelatedNotices,
    exact: true,
    middlewares: [isUserManagerOrConsultant],
  },
  {
    path: '/home/minhas-inscricoes',
    component: MyRelatedSubscriptions,
    exact: true,
  },
  {
    path: '/home/minhas-avaliacoes',
    component: MyEvaluations,
    exact: true,
    middlewares: [isEvaluator, isActiveEvaluations],
  },
  {
    path: '/home/minhas-avaliacoes/edital/:id',
    component: NoticeData,
    exact: true,
    middlewares: [isEvaluator, getNotice],
  },
]

export default homeRoutes
