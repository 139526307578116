import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
  CaracterCountStyle,
  InputGroupStyle,
  InputStyle,
  LabelStyle,
} from './styles'
import classNames from 'classnames'

function Input({
  defaultValue,
  labelInfo,
  label,
  id,
  className,
  active,
  showCount,
  maxLength,
  onChange,
  useFormik,
  value,
  error,
  groupStyle,
  groupClassName,
  ...props
}) {
  const [inputValue, setInputValue] = useState(defaultValue || value)

  useEffect(() => {
    showCount && setInputValue(value)
  }, [showCount, value])

  return (
    <InputGroupStyle style={groupStyle} className={classNames(groupClassName, 'input-group')}>
      {labelInfo || (label && <LabelStyle htmlFor={id}>{label}</LabelStyle>)}
      <InputStyle
        {...props}
        id={id}
        maxLength={maxLength}
        className={classnames(className, { active: active }, { error: error })}
        onChange={(e) =>
          onChange ? onChange(e) : setInputValue(e.target.value)
        }
        value={value}
        defaultValue={defaultValue}
        error={error}
      />

      {showCount && (
        <CaracterCountStyle>
          {String(inputValue || defaultValue || '').length}
          {maxLength && ` / ${maxLength}`} caracteres
        </CaracterCountStyle>
      )}
    </InputGroupStyle>
  )
}

function InputMask({
  defaultValue,
  labelInfo,
  label,
  id,
  className,
  active,
  showCount,
  maxLength,
  onChange,
  onBlur,
  error,
  maskFunc,
  groupStyle,
  groupClassName,
  ...props
}) {
  // quando maskFunc igual money, passar props maxLength = 21

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (defaultValue) setInputValue(maskFunc(String(defaultValue)).maskedString)
    else setInputValue('')
  }, [defaultValue, maskFunc])

  return (
    <InputGroupStyle style={groupStyle} className={groupClassName}>
      {labelInfo || (label && <LabelStyle htmlFor={id}>{label}</LabelStyle>)}
      <InputStyle
        {...props}
        id={id}
        className={classnames(className, { active: active }, { error: error })}
        error={error}
        onBlur={() => onBlur && onBlur(inputValue)}
        onChange={({ target: { value } }) => {
          const { string, maskedString } = maskFunc(value)
          onChange && onChange(string, maskedString)
          setInputValue(maskedString)
        }}
        maxLength={maxLength}
        value={inputValue}
      />
      {showCount && (
        <CaracterCountStyle>
          {String(inputValue || '').length}
          {maxLength && ` / ${maxLength}`} caracteres
        </CaracterCountStyle>
      )}
    </InputGroupStyle>
  )
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  active: PropTypes.bool,
  showCount: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  labelInfo: PropTypes.element,
  error: PropTypes.bool,
}

InputMask.propTypes = {
  maskFunc: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  maxlength: PropTypes.number,
  minlength: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  active: PropTypes.bool,
  showCount: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  labelInfo: PropTypes.element,
  error: PropTypes.bool,
}

export default {
  Input,
  InputMask,
}

export { Input, InputMask }
