import React from 'react'
import PropTypes from 'prop-types'

import { Content } from './styles'

function HorizontalRule(props) {
  return <Content {...props} />
}

HorizontalRule.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  opacity: PropTypes.number,
  radius: PropTypes.number,
}

HorizontalRule.default = {
  opacity: 1,
}

export default HorizontalRule
