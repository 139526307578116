import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD_NOTICE_EVALUATION_FORM'),
    ...async('LOAD_NOTICE_EVALUATION_ANSWERS'),
    ...async('UPDATE_NOTICE_EVALUATION_ANSWERS'),
    ...async('FINISH_NOTICE_EVALUATION_ANSWERS'),
    'SET_NOTICE_EVALUATION_ANSWERS',
    'SET_NOTICE_EVALUATION_FILES',
    'CLEAR_STATE',
  ],
  'NOTICE_EVALUATION'
)
