import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'



export const Content = styled.div`
  position: relative;
`

export const MainButton = styled.button`
  position: relative;
  width: ${({ width }) => width + 'px'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Itau Display Regular';
  color: var(--color-white);
  background: var(--color-dark);
  height: 60px;
  font-size: 16px;
  line-height: 21px;
  padding: 15px 30px 15px 20px;

  ${({ icon, right }) =>
    !icon
      ? `
          padding: 15px 30px 15px 30px;
          `
      : !right
      ? `
          padding: 15px 30px 15px 20px;
          `
      : `
          padding: 15px 20px 15px 30px;
          `}

  > img {
    display: block;
    height: 30px;
    width: 30px;
    ${({ right }) => (!right ? 'margin-right: 10px;' : 'margin-left: 10px;')}
  }

  > span {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 4px;
    width: 80%;
    background: var(--color-yellow);
    border-radius: 100px 100px 0 0;
  }

  &:focus,
  &:hover {
    text-decoration: none;
    color: var(--color-white);
    > span {
      display: block;
    }
  }
`

export const List = styled.div`
  position: absolute;
  bottom: 0;
  top: 55px;
  z-index: 2;
  margin-top: 5px;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding-top: 5px;
  min-width: ${({ width }) => width + 'px'};
  width: fit-content;

  ul {
    list-style: none;
    padding: 15px 7px 20px 7px;
    margin: 0;
    background: var(--color-dark);
    border-radius: 10px;
  }

  li + li {
    margin-top: 3px;
    width: 100%;
  }
`

const OptionStyle = css`
  display: flex;
  flex-direction: row;
  color: var(--color-white);
  font-size: 16px;
  line-height: 21px;
  padding: 7px 13px;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  text-align: left;
  align-items: center;

  &:hover,
  &:focus,
  &.active {
    color: var(--color-dark);
    background: var(--color-yellow);
    text-decoration: none;

    img {
      :nth-child(1) {
        display: none;
      }
      :nth-child(2) {
        display: initial;
      }
    }
  }

  img {
    width: 34px;
    height: 34px;

    :nth-child(1) {
      display: initial;
    }
    :nth-child(2) {
      display: none;
    }

    ${({ right }) => (right ? `margin-left: 10px;` : `margin-right: 10px;`)}
  }
`

export const LinkStyle = styled(Link)`
  ${OptionStyle}
`

export const ExternalLinkStyle = styled.a`
  ${OptionStyle}
`

export const ButtonStyle = styled.button`
  ${OptionStyle}
`
