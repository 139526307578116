import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  getSubscriptionByNoticeId,
  getSubscriptionByNoticeIdAndOrder,
} from '../services/requests'

import { getSubscriptionForm, setSubscription } from '../store/actions'

import { getNotice } from '~/views/Notices/services/requests'
import { setNotice } from '~/views/Notices/store/actions'

export default function hasSubscription(resolve, redirect, { match, history }) {
  return () => {
    const dispatch = useDispatch()

    useEffect(() => {
      async function findSubscription() {
        const noticeId = match.params.id
        const order = match.params.order

        dispatch(setSubscription({}))

        const notice = await getNotice(noticeId)

        if (!notice) return history.push('/home/minhas-inscricoes')
        if (notice.status === 'DRAFT')
          return history.push('/home/minhas-inscricoes')
        if (!notice.publishDate) return history.push('/home/minhas-inscricoes')

        dispatch(setNotice(notice))
        dispatch(getSubscriptionForm(notice && notice.id))

        let subscription = null

        if (Number(order)) {
          subscription = await getSubscriptionByNoticeIdAndOrder(
            noticeId,
            order
          )
        } else {
          subscription = await getSubscriptionByNoticeId(noticeId)
        }

        if (!subscription) return history.push('/home/minhas-inscricoes')

        dispatch(setSubscription(subscription))
      }

      findSubscription()
    }, [dispatch])

    return resolve()
  }
}
