import React, { useEffect, useState } from 'react'

import Checkbox from '~/components/Checkbox'
import T3 from '~/components/T3'
import { ArrowDownIcon } from '~/styles/Icons'
import colors from '~/utils/colors'
import fonts from '~/utils/fonts'
import { Content, List, ButtonStyle, ArrowStyle, MainButton } from './styles'

const IndeterminateCheckboxDropdown = ({
  onSelectAll,
  onSelectPage,
  onSelectIdList,
  getIsSelectedAll,
  getData,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const isSelectedAll = getIsSelectedAll()
  const data = getData()

  useEffect(() => {
    setIsChecked(isSelectedAll)
  }, [isSelectedAll])

  function onClickCheckbox() {
    if (isChecked) {
      if (onSelectAll) onSelectAll([])

      setIsChecked(false)
    } else {
      setIsOpen(true)
    }
  }

  function onClickOptionPage(e) {
    setIsChecked(!isChecked)
    setIsOpen(false)

    if (onSelectPage) onSelectPage(data)
  }

  function onClickOptionAll() {
    setIsChecked(!isChecked)
    setIsOpen(false)

    if (onSelectAll) onSelectAll('*')
  }

  function onClickOptionIdList() {
    setIsOpen(false)

    if (onSelectIdList) onSelectIdList()
  }

  return (
    <Content>
      <MainButton onClick={onClickCheckbox} aria-label="Selecionar linha">
        <Checkbox
          aria-label="Selecionar linha"
          activeDark
          checked={isChecked}
          onChange={() => {}}
        />
        <ArrowStyle src={ArrowDownIcon} alt="" aria-hidden={true} className="accessibility_invert" />
      </MainButton>

      <List
        //aria-labelledby={id}
        isOpen={isOpen}
        width={208}
        onMouseLeave={() => setIsOpen(false)}
        onMouseEnter={() => setIsOpen(true)}
      >
        <ul>
          <T3
            color={colors.yellow}
            font={fonts.displayXBold}
            style={{ paddingLeft: '10px', marginBottom: '5px' }}
          >
            Seleção em massa:
          </T3>
          <li>
            <ButtonStyle onClick={onClickOptionPage}>
              &gt; <span> Todos itens da página</span>
            </ButtonStyle>
            <ButtonStyle onClick={onClickOptionAll}>
              &gt; <span> Todos itens da tabela</span>
            </ButtonStyle>
            {
              onSelectIdList && (
                <ButtonStyle onClick={onClickOptionIdList}>
                  &gt; <span> Lista de ID's</span>
                </ButtonStyle>
              )
            }
          </li>
        </ul>
      </List>
    </Content>
  )
}

export default IndeterminateCheckboxDropdown
