import styled from 'styled-components'
import breakpoints from '~/utils/breakpoints'



export const LabelStyle = styled.label`
  display: flex;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  padding-bottom: ${({ labelSpace }) =>
    labelSpace ? labelSpace + 'px' : '5px'};
`

export const InputStyle = styled.input`
  outline: 0;
  display: flex;
  font-family: 'Itau Text Regular';
  font-size: 16px;
  line-height: 21px;
  padding: 14px 20px 15px 20px;
  border: 2px solid var(--color-light);
  width: 100%;
  height: 50px;

  &:focus,
  &.active {
    border-color: var(--color-yellow);
  }

  &:disabled {
    background-color: rgba(243, 237, 223, 0.7);
  }

  ::placeholder {
    color: var(--color-gray);
  }
`

export const List = styled.div`
  z-index: 2;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  border: 2px solid var(--color-light);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-width: 0px;
  max-height: 194px;
  overflow-y: scroll;
  position: absolute;

  ::-webkit-scrollbar {
    width: 14px;
  }
  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 5px solid var(--color-light);
    -webkit-border-radius: 7px;
    background-color: var(--color-darkGray);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--color-white);
  }

  ul {
    list-style: none;
    margin: 0;
    background: var(--color-white);
    padding-top: 20px;
    padding-bottom: 28px;
  }
`

export const Button = styled.button`
  text-decoration: none;
  padding: 10px 20px 10px 20px;
  width: 100%;
  text-align: left;

  &:hover,
  &:focus,
  &:active {
    color: var(--color-dark);
    background: var(--color-light);
    padding-right: 54px;
    border-radius: 0 10px 10px 0;
    border-left: 0px;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    width: 95%;
  }
`

export const ContentStyle = styled.div`
  position: relative;

  @media (max-width: calc(${breakpoints.md} - 1px)) {
    width: 100%;
  }
`
