import React from 'react'
import PropTypes from 'prop-types'

import {
  CardStyle,
  ArrowButton,
  CardHeader,
  CardBody,
  Status,
  Title,
  CardLabelStyle,
  CardValueStyle,
  CardByStyle,
  CardRowStyle,
  CardHrStyle,
  CardProgressStyle,
} from './styles'

function Card({ to, status, title, children, className, ...props }) {
  return (
    <CardStyle
      to={to}
      title={title}
      aria-label={`Visualizar ${title}`}
      className={`card ${className}`}
      {...props}
    >
      <CardHeader className='card-header'>
        <Status>{status}</Status>
        <Title>{title}</Title>
      </CardHeader>
      <CardBody>{children}</CardBody>
      <ArrowButton />
    </CardStyle>
  )
}

function CardLabel({ children, ...props }) {
  return <CardLabelStyle {...props}>{children}</CardLabelStyle>
}

function CardValue({ children, ...props }) {
  return <CardValueStyle {...props}>{children}</CardValueStyle>
}

function CardBy({ by, ...props }) {
  return (
    <CardByStyle {...props}>
      <span>Por: </span>
      {by}
    </CardByStyle>
  )
}

function CardRow({ children, ...props }) {
  return <CardRowStyle {...props}>{children}</CardRowStyle>
}

function CardItem({ label, value, ...props }) {
  return (
    <div {...props}>
      <CardLabelStyle>{label}</CardLabelStyle>
      <CardValueStyle>{value}</CardValueStyle>
    </div>
  )
}

function CardHr(props) {
  return <CardHrStyle {...props} />
}

function CardProgress({ progress, ...props }) {
  return (
    <CardProgressStyle
      progress={progress >= 7 || progress === 0 ? progress : 7}
      {...props}
    >
      <div />
    </CardProgressStyle>
  )
}

Card.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

CardItem.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CardBy.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  by: PropTypes.string,
}

CardProgress.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  progress: PropTypes.number,
}

CardProgress.defaultProps = {
  progress: 0,
}

Card.Label = CardLabel
Card.Value = CardValue
Card.By = CardBy
Card.Row = CardRow
Card.Item = CardItem
Card.Hr = CardHr
Card.Progress = CardProgress

export default Card
