import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useDebounce from '~/hooks/useDebounce'
import useOutsideClick from '~/hooks/useOutsideClick'

import { LabelStyle, List, SearchInputStyle } from './styles'

function SearchInput({ makeSearch, ...props }) {
  const debouncedSearchTerm = useDebounce(props.value, 1000)
  const wrapperRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)

  useOutsideClick(wrapperRef, () => setIsFocused(false))

  useEffect(() => {
    if (debouncedSearchTerm) makeSearch(debouncedSearchTerm)
  }, [debouncedSearchTerm, makeSearch])

  return (
    <div
      ref={wrapperRef}
      className={classnames(props.className)}
      aria-label={props['aria-label']}
      aria-haspopup="true"
      aria-expanded={!!(props.value && props.value.length)}
      style={{ position: 'relative' }}
    >
      {props.labelInfo ||
        (props.label && (
          <LabelStyle htmlFor={props.id}>{props.label}</LabelStyle>
        ))}
      <SearchInputStyle
        id={props.id}
        className='input-search'
        style={props.style}
        onChange={(e) => {
          props.onChange && props.onChange(e)
          setIsFocused(true)
        }}
        onFocus={() => {
          if (props.value && props.value.length) setIsFocused(true)
        }}
        aria-label={props['aria-label']}
        placeholder={props.placeholder || 'Digite sua busca'}
        value={props.value}
        autoComplete="off"
      />
      {props.data && props.data.length && isFocused ? (
        <List
          aria-labelledby={props.id}
          isOpen={props.value && props.value.length}
          className='input-search-list'
        >
          <ul>{props.children}</ul>
        </List>
      ) : null}
    </div>
  )
}

SearchInput.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  maxlength: PropTypes.number,
  minlength: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  active: PropTypes.bool,
  labelInfo: PropTypes.element,
  size: PropTypes.number,
}

export default SearchInput
