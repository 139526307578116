import { useEffect } from 'react'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'
import * as requests from '../services/requests'

export default function isEvaluator(resolve, redirect, { match, history }) {
  return () => {
    const user = useShallowEqualSelector((state) => state.profile.user)

    useEffect(() => {
      async function findEvaluations() {
        if (user && user.id) {
          const evaluations = await requests.getEvaluations(user.id)

          if (!evaluations?.length) {
            return history.push('/home/meus-editais')
          }
        }
      }

      findEvaluations()
    }, [user])

    return resolve()
  }
}
