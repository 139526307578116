import { handleActions, combineActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  user: {
    name: '',
    company: '',
    email: '',
    cpf: '',
    rne: '',
    cnpj: '',
    birthdate: '',
    phone: '',
    gender: '',
    ethnicity: '',
    education: '',
    pwd: false,
    deficiency: '',
    country: '',
    state: '',
    city: '',
    ocupation: '',
    organizationType: '',
    companyCnpj: '',
    companyInep: '',
    companyName: '',
    companyArea: '',
    profileImageUrl: '',
    lastModified: '',
    lastLogin: '',
    noticesOccupations: [],
    isAdmin: false,
  },
}

const beginLoading = combineActions(types.LOAD_USER)

const stopLoading = combineActions(
  types.LOAD_USER_SUCCESS,
  types.LOAD_USER_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_USER_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      user: data,
      isLoading: false,
    }),
    [types.CLEAR_STATE]: () => {
      return { ...INITIAL_STATE }
    },
    [types.CHANGE_USER]: (state, { values }) => {
      return {
        ...state,
        user: { ...state.user, ...values },
      }
    },
  },
  INITIAL_STATE
)

export default reducer
