import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD_NOTICES'),
    ...async('LOAD_NOTICE'),
    ...async('STORE_NOTICE'),
    ...async('DELETE_NOTICE'),
    ...async('SEARCH_NOTICES_BY_NAME_PUBLISH'),
    ...async('GET_BRAZIL_REGIONS'),
    ...async('GET_PROCESS_STEPS'),
    'SET_NOTICES',
    'SET_NOTICE',
    'CLEAR_STATE',
  ],
  'NOTICE'
)
