import store from '~/boot/store'

import { setNoticeEvaluationCriteriaForm, partialUpdateNoticeEvaluationCriteriaForm } from '../store/actions'

const updateForm = (callback) => {
  const notice = store.getState().notice.notice
  const form = store.getState().noticeProcessStepsEvaluationCriteriaForm.form

  const newForm = callback ? callback(form) : form
  const filteredNewForm = {
    ...newForm,
    groups: newForm?.groups?.filter(x => !x.delete).map(x => ({ ...x, dirty: false, criterias: x?.criterias.filter(y => !y.delete).map(z => ({ ...z, dirty: false })) }))
  }
  const alteredGroups = newForm?.groups?.filter(x => x.dirty)

  if (alteredGroups[0]?.criterias) {
    const alteredCriterias = alteredGroups[0].criterias.filter(x => x.dirty)
    alteredGroups[0] = {
      ...alteredGroups[0],
      criterias: alteredCriterias
    }
  }

  const dataToUpdate = {
    ...newForm,
    groups: alteredGroups,
  }

  if (notice && notice.id) {
    store.dispatch(setNoticeEvaluationCriteriaForm(filteredNewForm))

    if (alteredGroups.length || filteredNewForm.dirty)
      store.dispatch(partialUpdateNoticeEvaluationCriteriaForm(notice.id, dataToUpdate))
  }
}

export default updateForm
