import styled from 'styled-components'

import fonts from '~/utils/fonts'

export const StyledInline = styled.div`
  height: 50px;
  background-color: var(--color-light);
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const CardInfo = styled.small`
  height: 23px;
  line-height: 23px;
  background-color: var(--color-green) !important;
  font-family: ${fonts.displayRegular};
  color: var(--color-white);
  padding: 0 10px;
  border-radius: 5px 0 0 5px;
  position: absolute;
  right: 15px;
  margin-top: -15px;
  display: ${({ children }) => (children ? 'block' : 'none')};
`
