import styled from 'styled-components'


export const Content = styled.div`
  width: fit-content;
  ${({ block }) => block && `width:100%;`}
  > input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  > input + span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    ${({ block }) => block && `width:100%;`}
    min-height: 60px;
    background: var(--color-white);
    padding: 10px 18px 10px 15px;
    border: 5px solid var(--color-light);
    border-radius: 10px;

    font-family: 'Itau Display Regular';
    font-size: 16px;
    line-height: 21px;
    color: var(--color-dark);

    cursor: pointer;

    > img {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }
  }

  > input:focus + span,
  > input + span:hover,
  &.active > span,
  &.hover > span {
    border-color: var(--color-yellow);
    background: var(--color-yellow);
  }

  > input:focus + span {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  > input:disabled + span {
    cursor: default;

    &:hover {
      background-color: var(--color-white);
      border-color: var(--color-light);
    }
  }
`
