import { createGlobalStyle } from 'styled-components'
import breakpoints from '~/utils/breakpoints'

export default createGlobalStyle`
  .font-display-regular {
    font-family:'Itau Display Regular'
  }
  .font-display-bold {
    font-family:'Itau Display Bold'
  }
  .font-display-xbold {
    font-family:'Itau Display XBold'
  }
  .font-text-regular {
    font-family:'Itau Text Regular'
  }
  .font-text-bold {
    font-family:'Itau Text Bold'
  }
  .font-text-xbold {
    font-family:'Itau Text XBold'
  }

  .p-0 {
    padding: 0px;
  }
  .py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .px-0 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .pt-0 {
    padding-top: 0px;
  }
  .pr-0 {
    padding-right: 0px;
  }
  .pb-0 {
    padding-bottom: 0px;
  }
  .pl-0 {
    padding-left: 0px;
  }

  .p-0-5 {
    padding: 5px;
  }
  .py-0-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .px-0-5 {
    padding-right: 5px;
    padding-left: 5px;
  }
  .pt-0-5 {
    padding-top: 5px;
  }
  .pr-0-5 {
    padding-right: 5px;
  }
  .pb-0-5 {
    padding-bottom: 5px;
  }
  .pl-0-5 {
    padding-left: 5px;
  }

  .p-1 {
    padding: 10px;
  }
  .py-1 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .px-1 {
    padding-right: 10px;
    padding-left: 10px;
  }
  .pt-1 {
    padding-top: 10px;
  }
  .pr-1 {
    padding-right: 10px;
  }
  .pb-1 {
    padding-bottom: 10px;
  }
  .pl-1 {
    padding-left: 10px;
  }

  .p-2 {
    padding: 15px;
  }
  .py-2 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .px-2 {
    padding-right: 15px;
    padding-left: 15px;
  }
  .pt-2 {
    padding-top: 15px;
  }
  .pr-2 {
    padding-right: 15px;
  }
  .pb-2 {
    padding-bottom: 15px;
  }
  .pl-2 {
    padding-left: 15px;
  }

  .p-3 {
    padding: 20px;
  }
  .py-3 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .px-3 {
    padding-right: 20px;
    padding-left: 20px;
  }
  .pt-3 {
    padding-top: 20px;
  }
  .pr-3 {
    padding-right: 20px;
  }
  .pb-3 {
    padding-bottom: 20px;
  }
  .pl-3 {
    padding-left: 20px;
  }

  .p-4 {
    padding: 30px;
  }
  .py-4 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .px-4 {
    padding-right: 30px;
    padding-left: 30px;
  }
  .pt-4 {
    padding-top: 30px;
  }
  .pr-4 {
    padding-right: 30px;
  }
  .pb-4 {
    padding-bottom: 30px;
  }
  .pl-4 {
    padding-left: 30px;
  }

  .p-5 {
    padding: 50px;
  }
  .py-5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .px-5 {
    padding-right: 50px;
    padding-left: 50px;
  }
  .pt-5 {
    padding-top: 50px;
  }
  .pr-5 {
    padding-right: 50px;
  }
  .pb-5 {
    padding-bottom: 50px;
  }
  .pl-5 {
    padding-left: 50px;
  }

  .p-6 {
    padding: 80px;
  }
  .py-6 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .px-6 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .pt-6 {
    padding-top: 80px;
  }
  .pr-6 {
    padding-right: 80px;
  }
  .pb-6 {
    padding-bottom: 80px;
  }
  .pl-6 {
    padding-left: 80px;
  }

  .p-7 {
    padding: 130px;
  }
  .py-7 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  .px-7 {
    padding-right: 130px;
    padding-left: 130px;
  }
  .pt-7 {
    padding-top: 130px;
  }
  .pr-7 {
    padding-right: 130px;
  }
  .pb-7 {
    padding-bottom: 130px;
  }
  .pl-7 {
    padding-left: 130px;
  }

  .p-auto {
    padding: auto;
  }
  .py-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .px-auto {
    padding-right: auto;
    padding-left: auto;
  }
  .pt-auto {
    padding-top: auto;
  }
  .pr-auto {
    padding-right: auto;
  }
  .pb-auto {
    padding-bottom: auto;
  }
  .pl-auto {
    padding-left: auto;
  }

  .m-0 {
    margin: 0px;
  }
  .my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .mx-0 {
    margin-right: 0px;
    margin-left: 0px;
  }
  .mt-0 {
    margin-top: 0px;
  }
  .mr-0 {
    margin-right: 0px;
  }
  .mb-0 {
    margin-bottom: 0px;
  }
  .ml-0 {
    margin-left: 0px;
  }

  .m-0-5 {
    margin: 5px;
  }
  .my-0-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .mx-0-5 {
    margin-right: 5px;
    margin-left: 5px;
  }
  .mt-0-5 {
    margin-top: 5px;
  }
  .mr-0-5 {
    margin-right: 5px;
  }
  .mb-0-5 {
    margin-bottom: 5px;
  }
  .ml-0-5 {
    margin-left: 5px;
  }

  .m-1 {
    margin: 10px;
  }
  .my-1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mx-1 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .mt-1 {
    margin-top: 10px;
  }
  .mr-1 {
    margin-right: 10px;
  }
  .mb-1 {
    margin-bottom: 10px;
  }
  .ml-1 {
    margin-left: 10px;
  }

  .m-2 {
    margin: 15px;
  }
  .my-2 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .mx-2 {
    margin-right: 15px;
    margin-left: 15px;
  }
  .mt-2 {
    margin-top: 15px;
  }
  .mr-2 {
    margin-right: 15px;
  }
  .mb-2 {
    margin-bottom: 15px;
  }
  .ml-2 {
    margin-left: 15px;
  }

  .m-3 {
    margin: 20px;
  }
  .my-3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mx-3 {
    margin-right: 20px;
    margin-left: 20px;
  }
  .mt-3 {
    margin-top: 20px;
  }
  .mr-3 {
    margin-right: 20px;
  }
  .mb-3 {
    margin-bottom: 20px;
  }
  .ml-3 {
    margin-left: 20px;
  }

  .m-4 {
    margin: 30px;
  }
  .my-4 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .mx-4 {
    margin-right: 30px;
    margin-left: 30px;
  }
  .mt-4 {
    margin-top: 30px;
  }
  .mr-4 {
    margin-right: 30px;
  }
  .mb-4 {
    margin-bottom: 30px;
  }
  .ml-4 {
    margin-left: 30px;
  }

  .m-5 {
    margin: 50px;
  }
  .my-5 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .mx-5 {
    margin-right: 50px;
    margin-left: 50px;
  }
  .mt-5 {
    margin-top: 50px;
  }
  .mr-5 {
    margin-right: 50px;
  }
  .mb-5 {
    margin-bottom: 50px;
  }
  .ml-5 {
    margin-left: 50px;
  }

  .m-6 {
    margin: 80px;
  }
  .my-6 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mx-6 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mt-6 {
    margin-top: 80px;
  }
  .mr-6 {
    margin-right: 80px;
  }
  .mb-6 {
    margin-bottom: 80px;
  }
  .ml-6 {
    margin-left: 80px;
  }

  .m-7 {
    margin: 130px;
  }
  .my-7 {
    margin-top: 130px;
    margin-bottom: 130px;
  }
  .mx-7 {
    margin-right: 130px;
    margin-left: 130px;
  }
  .mt-7 {
    margin-top: 130px;
  }
  .mr-7 {
    margin-right: 130px;
  }
  .mb-7 {
    margin-bottom: 130px;
  }
  .ml-7 {
    margin-left: 130px;
  }

  .m-auto {
    margin: auto;
  }
  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mx-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .mt-auto {
    margin-top: auto;
  }
  .mr-auto {
    margin-right: auto;
  }
  .mb-auto {
    margin-bottom: auto;
  }
  .ml-auto {
    margin-left: auto;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .align-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .justify-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .align-row-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .justify-row-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .align-row-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .justify-row-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .mobile-center {
    @media (max-width: 798px) {
      text-align: center;
      justify-content: center;
    }
  }

  .md-center {
    @media (max-width: calc(${breakpoints.md} - 1px)) {
      text-align: center;
    }
  }

  .md-hide {
    @media (max-width: calc(${breakpoints.md} - 1px)) {
      display: none;
    }
  }

  .mobile-hide {
    @media (max-width: 798px) {
      display: none;
    }
  }

  .mobile-show {
    @media (max-width: 798px) {
      display: flex;
    }
  }

  .scroll-lock {
     overflow: hidden;
  }

  .block-xs {
    @media (max-width: calc(${breakpoints.sm} - 1px)) {
      width:100%!important;
    }
  }

  .block-sm {
    @media (min-width: calc(${breakpoints.sm} - 1px)) and (max-width: calc(${breakpoints.md} - 1px)) {
      width:100%!important;
    }
  }

  .block-md {
    @media (min-width: calc(${breakpoints.md} - 1px)) and (max-width: calc(${breakpoints.lg} - 1px)) {
      width:100%!important;
    }
  }

  .block-lg {
    @media (min-width: ${breakpoints.lg}) {
      width:100%!important;
    }
  }

  .italic {
    font-style: italic;
  }
`
