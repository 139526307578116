import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

import * as requests from '~/views/Notices/services/requests'
import { setNotice } from '~/views/Notices/store/actions'

export default function getNotice(resolve, redirect, { match, history }) {
  return () => {
    const user = useShallowEqualSelector((state) => state.profile.user)
    const dispatch = useDispatch()

    useEffect(() => {
      async function findNotice() {
        if (user && user.id) {
          const notice = await requests.getNotice(match.params.id)
          if (!notice) {
            history.push('/home/meus-editais')
          }
          dispatch(setNotice(notice))
        }
      }

      findNotice()

      return () => dispatch(setNotice({}))
    }, [dispatch, user])

    return resolve()
  }
}
