import types from './types'

export function loadNoticeSubscriptionFormQuestionnaire(noticeId) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.LOAD_NOTICE_QUESTIONNAIRE,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/subscription-form`,
            method: 'GET',
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function updateNoticeSubscriptionFormQuestionnaire(
  noticeId,
  questionnaire
) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.UPDATE_NOTICE_QUESTIONNAIRE,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/subscription-form`,
            method: 'PUT',
            data: questionnaire,
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function partialUpdateNoticeSubscriptionFormQuestionnaire(
  noticeId,
  questionnaire
) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.UPDATE_NOTICE_QUESTIONNAIRE,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/subscription-form`,
            method: 'PATCH',
            data: questionnaire,
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function setNoticeSubscriptionFormQuestionnaire(questionnaire) {
  return { type: types.SET_NOTICE_QUESTIONNAIRE, questionnaire }
}

export function clearState() {
  return { type: types.CLEAR_STATE }
}
