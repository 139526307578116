import React from 'react'
import {ArrowDownIcon, ArrowUpIcon} from '~/styles/Icons'

import {SortArrowStyle} from '../styles'

function SortIcon({ hasSortBy, column }) {
  return (
    <span>
      {hasSortBy &&
        !column.hideSortIcon &&
        (column.isSorted ? (
          column.isSortedDesc ? (
            <SortArrowStyle src={ArrowDownIcon} alt="" aria-hidden={true} />
          ) : (
            <SortArrowStyle src={ArrowUpIcon} alt="" aria-hidden={true} />
          )
        ) : null )}
    </span>
  )
}

export default SortIcon
