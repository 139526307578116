import React from 'react'

import { ModalFilters } from '~/components'

import Notices from './Notices';
import NoticeStatus from './NoticeStatus';
import TypeNotice from './TypeNotice';

function ModalFiltersSubs({ isOpen, toggle, history }) {
  return (
    <ModalFilters
      isOpen={isOpen}
      toggle={toggle}
      title={'Filtrar Relatórios'}
      history={history}
    >
      <ModalFilters.Filter
        title={'Editais'}
        paramName={'notices'}
      >
        {({ param, setParam }) => (
          <Notices param={param} setParam={setParam} />
        )}
      </ModalFilters.Filter>
      <ModalFilters.Filter
        title={'Status do Edital'}
        paramName={'status'}
      >
        {({ param, setParam }) => (
          <NoticeStatus param={param} setParam={setParam} />
        )}
      </ModalFilters.Filter>
      <ModalFilters.Filter
        title={'Tipo do Edital'}
        paramName={'target'}
      >
        {({ param, setParam }) => (
          <TypeNotice param={param} setParam={setParam} />
        )}
      </ModalFilters.Filter>

    </ModalFilters>
  )
}

export default ModalFiltersSubs
