import styled from 'styled-components'


import { ArrowDownIcon } from '~/styles/Icons'
import { Link as LinkComponent } from 'react-router-dom'

export const DropdownStyle = styled.div`
  position: relative;
  margin-left: 14px;
  width: 201px;

  @media (max-width: 1022px) {
    width: 180px;
  }

  @media (max-width: 510px) {
    width: auto;
  }
`

export const MainButton = styled.button`
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 29px 5px 10px;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  height: 50px;

  background-image: url(${ArrowDownIcon});
  background-repeat: no-repeat;
  background-position: right 0 center;
  background-size: 24px 24px;

  > img {
    margin-left: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  > span {
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 20px;
    max-height: 40px;
    width: 112px;
    margin: auto 0;
  }

  @media (max-width: 798px) {
    span {
      display: none;
    }
  }

  @media (max-width: 1022px) {
    width: 180px;
  }

  @media (max-width: 510px) {
    width: auto;
  }
`

export const List = styled.div`
  display: none;
  /* display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')}; */
  position: absolute;
  flex-shrink: 0;
  right: 0;
  bottom: 0;
  top: 64px;
  z-index: 2;
  flex-direction: column;
  width: 200px;
  height: fit-content;
  border: 4px solid var(--color-light);
  border-radius: 10px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  background: var(--color-white);

  &.opened {
    display: flex;
  }

  > span {
    flex-shrink: 0;
    display: flex;
    height: 4px;
    background: var(--color-yellow);
    width: 160px;
    margin: 0;
    border: 0;
    margin: -4px 16px 15px 16px;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    width: 100%;

    :last-child {
      border-top: 2px solid var(--color-light);
      margin-top: 15px;
    }

    &:hover,
    &:focus {
      background: var(--color-yellow);
    }
  }
`

export const Link = styled(LinkComponent)`
  display: flex;
  color: var(--color-dark);
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  padding: 5px 16px;

  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    color: var(--color-dark);
    text-decoration: none;
  }

  img {
    height: 30px;
    width: 30px;
  }
`

export const LinkExternal = styled.a`
  display: flex;
  color: var(--color-dark);
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  padding: 5px 16px;

  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    color: var(--color-dark);
    text-decoration: none;
  }

  img {
    height: 30px;
    width: 30px;
  }
`

export const LinkLogout = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 11px 8px 16px;

  color: var(--color-dark);
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;

  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    color: var(--color-dark);
    text-decoration: none;
  }

  img {
    height: 30px;
    width: 30px;
  }
`
