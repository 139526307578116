import React from 'react'
import PropTypes from 'prop-types'

import { Content } from './styles'
import classNames from 'classnames'

function Inline(props) {
  return <Content {...props} className={classNames(props.className, 'inline')} />
}

Inline.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  right: PropTypes.number,
  bottom: PropTypes.number,
  mobileCenter: PropTypes.bool,
  mobileJustifyCenter: PropTypes.bool,
  noWrap: PropTypes.bool,
  verticalMiddle: PropTypes.bool,
  center: PropTypes.bool,
  justify: PropTypes.bool,
}

export default Inline
