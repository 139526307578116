import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CollapsibleStyle } from './styles'

function Collapsible(props) {
  return (
    <CollapsibleStyle
      trigger={props.trigger}
      triggerStyle={props.triggerStyle}
      classParentString="Collapsible"
      onOpen={props.onOpen}
      onTriggerOpening={props.onTriggerOpening}
      onClose={props.onClose}
      onTriggerClosing={props.onTriggerClosing}
      open={props.open}
      className={classnames(props.className)}
      {...props}
    >
      {props.children}
    </CollapsibleStyle>
  )
}

Collapsible.propTypes = {
  className: PropTypes.string,
  transitionTime: PropTypes.number,
  transitionCloseTime: PropTypes.number,
  triggerTagName: PropTypes.string,
  easing: PropTypes.string,
  open: PropTypes.bool,
  containerElementProps: PropTypes.object,
  classParentString: PropTypes.string,
  openedClassName: PropTypes.string,
  triggerStyle: PropTypes.object,
  triggerClassName: PropTypes.string,
  triggerOpenedClassName: PropTypes.string,
  triggerElementProps: PropTypes.object,
  contentOuterClassName: PropTypes.string,
  contentInnerClassName: PropTypes.string,
  accordionPosition: PropTypes.string,
  handleTriggerClick: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOpening: PropTypes.func,
  onClosing: PropTypes.func,
  onTriggerOpening: PropTypes.func,
  onTriggerClosing: PropTypes.func,
  trigger: PropTypes.element,
  triggerWhenOpen: PropTypes.element,
  triggerDisabled: PropTypes.bool,
  lazyRender: PropTypes.bool,
  overflowWhenOpen: PropTypes.string,
  contentHiddenWhenClosed: PropTypes.bool,
  triggerSibling: PropTypes.element,
  tabIndex: PropTypes.number,
  contentContainerTagName: PropTypes.string,
}

export default Collapsible
