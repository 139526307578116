import React from 'react'
import getNotice from '../Notices/middlewares/getNotice'
import getNoticePublished from './middlewares/getNoticePublished'
import activeStep from './middlewares/activeStep'
import getSubscription from './middlewares/getSubscription'
import { STEPS } from './middlewares/constants'

const Dashboard = React.lazy(() => import('./subpages/Dashboard'))
const GeneralData = React.lazy(() => import('./subpages/GeneralData'))
const Subscriptions = React.lazy(() => import('./subpages/Subscriptions'))
const DocumentAnalysis = React.lazy(() => import('./subpages/DocumentAnalysis'))
const RiskAnalysis = React.lazy(() => import('./subpages/RiskAnalysis'))
const Evaluation = React.lazy(() => import('./subpages/Evaluation'))
const Formalization = React.lazy(() => import('./subpages/Formalization'))
const Transference = React.lazy(() => import('./subpages/Transference'))
const Selection = React.lazy(() => import('./subpages/Selection'))
const ViewEvaluation = React.lazy(() => import('./subpages/ViewEvaluation'))

const noticeManagementRoutes = [
  {
    path: '/editais/:id/gestao/dashboard',
    component: Dashboard,
    exact: true,
    middlewares: [getNotice, getNoticePublished],
  },
  {
    path: '/editais/:id/gestao/dados-gerais',
    component: GeneralData,
    exact: true,
    middlewares: [getNotice, getNoticePublished],
  },
  {
    path: '/editais/:id/gestao/etapas/inscricoes',
    component: Subscriptions,
    exact: true,
    middlewares: [getNotice, getNoticePublished],
  },
  {
    path: '/editais/:id/gestao/etapas/analise-de-risco',
    component: RiskAnalysis,
    exact: true,
    middlewares: [
      getNotice,
      getNoticePublished,
      activeStep(STEPS.RISK_ANALYSIS),
    ],
  },
  {
    path: '/editais/:id/gestao/etapas/analise-documental',
    component: DocumentAnalysis,
    exact: true,
    middlewares: [
      getNotice,
      getNoticePublished,
      activeStep(STEPS.DOCUMENT_ANALYSIS),
    ],
  },
  {
    path: '/editais/:id/gestao/etapas/avaliacao',
    component: Evaluation,
    exact: true,
    middlewares: [
      getNotice,
      getNoticePublished,
      activeStep(STEPS.REVIEW_CRITERIA),
    ],
  },
  {
    path: '/editais/:id/gestao/etapas/selecao',
    component: Selection,
    exact: true,
    middlewares: [getNotice, getNoticePublished, activeStep(STEPS.SELECTION)],
  },
  {
    path: '/editais/:id/gestao/etapas/formalizacao',
    component: Formalization,
    exact: true,
    middlewares: [
      getNotice,
      getNoticePublished,
      activeStep(STEPS.FORMALIZATION),
    ],
  },
  {
    path: '/editais/:id/gestao/etapas/repasse',
    component: Transference,
    exact: true,
    middlewares: [
      getNotice,
      getNoticePublished,
      activeStep(STEPS.TRANSFERENCE),
    ],
  },
  {
    path:
      '/editais/:id/gestao/ficha-de-inscricao/:subscriptionId/avaliacao/:evaluatorId',
    component: ViewEvaluation,
    exact: true,
    middlewares: [getNotice, getNoticePublished, getSubscription],
  },
]

export default noticeManagementRoutes
