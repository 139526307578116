import styled from 'styled-components'

import breakpoints from '~/utils/breakpoints'

import { Button, Input } from '~/components'
import { SearchIcon } from '~/styles/Icons'

export const ButtonStyle = styled(Button)`
  @media (max-width: calc(${breakpoints.sm} - 1px)) {
    margin-right: auto !important;
    margin-left: auto;
  }
  & > button {
    max-height: 40px;
    & > img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }

`

export const InputStyle = styled(Input)`
  height: 50px;
  padding-right: 50px;

  background: url(${SearchIcon});
  background-size: 36px 33px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center right 12px;
`
