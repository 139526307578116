import styled from 'styled-components'


export const TableWrap = styled.div`
  display: block;
  max-width: 100%;
  overflow-x: auto;
`

export const TableStyle = styled.table`
  width: 100%;
`

export const THead = styled.thead``

export const TBody = styled.tbody``

export const THeadRow = styled.tr``

export const THeader = styled.th`
  background: var(--color-white);
  padding-right: 20px;
  padding-bottom: 10px;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-dark);
  text-align: ${({ align }) => (align ? align : 'left')};
  border-bottom: 2px solid var(--color-yellow);
`

export const TData = styled.td`
  height: 50px;
  padding: 16px 20px 16px 0;
  vertical-align: middle;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-darkGray);
  text-align: ${({ align }) => (align ? align : 'left')};
`

export const TBodyRow = styled.tr`
  &:first-child {
    ${TData} {
      padding-top: 20px;
    }
  }
`

export const PaginationStyle = styled.div`
  padding: 20px 0;
`

export const SortArrowStyle = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 5px;
`

export const TDLoading = styled.td`
  height: 50px;
  padding: 20px 0;
  vertical-align: middle;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-darkGray);
`
