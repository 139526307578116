import React from 'react'
import colors from '~/utils/colors'


import { Button, Inline, Modal, T3 } from '~/components'
import { CheckIcon, CheckLightIcon } from '~/styles/Icons'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'
import baseConfig from '~/utils/baseConfig'

function ModalIncompleteProfile() {
  const isOpen = useShallowEqualSelector(
    (state) => state.modalIncompleteProfile.isOpen
  )
  const setIsOpen = () => {
    window.location = `${baseConfig.url}/api/profile/edit`
  }
  return (
    <Modal
      id="modalIncompleteProfile"
      md
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
    >
      <Modal.Header type="normal" title={'Aviso!'} />

      <Modal.Body>
        <T3 color={colors.darkGray}>{'É necessário completar/atualizar seu cadastro'}</T3>
      </Modal.Body>

      <Modal.Footer>
        <Inline right={10}>
          <Button
            aria-label="Concluir"
            className="mb-3"
            onClick={() => setIsOpen(!isOpen)}
            icon={CheckLightIcon}
            iconHover={CheckIcon}
          >
            Continuar
          </Button>
        </Inline>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalIncompleteProfile
