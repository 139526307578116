import React, { useCallback, useEffect, useMemo, useState } from 'react'
import store from '~/boot/store'
import axios from '~/boot/client'

import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'
import useModal from '~/hooks/useModal'
import { onKeyPress } from '~/utils/onKeyPress'
import useSortBy from '~/utils/useSortBy'

import {
  FilterIcon,
  FilterLightIcon,
  DownloadIcon,
  DownloadLightIcon,
} from '~/styles/Icons'

import {
  Container,
  Row,
  Col,
  Box,
  Filter,
  Table,
  Inline,
  Button,
} from '~/components'

import ModalFilter from './components/ModalFilter'
import ModalFiltersSubs from './components/ModalFiltersSubs'
import FiltersSubs from './components/FiltersSubs'
import useCreateTableColumns from './services/useCreateTableColumns'
import { searchReports } from './store/actions'
import queryString from 'query-string'
import ModalInfo from './components/ModalInfo'

import { InputStyle } from './styles';

function loadReports(params) {
  store.dispatch(searchReports(params))
}

function Reports({ history }) {
  const defaultGenerateReportMessage =
  'Dentro de alguns instantes será enviado um link para o endereço de e-mail cadastrado no seu perfil. Clique nele para finalizar a exportação do Excel'
  const [generateReportMessage, setGenerateReportMessage] = useState(defaultGenerateReportMessage);
  const [infoGenerateReportOpen, toggleGenerateReportInfo] = useModal(false)
  const [modalSearchIsOpen, toggleModalSearch] = useModal()
  const [filtersOpen, toggleFilters] = useModal(false)
  const columns = useCreateTableColumns()
  const { reports, pageCount, resultsCount } = useShallowEqualSelector(
    (state) => state.reports
  )

  const [sortBy, setSortBy] = useSortBy()

  const params = useMemo(
    () => (history.location.search ? history.location.search.substring(1) : ''),
    [history]
  )

  const {pageIndex} = useMemo(
    () => (queryString.parse(history.location.search)),
    [history]
  )

  const [reportParam, setReportParam] = useState(params ? `?${params}` : '')

  const fetchData = useCallback(
    ({ sortBy }) => {
      setSortBy(sortBy)
    },
    [setSortBy])

  useEffect(() => {
    loadReports(params)
  }, [params])

  const noticeEvaluation = useShallowEqualSelector((state) => state.notice.notice.evaluation)

  const generateReport = useCallback(
    async () => {
      toggleGenerateReportInfo(true)
      try {
        await axios.get(`/api/participant-and-subscription/report/download-report${reportParam}`)
        setGenerateReportMessage(defaultGenerateReportMessage)
      } catch({ response }) {
        setGenerateReportMessage(response.data.message)
      }
    },
    [toggleGenerateReportInfo, setGenerateReportMessage, reportParam, defaultGenerateReportMessage]
  )

  return (
    <>
      <Container className="mb-5">
        <Box className="mt-5 pt-4 pb-4 px-5">
          <Row>
            <Col md={16} xs={24} className="mt-1 pt-1">
              <Filter history={history} paramName={'term'}>
                {({ param, setParam, search }) => {
                  return (
                    <InputStyle
                      value={param ? param : ''}
                      className='input-search'
                      placeholder="Pesquise por nome ou CNPJ da organização"
                      aria-label="Pesquise por nome ou CNPJ da organização"
                      onChange={(e) => {
                        setParam(e.target.value)
                        setReportParam(
                          e.target.value ? `?term=${e.target.value}` : ''
                        )
                      }}
                      onKeyDown={(e) => onKeyPress(e, 13, () => search())}
                    />
                  )
                }}
              </Filter>
            </Col>
            <Col md={8} xs={24} className="mt-1 pt-1 justify-row-right">
              <Inline>
                <Button
                  icon={DownloadLightIcon}
                  iconHover={DownloadIcon}
                  aria-label="Exportar Relatório"
                  size={2}
                  style={{ marginRight: '10px' }}
                  onClick={generateReport}
                >
                  Exportar Relatório
                </Button>
                <Button
                  light
                  icon={FilterIcon}
                  iconHover={FilterLightIcon}
                  aria-label="Filtrar"
                  size={2}
                  onClick={toggleFilters}
                >
                  Filtrar
                </Button>
              </Inline>
            </Col>
          </Row>

          <FiltersSubs
            className="mt-4"
            history={history}
            resultsCount={resultsCount}
          />

          <Row className="mt-4">
            <Col xs={24}>
              <Table
                hasSortBy
                hasPagination
                sortBy={sortBy}
                searchUrl={true}
                pageIndex={pageIndex ? Number(pageIndex) : 0}
                pageCount={pageCount}
                columns={columns}
                data={reports}
                // onClickRow={onClickRow}
                fetchData={fetchData}
              />
            </Col>
          </Row>
        </Box>
      </Container>

      <ModalFiltersSubs
        isOpen={filtersOpen}
        toggle={toggleFilters}
        history={history}
        hasGroups={noticeEvaluation?.hasGroupEvaluationCriteria}
      />
      <ModalFilter isOpen={modalSearchIsOpen} toggle={toggleModalSearch} />

      <ModalInfo
        isOpen={infoGenerateReportOpen}
        toggle={toggleGenerateReportInfo}
        message={generateReportMessage}
      />
    </>
  )
}

export default Reports
