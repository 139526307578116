import { handleActions, combineActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  pendingUpdatesCount: 0,
  groups: [],
  hasGroupEvaluationCriteria: false,
  needsPriorOpinion: false,
  answers: [],
  files: [],
  errors: [],
  evaluationId: null,
  subscriptionId: null,
  evaluatorId: null,
  finished: true,
  status: 'FINISHED'
}

const beginLoading = combineActions(
  types.LOAD_NOTICE_EVALUATION_FORM,
  types.LOAD_NOTICE_EVALUATION_ANSWERS,
  types.UPDATE_NOTICE_EVALUATION_ANSWERS,
  types.FINISH_NOTICE_EVALUATION_ANSWERS
)

const stopLoading = combineActions(
  types.LOAD_NOTICE_EVALUATION_FORM_SUCCESS,
  types.LOAD_NOTICE_EVALUATION_FORM_FAIL,
  types.LOAD_NOTICE_EVALUATION_ANSWERS_SUCCESS,
  types.LOAD_NOTICE_EVALUATION_ANSWERS_FAIL,
  types.UPDATE_NOTICE_EVALUATION_ANSWERS_SUCCESS,
  types.UPDATE_NOTICE_EVALUATION_ANSWERS_FAIL,
  types.FINISH_NOTICE_EVALUATION_ANSWERS_SUCCESS,
  types.FINISH_NOTICE_EVALUATION_ANSWERS_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_NOTICE_EVALUATION_FORM_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      groups: data?.groups ?? [],
      hasGroupEvaluationCriteria: data?.hasGroupEvaluationCriteria ?? false,
      needsPriorOpinion: data?.needsPriorOpinion ?? false,
      finished: data?.finished,
      isLoading: false,
    }),
    [types.LOAD_NOTICE_EVALUATION_ANSWERS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => {
      return {
        ...state,
        evaluationId: data?.id,
        subscriptionId: data?.usersSubscriptionsId,
        evaluatorId: data?.evaluatorId,
        answers: data?.answers ?? [],
        files: data?.files ?? [],
        errors: data?.errors ?? [],
        finished: data?.finished,
        status: data?.status,
        finishDate: data?.finishDate,
        isLoading: false,
      }
    },
    [types.UPDATE_NOTICE_EVALUATION_ANSWERS]: (state) => {
      return {
        ...state,
        pendingUpdatesCount: state.pendingUpdatesCount + 1,
      }
    },
    [types.UPDATE_NOTICE_EVALUATION_ANSWERS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => {
      const updatedPendingUpdatesCount =  state.pendingUpdatesCount - 1

      return {
        ...state,
        evaluationId: data?.id,
        subscriptionId: data?.usersSubscriptionsId,
        evaluatorId: data?.evaluatorId,
        answers: updatedPendingUpdatesCount > 0 ? state.answers : data?.answers ?? [],
        files: data?.files ?? [],
        errors: data?.errors ?? [],
        finished: data?.finished,
        finishDate: data?.finishDate,
        isLoading: false,
        pendingUpdatesCount: updatedPendingUpdatesCount,
      }
    },
    [types.FINISH_NOTICE_EVALUATION_ANSWERS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      evaluationId: data?.id,
      subscriptionId: data?.usersSubscriptionsId,
      evaluatorId: data?.evaluatorId,
      answers: data?.answers ?? [],
      files: data?.files ?? [],
      errors: data?.errors ?? [],
      finished: data?.finished,
      finishDate: data?.finishDate,
      isLoading: false,
      status: data?.status,
    }),
    [types.SET_NOTICE_EVALUATION_ANSWERS]: (state, { answers }) => ({
      ...state,
      answers,
    }),
    [types.SET_NOTICE_EVALUATION_FILES]: (state, { files }) => ({
      ...state,
      files,
    }),
    [types.CLEAR_STATE]: () => {
      return { ...INITIAL_STATE }
    },
  },
  INITIAL_STATE
)

export default reducer
