import { combineActions, handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  notice: {},
  notices: [],
  noticesSearchByName: [],
  pageSize: 10,
  pageIndex: 1,
  pageCount: 1,
  isLoading: true,
}

const beginLoading = combineActions(
  types.LOAD_NOTICES,
  types.LOAD_NOTICE,
  types.STORE_NOTICE,
  types.DELETE_NOTICE,
  types.GET_BRAZIL_REGIONS,
  types.GET_PROCESS_STEPS,
)

const stopLoading = combineActions(
  types.LOAD_NOTICES_SUCCESS,
  types.LOAD_NOTICES_FAIL,
  types.LOAD_NOTICE_SUCCESS,
  types.LOAD_NOTICE_FAIL,
  types.STORE_NOTICE_SUCCESS,
  types.STORE_NOTICE_FAIL,
  types.DELETE_NOTICE_SUCCESS,
  types.DELETE_NOTICE_FAIL,
  types.GET_BRAZIL_REGIONS_SUCCESS,
  types.GET_BRAZIL_REGIONS_FAIL,
  types.GET_PROCESS_STEPS_SUCCESS,
  types.GET_PROCESS_STEPS_FAIL,
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_NOTICES_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: result }
        },
      }
    ) => ({
      ...state,
      notices: result.notices,
      pageCount: result.pageCount,
      filterCount: result.filterCount
    }),
    [types.LOAD_NOTICE_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      notice: data,
    }),
    [types.SEARCH_NOTICES_BY_NAME_PUBLISH_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: noticesSearchByName },
        },
      }
    ) => ({
      ...state,
      noticesSearchByName,
    }),
    [types.SET_NOTICES]: (state, { notices }) => ({
      ...state,
      notices,
    }),
    [types.SET_NOTICE]: (state, { notice }) => ({
      ...state,
      notice,
    }),
    [types.STORE_NOTICE_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: notice },
        },
      }
    ) => ({
      ...state,
      notice,
    }),
    [types.GET_BRAZIL_REGIONS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: brazilRegions },
        },
      }
    ) => ({
      ...state,
      brazilRegions,
    }),
    [types.GET_PROCESS_STEPS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: processSteps },
        },
      }
    ) => ({
      ...state,
      processSteps,
    }),
    [types.DELETE_NOTICE_SUCCESS]: (state) => ({
      ...state,
      notice: {},
    }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
