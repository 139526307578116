import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonTag } from '~/components'

function TypeNotices({ param, setParam, onRemove, setParamCount }) {
  const [selecteds, setSelecteds] = useState([])

  const personTypes = [
    { id: 'PF', label: 'Pessoa Física' },
    { id: 'PJ', label: 'Pessoa Jurídica' },
  ]

  useEffect(() => {
    if (param) {
      if (!Array.isArray(param)) {
        const select = param.split(',')
        setParamCount(select.length)
        setSelecteds(select)
        setParam(select)
        return
      }

      setParamCount(param.length)
      setSelecteds(param)
      return
    }

    setSelecteds([])
    return () => setParamCount(0)
  }, [param, setParam, setParamCount])

  const changeSelected = useCallback(
    (value) => {
      const selected = !!selecteds.find(selected => selected === value)
      if (selected) {
        return selecteds.filter((item) => item !== value)
      }
    },
    [selecteds]
  )

  const data = useMemo(
    () => personTypes
        ? personTypes.filter((personType) =>
            selecteds.find((selected) => selected === personType.id))
        : [],
    [personTypes, selecteds]
  )

  return (
    <>
      {data.map((item, key) =>
        <ButtonTag
          key={key}
          aria-label={item.label}
          onClick={() => onRemove(null, changeSelected(item.id))}
        >
          {item.label}
        </ButtonTag>
      )}
    </>
  )
}

export default memo(TypeNotices)
