const colors = {
  black: '#000000',
  white: '#FFFFFF',
  dark: '#2A2A2A',
  darkGray: '#666666',
  gray: '#999999',
  lightGray: '#D8D8D8',
  yellow: '#2A2A2A',
  light: '#808080',
  blue: '#6B95BF',
  red: '#B53A3A',
  green: '#63A142',
  orange: '#E68E00',
  transparent: 'transparent',
  accessibilityLink: '#fcc419',
  accessibilityGrey: '#808080',
  percentBarColor1: '#666569',
  percentBarColor2: '#87868B',
  percentBarColor3: '#A8A6AD',
  percentBarColor4: '#C9C7CF',
  verticalLineBlack: '#C9C7CF',
  verticalLineGray: '#999999',
  percentBarBg: '#C9C7CF',
  percentBarFg: '#FFFFFF',
  percentBarComplete: '#FFFFFF',
}

export default colors
