import React from 'react'
import { Provider } from 'react-redux'

import store from './boot/store'

import Layout from './layout/Layout'
import { CookiesProvider } from 'react-cookie'

function App() {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <Layout />
      </CookiesProvider>
    </Provider>
  )
}

export default App
