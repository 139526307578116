import React from 'react'
import PropTypes from 'prop-types'

import { RequiredStyle } from './styles'

function Required(props) {
  return props.error ? (
    <RequiredStyle {...props} className='required'>
      {props.message || 'PREENCHER CAMPO OBRIGATÓRIO'}
    </RequiredStyle>
  ) : null
}

Required.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  error: PropTypes.bool,
  inline: PropTypes.bool,
  message: PropTypes.string
}
Required.defaultProps = {
  error: false,
  inline: true,
}

export default Required
