import React from 'react'

import { Redirect, Router, Switch } from 'react-router-dom'

import history from '~/utils/history'

import GlobalStyles from '~/styles/GlobalStyles'
import GlobalClasses from '~/styles/GlobalClasses'
import GlobalAccessibilityStyles from '~/styles/GlobalAccessibilityStyles'
import GlobalFonts from '~/styles/GlobalFonts'

import Header from '~/layout/Header'
import Footer from '~/layout/Footer'
import Main from '~/layout/Main'
import Forbidden from '~/layout/Forbidden'
import Route from '~/layout/Route'
import ModalSessionAlert from '~/layout/ModalSessionAlert'

import routes from '~/routes'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

import { getOccupationUserRoute } from './services/getOccupationUserRoute'
import ModalIncompleteProfile from '../ModalIncompleteProfile'

const profileInitialPage = {
  ADMIN: '/home/meus-editais',
  MANAGER: '/home/meus-editais',
  CONSULTANT: '/home/meus-editais',
  EVALUATOR: '/home/minhas-avaliacoes',
  PARTICIPANT: '/home/minhas-inscricoes',
}

function Layout() {
  const user = useShallowEqualSelector(({ profile }) => profile.user)

  const occupationUserRoute = getOccupationUserRoute(user)

  return (
    <>
      <GlobalStyles />
      <GlobalAccessibilityStyles />
      <GlobalClasses />
      <GlobalFonts />
      <ModalIncompleteProfile />
      <Router history={history}>
        <Header />
        <Main id="conteudo">
          <Switch>
            {routes.map((route, key) => {
              return (
                <Route
                  key={key}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                  middlewares={route.middlewares}
                />
              )
            })}
            <Route path="/acesso-proibido" component={Forbidden} />
            <Redirect
              to={`${profileInitialPage[occupationUserRoute] || '/'}`}
            />
          </Switch>
        </Main>
        <Footer id="rodape" />
        <ModalSessionAlert />
      </Router>
    </>
  )
}

export default Layout
