export default function getCellProps(cell, id) {
  const defaultProps = cell.getCellProps([
    {
      ...(cell.column && cell.column),
      style: {
        color: cell.column.color,
        ...(cell.column.style && cell.column.style),
      },
    },
  ])

  return {
    headers: `${id ? id + '-' : ''}th-${cell.column.id}`,
    ...defaultProps,
    id: `${id ? id + '-' : ''}${cell.row.id}-td-${cell.column.id}`,
  }
}
