import React from 'react'
import PropTypes from 'prop-types'

import {
  FormCardStyle,
  ArrowButton,
  FormCardHeader,
  FormCardBody,
  Name,
  Title,
  FormCardLabel,
  FormCardValue,
  FormCardBy,
  FormCardRow,
  FormCardHr,
  Status,
  Bullets,
} from './styles'

function FormCard({
  to,
  status,
  color,
  name,
  title,
  startDate,
  finishDate,
  by,
  isOn,
  ...props
}) {
  return (
    <FormCardStyle
      to={to}
      title={title}
      aria-label={`Visualizar ${title}`}
      {...props}
    >
      <FormCardHeader>
        <Status isOn={isOn}>{status}</Status>
        <Name>{name}</Name>
        <Title>{title}</Title>
        <Bullets />
      </FormCardHeader>
      <FormCardHr />
      <FormCardBody>
        <FormCardRow>
          <div>
            <FormCardLabel>Criação</FormCardLabel>
            <FormCardValue>{startDate}</FormCardValue>
          </div>
          <div>
            <FormCardLabel>Última alteração</FormCardLabel>
            <FormCardValue>{finishDate}</FormCardValue>
          </div>
        </FormCardRow>
      </FormCardBody>
      <FormCardBy>
        <span>Por: </span>
        {by}
      </FormCardBy>
      <ArrowButton />
    </FormCardStyle>
  )
}

FormCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  to: PropTypes.string,
  status: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  startDate: PropTypes.string,
  finishDate: PropTypes.string,
  by: PropTypes.string,
  on: PropTypes.bool,
}

export default FormCard
