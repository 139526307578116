import types from './types'

export function loadProfileUser() {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.LOAD_USER,
        payload: {
          request: {
            url: `/api/profile`,
            method: 'GET',
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function clearState() {
  return { type: types.CLEAR_STATE }
}
