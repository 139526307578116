import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { difference } from 'ramda'

export default function useSortBy(extraParams = {}) {
  const history = useHistory()

  const params = useMemo(() => queryString.parse(history.location.search), [
    history,
  ])

  const sortBy = Array.isArray(params?.sortBy)
    ? params.sortBy
    : params?.sortBy
    ? JSON.parse(params.sortBy)
    : []

  const setParam = useCallback(
    (value) => {
      const diff = difference(value, sortBy)
      if (diff.length) {
        const newSearch = queryString.stringify(
          { ...params, ...extraParams, sortBy: JSON.stringify(value) },
          {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          }
        )

        history.push({
          search: newSearch,
        })
      } else if (params.sortBy && value.length === 0) {
        const newSearch = queryString.stringify(
          { ...params, ...extraParams, sortBy: null },
          {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          }
        )

        history.push({
          search: newSearch,
        })
      }
    },
    [params, sortBy, history, extraParams]
  )

  return [sortBy, setParam]
}
