export const OPEN_TEXT = 1
export const MULTIPLE_CHOICE = 2
export const MULTIPLE_OPTIONS = 3
export const VALUES_TABLE = 4
export const VALIDATED_FIELD = 5
export const FILE = 6

export const questionTypes = [
  { value: OPEN_TEXT, label: 'Texto Aberto' },
  { value: MULTIPLE_CHOICE, label: 'Múltipla escolha' },
  { value: MULTIPLE_OPTIONS, label: 'Múltiplas opções' },
  { value: VALUES_TABLE, label: 'Tabela de valores' },
  { value: VALIDATED_FIELD, label: 'Campo validado' },
  { value: FILE, label: 'Campo arquivo' },
]
