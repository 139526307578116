import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'


export const OptionsStyle = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding: 20px 0px;
  ${({ center }) => center && `justify-content: center;`}

  * + * {
    margin-left: 20px;
  }

  ${({ mobileCenter }) =>
    mobileCenter &&
    `
    @media (max-width: 798px) {
      justify-content: center;
      flex-direction: column;

      > * {
          width: fit-content!important;
          margin-left: auto!important;
          margin-right: auto!important;
          margin-top: 30px!important;
      }

      > *:first-child {
          margin-top: 0px!important;
      }


    }
    `}

  ${({ light }) =>
    light
      ? `
        background: var(--color-white);

        ${LinkStyle},
        ${ExternalLinkStyle},
        ${ButtonStyle} {
          color: var(--color-dark);
          &:active,
          &:visited {
            color: var(--color-white);
          }

          &:hover,
          &.hover,
          &:focus ,
          &.active {
            color: var(--color-yellow);
          }
        }
        `
      : `
        background: var(--color-dark);

        ${LinkStyle},
        ${ExternalLinkStyle},
        ${ButtonStyle} {
          color: var(--color-white);
          &:active,
          &:visited {
            color: var(--color-white);
          }

          &:hover,
          &.hover,
          &:focus ,
          &.active {
            color: var(--color-yellow);
          }
        }
        `}
`

const OptionStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Itau Display Regular';
  font-size: 14px;
  line-height: 18px;
  position: relative;

  img {
    width: 30px;
    height: 30px;

    ${({ right }) =>
      right === 'true' || right === true
        ? `margin-left: 4px;`
        : `margin-right: 4px;`}
  }

  &:hover,
  &:active,
  &:visited,
  &:link {
    text-decoration: none;
  }
`

export const LinkStyle = styled(Link)`
  ${OptionStyle}
`

export const ExternalLinkStyle = styled.a`
  ${OptionStyle}
`

export const ButtonStyle = styled.button`
  ${OptionStyle}
`
