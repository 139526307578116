import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD_NOTICE_MANAGEMENT_PROCESS_STEPS'),
    'SET_SUBSCRIPTION',
    'CLEAR_STATE',
  ],
  'NOTICE_MANAGEMENT'
)
