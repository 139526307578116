import styled from 'styled-components'

export const Content = styled.hr`
  height: ${({ height }) => height + 'px'};
  background: var(--color-${({ color }) => color}) !important;
  opacity: ${({ opacity }) => opacity};
  border-radius: ${({ radius }) => radius + 'px'};
  margin: 0;
  border: 0;
  width: 100%;
`
