import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import withClearCache from './ClearCache'

const ClearCacheComponent = withClearCache(App)

ReactDOM.render(
  // <React.StrictMode>
  <ClearCacheComponent />,
  // </React.StrictMode>,
  document.getElementById('root')
)
