import styled from 'styled-components'

import breakpoints from '~/utils/breakpoints'


import { ClockIcon } from '~/styles/Icons'

export const Container = styled.div`
  ${({ fullWidth }) =>
    fullWidth
      ? `
    @media (max-width: ${breakpoints.xs}) {
      width: 100%;

      & > input:first-child  {
        width: 100%;
      }
    }
  `
      : ''}
`

export const LabelStyle = styled.label`
  display: flex;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 5px;
`

export const InputStyle = styled.input`
  outline: 0;
  display: flex;
  font-family: 'Itau Text Regular';
  font-size: 16px;
  line-height: 21px;
  padding: 14px 30px 15px 30px;
  border: 2px solid var(--color-light);
  width: 134px;
  height: 60px;

  &:focus,
  &.active {
    border-color: var(--color-yellow);
  }

  background: url(${ClockIcon});
  background-size: 36px 33px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center left 12px;
  padding-left: 50px;

  ::placeholder {
    color: var(--color-gray);
  }
`

export const List = styled.div`
  z-index: 2;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  width: 134px;
  border: 2px solid var(--color-light);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-width: 0px;
  max-height: 194px;
  overflow-y: scroll;
  position: absolute;

  ::-webkit-scrollbar {
    width: 14px;
  }
  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 5px solid var(--color-light);
    -webkit-border-radius: 7px;
    background-color: var(--color-darkGray);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--color-white);
  }

  ul {
    list-style: none;
    margin: 0;
    background: var(--color-white);
    padding-top: 20px;
    padding-bottom: 28px;
  }
`

export const Button = styled.button`
  text-decoration: none;
  padding: 10px 20px 10px 20px;
  width: 100%;
  text-align: left;

  &:hover,
  &:focus,
  &:active {
    color: var(--color-dark);
    background: var(--color-light);
    padding-right: 54px;
    border-radius: 0 10px 10px 0;
    border-left: 0px;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    width: 95%;
  }
`
