import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

function Row({ innerRef, ...props }) {
  return (
    <div
      {...props}
      ref={innerRef}
      className={classnames('row', props.className)}
    />
  )
}

Row.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Row
