import { handleActions, combineActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  groups: [],
  hasGroupEvaluationCriteria: false,
  needsPriorOpinion: false,
  answers: [],
  files: [],
  evaluationId: null,
  subscriptionId: null,
  evaluatorId: null,
  finished: true,
  evaluator: {},
  participant: {},
}

const beginLoading = combineActions(
  types.LOAD_VIEW_NOTICE_EVALUATION_FORM,
  types.LOAD_VIEW_NOTICE_EVALUATION_ANSWERS
)

const stopLoading = combineActions(
  types.LOAD_VIEW_NOTICE_EVALUATION_FORM_SUCCESS,
  types.LOAD_VIEW_NOTICE_EVALUATION_FORM_FAIL,
  types.LOAD_VIEW_NOTICE_EVALUATION_ANSWERS_SUCCESS,
  types.LOAD_VIEW_NOTICE_EVALUATION_ANSWERS_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_VIEW_NOTICE_EVALUATION_FORM_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      groups: data?.groups ?? [],
      hasGroupEvaluationCriteria: data?.hasGroupEvaluationCriteria ?? false,
      needsPriorOpinion: data?.needsPriorOpinion ?? false,
      isLoading: false,
    }),
    [types.LOAD_VIEW_NOTICE_EVALUATION_ANSWERS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      evaluationId: data?.id,
      subscriptionId: data?.usersSubscriptionsId,
      evaluatorId: data?.evaluatorId,
      answers: data?.answers ?? [],
      files: data?.files ?? [],
      finished: data?.finished,
      finishDate: data?.finishDate,
      evaluator: data?.evaluator,
      participant: data?.participant,
      isLoading: false,
    }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
