import styled from 'styled-components'



import { Link } from 'react-router-dom'

import { ArrowRightIcon } from '~/styles/Icons'

export const CardStyle = styled(Link)`
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border-radius: 10px 10px 20px 10px;
  width: 270px;
  min-height: 350px;
  position: relative;
  padding-bottom: 37px;
  color: var(--color-dark);
  transition: transform 0.1s ease;

  box-shadow: 0 10px 20px 0 rgba(102, 102, 102, 0.05);

  &:hover,
  &:focus,
  &:active,
  &:link,
  &:visited {
    color: var(--color-dark);
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &.active {
    transform: translateY(-10px);
    transition: transform 0.1s ease;
    box-shadow: none;
  }
`

export const ArrowButton = styled.div`
  background: var(--color-yellow);
  background-image: url(${ArrowRightIcon});
  background-size: 24px 24px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 10px;
  right: 10px;
`

export const CardHeader = styled.div`
  background: var(--color-yellow);
  border-radius: 10px 10px 0 0;
  padding: 20px;
  width: 100%;
  height: 100px;
`

export const Status = styled.p`
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-darkGray);
`

export const Title = styled.p`
  font-family: 'Itau Display XBold';
  font-size: 16px;
  line-height: 21px;
  color: var(--color-dark);

  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 42px;
`

export const CardBody = styled.div`
  padding: 0 20px 0px 20px;
  width: 100%;
`

export const CardLabelStyle = styled.p`
  margin-top: 20px;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-dark);
`

export const CardValueStyle = styled.p`
  margin-top: 5px;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-darkGray);
`

export const CardByStyle = styled.p`
  margin-top: 5px;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-darkGray);

  span {
    color: var(--color-dark);
  }
`

export const CardRowStyle = styled.div`
  display: flex;
  flex-direction: row;
`

export const CardHrStyle = styled.hr`
  background: var(--color-light);
  border-radius: 10px;
  height: 2px;
  width: 100%;
  border: 0;
  margin: 5px 0 0 0;
`

export const CardProgressStyle = styled.div`
  display: block;
  background: var(--color-white);
  border-radius: 10px;
  height: 20px;
  width: 100%;
  border: 0;
  margin: 10px 0 0 0;
  border: 2px solid var(--color-light);
  position: relative;

  > div {
    display: block;
    background: var(--color-orange);
    border-radius: 10px;
    height: 20px;
    position: absolute;
    top: -2px;
    left: -2px;

    ${({ progress }) =>
      progress !== 0 &&
      `
      width: calc(${progress}% + 4px);
    `}
  }

  ${({ progress }) =>
    progress === 100 &&
    `
    border-color: var(--color-lightGray);
    div {
      background: var(--color-lightGray);
    }
  `}
`
