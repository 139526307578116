import React from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { BulletsIcon } from '~/styles/Icons'

import {
  NextButton,
  PageButton,
  PaginationStyle,
  PreviousButton,
  Bullets,
  PaginationWrapper,
} from './styles'

function Pagination({
  gotoPage,
  previousPage,
  canPreviousPage,
  nextPage,
  pageCount,
  canNextPage,
  pageIndex,
  searchUrl,
  extraParams = {},
}) {
  const history = useHistory()
  function checkPage(key) {
    const objSearch = queryString.parse(history.location.search)
    objSearch.pageIndex = key
    const newSearch = queryString.stringify(
      { ...objSearch, ...extraParams },
      {
        arrayFormat: 'comma',
        skipNull: true,
        skipEmptyString: true,
      }
    )

    history.push({
      search: newSearch,
    })
  }

  return (
    <PaginationWrapper>
      <PaginationStyle>
        <PreviousButton
          className='accessibility_invert'
          aria-label="Página anterior"
          onClick={() => {
            if (searchUrl) checkPage(pageIndex - 1)
            previousPage()
          }}
          disabled={!canPreviousPage}
        />
        {pageCount > 5 ? (
          <>
            {[...Array(pageCount)].map((_, key) => {
              if (pageIndex < 5 && key < 5) {
                return (
                  <PageButton
                    active={key === pageIndex}
                    key={key}
                    aria-label={`Ir para a página ${pageIndex + 1}`}
                    onClick={() => {
                      if (searchUrl) checkPage(key)
                      gotoPage(key)
                    }}
                    className={`pagination-button${key === pageIndex ? ' active' : ''}`}
                  >
                    {key + 1}
                  </PageButton>
                )
              } else if (
                pageIndex > 4 &&
                key >
                  pageIndex -
                    (pageIndex === pageCount - 2
                      ? 6
                      : pageIndex === pageCount - 1
                      ? 7
                      : 5) &&
                key <= pageIndex
              ) {
                return (
                  <PageButton
                    active={key === pageIndex}
                    key={key}
                    aria-label={`Ir para a página ${pageIndex + 1}`}
                    onClick={() => {
                      if (searchUrl) checkPage(key)
                      gotoPage(key)
                    }}
                    className={`pagination-button${key === pageIndex ? ' active' : ''}`}
                  >
                    {key + 1}
                  </PageButton>
                )
              } else {
                return null
              }
            })}

            {pageIndex < pageCount - 2 ? <Bullets className='accessibility_invert' width={28} height={28} src={BulletsIcon} /> : null}

            {pageIndex < pageCount - 1 ? (
              <PageButton
                active={pageCount - 1 === pageIndex}
                aria-label={`Ir para a página ${pageCount}`}
                onClick={() => {
                  if (searchUrl) checkPage(pageCount - 1)
                  gotoPage(pageCount - 1)
                }}
                className={`pagination-button${pageCount - 1 === pageIndex ? ' active' : ''}`}
              >
                {pageCount}
              </PageButton>
            ) : null}
          </>
        ) : (
          [...Array(pageCount)].map((a, key) => {
            return (
              <PageButton
                active={key === pageIndex}
                key={key}
                aria-label={`Ir para a página ${pageIndex + 1}`}
                onClick={() => {
                  if (searchUrl) checkPage(key)
                  gotoPage(key)
                }}
                className={`pagination-button${key === pageIndex ? ' active' : ''}`}
              >
                {key + 1}
              </PageButton>
            )
          })
        )}
        <NextButton
          aria-label="Próxima página"
          className='accessibility_invert'
          onClick={() => {
            if (searchUrl) checkPage(pageIndex + 1)
            nextPage()
          }}
          disabled={!canNextPage}
        />
      </PaginationStyle>
    </PaginationWrapper>
  )
}

export default Pagination
