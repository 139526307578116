import { useEffect } from 'react'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

import * as requests from '~/views/Notices/services/requests'

export default function hasSubscriptions(
  resolve,
  redirect,
  { match, history }
) {
  return () => {
    const user = useShallowEqualSelector((state) => state.profile.user)
    useEffect(() => {
      async function findSubscriptions() {
        const subscriptions = await requests.noticeSubscriptions(
          match.params.id
        )

        if (subscriptions && subscriptions.length) {
          if (user.profileType && user.profileType !== 'ADMIN') {
            return history.push('/home/meus-editais')
          }
        }
      }

      findSubscriptions()
    }, [user.profileType])

    return resolve()
  }
}
