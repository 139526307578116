import { client } from '~/boot/client'

export async function fetchSelectedNotices(selectedIds, setNoticesSelected) {
  if (selectedIds.length) {
    const response = await client.get(
      `/api/search-notices-by-name-or-id?ids=${selectedIds.join(',')}`
    )
    return setNoticesSelected(response.data.data)
  }
  return setNoticesSelected([])
}

export async function searchNotices(setNotices, search) {
  const response = await client.get(
    `/api/search-notices-by-name-or-id?term=${search}`
  )
  setNotices(response.data.data)
}
