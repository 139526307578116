import { combineActions, handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  activesEvaluations: null,
  pastEvaluations: null,
}

const beginLoading = combineActions(
  types.LOAD_EVALUATIONS,
  types.SEARCH_EVALUATIONS,
  types.SEARCH_PAST_EVALUATIONS
)

const stopLoading = combineActions(
  types.LOAD_EVALUATIONS_SUCCESS,
  types.LOAD_EVALUATIONS_FAIL,
  types.SEARCH_EVALUATIONS_SUCCESS,
  types.SEARCH_EVALUATIONS_FAIL,
  types.SEARCH_PAST_EVALUATIONS_SUCCESS,
  types.SEARCH_PAST_EVALUATIONS_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_EVALUATIONS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      activesEvaluations: data,
      isLoading: false,
    }),
    [types.SEARCH_PAST_EVALUATIONS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      pastEvaluations: data,
      isLoading: false,
    }),
    [types.SEARCH_EVALUATIONS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => {
      return {
        ...state,
        activesEvaluations: state?.activesEvaluations.map((info) => {
          if (info.noticeId !== data.noticeId) {
            return info
          }
          return {
            ...info,
            evaluations: data.evaluations,
            evaluationsPageCount: data.evaluationsPageCount,
          }
        }),
        isLoading: false,
      }
    },
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
