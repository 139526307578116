import React from 'react'
import PropTypes from 'prop-types'

import colors from '~/utils/colors'

import { BoxStyle } from './styles'
import classNames from 'classnames'

function Box(props) {
  return <BoxStyle {...props} className={classNames(props.className, 'box')} ref={props.innerRef} />
}

Box.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
}

Box.defaultProps = {
  color: colors.white,
}

export default Box
