import React from 'react'
import getNotice from './middlewares/getNotice'
import loadOrCreateEvaluationCriteriaForm from './ProcessSteps/subpages/EvaluationCriteriaForm/middlewares/loadOrCreateEvaluationCriteriaForm'
import hasSubscriptions from './middlewares/hasSubscriptions'
import isUserManagerOrConsultant from '~/views/Home/middlewares/isUserManagerOrConsultant'
import hasEvaluations from './middlewares/hasEvaluations'

const GeneralData = React.lazy(() => import('./GeneralData'))
const ProcessSteps = React.lazy(() => import('./ProcessSteps'))
const ProcessStepsRiskAnalysis = React.lazy(() =>
  import('./ProcessSteps/subpages/RiskAnalysis')
)
const ProcessStepsDocumentAnalysis = React.lazy(() =>
  import('./ProcessSteps/subpages/DocumentAnalysis')
)
const ProcessStepsCriteria = React.lazy(() =>
  import('./ProcessSteps/subpages/Criteria')
)
const ManagersAndConsultants = React.lazy(() =>
  import('./ManagersAndConsultants')
)
const SubscriptionForm = React.lazy(() => import('./SubscriptionForm'))
const SubscriptionFormIntroPage = React.lazy(() =>
  import('./SubscriptionForm/IntroPage')
)
const SubscriptionFormNoticeQuestionnaire = React.lazy(() =>
  import('./SubscriptionForm/NoticeQuestionnaire')
)
const EvaluationCriteriaForm = React.lazy(() =>
  import('./ProcessSteps/subpages/EvaluationCriteriaForm')
)
const Notices = React.lazy(() => import('./index'))

const noticesRoutes = [
  {
    path: '/editais',
    component: Notices,
    exact: true,
    middlewares: [isUserManagerOrConsultant],
  },
  {
    path: '/editais/:id/dados-gerais',
    component: GeneralData,
    exact: true,
    middlewares: [getNotice],
  },
  {
    path: '/editais/:id/etapas-do-processo',
    component: ProcessSteps,
    exact: true,
    middlewares: [getNotice],
  },
  {
    path: '/editais/:id/etapas-do-processo/analise-de-risco',
    component: ProcessStepsRiskAnalysis,
    exact: true,
    middlewares: [getNotice, hasSubscriptions],
  },
  {
    path: '/editais/:id/etapas-do-processo/analise-documental',
    component: ProcessStepsDocumentAnalysis,
    exact: true,
    middlewares: [getNotice, hasSubscriptions],
  },
  {
    path: '/editais/:id/etapas-do-processo/avaliacao-criterios',
    component: ProcessStepsCriteria,
    exact: true,
    middlewares: [getNotice, hasEvaluations],
  },
  {
    path: '/editais/:id/etapas-do-processo/avaliacao-criterios/formulario',
    component: EvaluationCriteriaForm,
    exact: true,
    middlewares: [
      getNotice,
      loadOrCreateEvaluationCriteriaForm,
      hasEvaluations,
    ],
  },
  {
    path: '/editais/:id/gestores-e-consultores',
    component: ManagersAndConsultants,
    exact: true,
    middlewares: [getNotice],
  },
  {
    path: '/editais/:id/formulario-de-inscricao',
    component: SubscriptionForm,
    exact: true,
    middlewares: [getNotice],
  },
  {
    path: '/editais/:id/formulario-de-inscricao/pagina-introdutoria',
    component: SubscriptionFormIntroPage,
    exact: true,
    middlewares: [getNotice, hasSubscriptions],
  },
  {
    path: '/editais/:id/formulario-de-inscricao/questionario-do-edital',
    component: SubscriptionFormNoticeQuestionnaire,
    exact: true,
    middlewares: [getNotice, hasSubscriptions],
  },
]

export default noticesRoutes
