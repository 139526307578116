import styled from 'styled-components'

import ErrorImage from '../../assets/images/error.png'
import breakpoints from '~/utils/breakpoints'

export const MenuStyle = styled.div`
  margin-top: -40px;
`

export const MenuContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  width: 100%;
  background: var(--color-dark);
  border-radius: 5px;
  min-height: 61px;
  padding: 0;
  position: relative;
`
export const ProfileStyle = styled.div`
  margin: 30px 0 0 0;
`

export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: row;
  ${({ flexEnd }) => flexEnd ? `
    justify-content: flex-end;
  ` : ''}
  margin-bottom: 30px;
  align-items: center;

  small {
    text-align: left;
    width: 100%;
    display: block;
  }
`

export const Avatar = styled.img`
  ${({ flexEnd }) => flexEnd ? `
    width: 50px;
    height: 50px;
    margin-left: 20px;
  ` : `
    width: 50px;
    height: 50px;
    margin-right: 20px;
  `}
  margin-top: 2px;
  ${({ hasBorderRadius }) => (hasBorderRadius ? 'border-radius: 25px' : '')}
`

export const WelcomeMessage = styled.p`
  display: flex;
  color: var(--color-white);
  font-family: 'Itau Text XBold';
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 5px;
`

export const ProfileName = styled.p`
  display: flex;
  color: var(--color-${({color}) => color});
  font-family: 'Itau Text Regular';
  font-size: 20px;
  line-height: 26px;
`

export const Message = styled.p`
  color: var(--color-white);
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 30px;
`

export const SidesStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /*  align-items: center; */
`

export const LeftStyle = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
  padding-bottom: 20px;
`

export const LeftContentStyle = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 798px) {
    width: 100%;
    justify-content: center;
    text-align: center;
    padding-right: 50px;
  }
`

export const RightStyle = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: calc(${breakpoints.md} - 1px)) {
    padding-right: 30px;
    padding-left: 30px;
  }
`

export const DivisorStyle = styled.div`
  display: flex;
  width: 2px;
  background: var(--color-black);

  height: 100%;
`

export const RequiredStyle = styled.div`
  height: 66px;
  width: 11px;
  border: 3px solid var(--color-black);
  border-radius: 25px;
  background-color: var(--color-orange);

  position: absolute;
  top: 17px;
  right: 47px;

  @media (max-width: ${breakpoints.lg}) {
    height: 20px;
    width: 11px;
    top: 10px;
    right: 10px;
  }
`

export const RequiredErrorStyle = styled.a`
  background-image: url(${ErrorImage});
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-position: center center;
  height: 40px;
  width: 40px;

  position: absolute;
  top: 31px;
  right: 22px;

  @media (max-width: ${breakpoints.lg}) {
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
    top: 10px;
    right: 10px;
  }
`
