import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  TabsMenuStyle,
  LinkStyle,
  LinkExpansiveStyle,
  LinkExpansiveItemStyle,
  TitleStyle,
  ExpandIcon,
  ExpandList,
} from './styles'
import classNames from 'classnames'

function TabsMenu(props) {
  return (
    <TabsMenuStyle {...props} className={classNames(props.className, 'tab-menu')}>
      {props.title && <TitleStyle className='title'>{props.title}</TitleStyle>}
      {props.children}
    </TabsMenuStyle>
  )
}

function Link(props) {
  return (
    <LinkStyle {...props} exact={props.exact} activeClassName="active">
      <span className='icon'>
        <img src={props.icon} alt="" aria-hidden />
      </span>
      <div style={{ maxWidth: '140px', fontFamily: 'inherit' }}>
        {props.children}
      </div>
    </LinkStyle>
  )
}

function LinkExpansive(props) {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <div className={classNames(props.className, 'link-expansive')} style={props.style}>
      <LinkExpansiveStyle
        isOpen={isOpen}
        isSubPage={props.isSubPage}
        activeClassName="active"
        className='tabMenuExpansive'
        aria-label={props['aria-label']}
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <span className='icon'>
          <img src={props.icon} alt="" aria-hidden />
        </span>
        <div style={{ maxWidth: '105px', fontFamily: 'inherit' }}>
          {props.title}
        </div>
        <ExpandIcon className={classNames('expand-icon', { isOpen: isOpen }, { isSubPage: props.isSubPage })} />
      </LinkExpansiveStyle>
      <ExpandList isOpen={isOpen} isSubPage={props.isSubPage}>
        {props.children}
      </ExpandList>
    </div>
  )
}

function LinkExpansiveItem(props) {
  return (
    <LinkExpansiveItemStyle {...props} activeClassName="active">
      {props.children}
      <span>{props.count}</span>
    </LinkExpansiveItemStyle>
  )
}

TabsMenu.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
}

Link.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  exact: PropTypes.bool,
}

Link.defaultProps = {
  exact: false,
}

LinkExpansive.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isSubPage: PropTypes.bool,
}

LinkExpansive.defaultProps = {
  exact: false,
  isOpen: true,
}

LinkExpansiveItem.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  count: PropTypes.number,
}

LinkExpansiveItem.defaultProps = {
  exact: false,
  count: 0,
}

TabsMenu.Link = Link
TabsMenu.LinkExpansive = LinkExpansive
TabsMenu.LinkExpansiveItem = LinkExpansiveItem

export default TabsMenu
