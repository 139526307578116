import React from 'react'
import requirements from './middlewares/requirements'
import hasSubscription from './middlewares/hasSubscription'
import getData from './middlewares/getData'

const SubscriptionPage = React.lazy(() => import('./subpages/SubscriptionPage'))
const ReviewSubscription = React.lazy(() =>
  import('./subpages/ReviewSubscription')
)
const ViewForm = React.lazy(() => import('./subpages/ViewForm'))

const subscriptionsRoutes = [
  {
    path: '/inscricao/:id/realizar-inscricao',
    component: SubscriptionPage,
    exact: true,
    middlewares: [requirements],
  },
  {
    path: '/inscricao/:id/realizar-inscricao/:order',
    component: SubscriptionPage,
    exact: true,
    middlewares: [requirements],
  },
  {
    path: '/inscricao/:id/visualizar-inscricao',
    component: ReviewSubscription,
    exact: true,
    middlewares: [hasSubscription],
  },
  {
    path: '/inscricao/:id/visualizar-inscricao/:order',
    component: ReviewSubscription,
    exact: true,
    middlewares: [hasSubscription],
  },
  {
    path: '/inscricao/:id/visualizar-formulario',
    component: ViewForm,
    exact: true,
    middlewares: [getData],
  },
]

export default subscriptionsRoutes
