import { async, types } from '~/utils/typeCreator'

export default types(
  [
    ...async('SEARCH_SUBSCRIPTIONS'),
    ...async('STATS'),
    'SET_SUBSCRIPTIONS_SELECTED',
    'CLEAR_STATE',
  ],
  'NOTICE_MANAGEMENT_RISK_ANALYSIS'
)
