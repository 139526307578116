import styled from 'styled-components'



export const InputGroupStyle = styled.div`
  position: relative;
  width: 100%;
`

export const InputStyle = styled.input`
  outline: 0;
  display: flex;
  font-family: 'Itau Text Regular';
  font-size: 16px;
  line-height: 21px;
  padding: 14px 20px 15px 20px;
  border: 2px solid var(--color-light);
  width: 100%;
  height: 50px;

  &:focus,
  &.active {
    border-color: var(--color-yellow);
  }
  &:disabled {
    background-color: rgba(243, 237, 223, 0.7);
  }

  ${({ error }) =>
    error &&
    `
    border-color: var(--color-red);
  `}

  ${({ iconLeft }) =>
    iconLeft &&
    `
    background: url(${iconLeft});
    background-size: 36px 33px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center left 12px;
    padding-left: 50px;
  `}

  ${({ iconRight }) =>
    iconRight &&
    `
    background: url(${iconRight});
    background-size: 36px 33px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center right 12px;
    padding-right: 50px;
  `}

  ::placeholder {
    color: var(--color-gray);
  }
`

export const LabelStyle = styled.label`
  display: flex;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 10px;
`

export const CaracterCountStyle = styled.small`
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-gray);
  position: absolute;
  right: 0;
  padding-top: 5px;
`
