import './custom-theme/bootstrap.min.css'
import { createGlobalStyle } from 'styled-components'
import colors from '~/utils/colors'
import accessibilityDarkColors from '~/utils/accessibility-dark-colors'
import accessibilityLightColors from '~/utils/accessibility-light-colors'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    --color-white: ${colors.white};
    --color-black: ${colors.black};
    --color-dark: ${colors.dark};
    --color-darkGray: ${colors.darkGray};
    --color-gray: ${colors.gray};
    --color-lightGray: ${colors.lightGray};
    --color-yellow: ${colors.yellow};
    --color-light: ${colors.light};
    --color-blue: ${colors.blue};
    --color-red: ${colors.red};
    --color-green: ${colors.green};
    --color-orange: ${colors.orange};
    --color-transparent: ${colors.transparent};
    --color-accessibilityLink: ${colors.accessibilityLink};
    --color-accessibilityGrey: ${colors.accessibilityGrey};
    --color-percentBarColor1: ${colors.percentBarColor1};
    --color-percentBarColor2: ${colors.percentBarColor2};
    --color-percentBarColor3: ${colors.percentBarColor3};
    --color-percentBarColor4: ${colors.percentBarColor4};
    --color-verticalLineBlack: ${colors.verticalLineBlack};
    --color-verticalLineGray: ${colors.verticalLineGray};
    --color-percentBarBg: ${colors.percentBarBg};
    --color-percentBarFg: ${colors.percentBarFg};
    --color-percentBarComplete: ${colors.percentBarComplete};

    body[data-theme="accessibility-dark"] {
      --color-white: ${accessibilityDarkColors.white};
      --color-black: ${accessibilityDarkColors.black};
      --color-dark: ${accessibilityDarkColors.dark};
      --color-darkGray: ${accessibilityDarkColors.darkGray};
      --color-gray: ${accessibilityDarkColors.gray};
      --color-lightGray: ${accessibilityDarkColors.lightGray};
      --color-yellow: ${accessibilityDarkColors.yellow};
      --color-light: ${accessibilityDarkColors.light};
      --color-blue: ${accessibilityDarkColors.blue};
      --color-red: ${accessibilityDarkColors.red};
      --color-green: ${accessibilityDarkColors.green};
      --color-orange: ${accessibilityDarkColors.orange};
      --color-accessibilityLink: ${accessibilityDarkColors.accessibilityLink};
      --color-accessibilityGrey: ${accessibilityDarkColors.accessibilityGrey};
      --color-percentBarColor1: ${accessibilityDarkColors.percentBarColor1};
      --color-percentBarColor2: ${accessibilityDarkColors.percentBarColor2};
      --color-percentBarColor3: ${accessibilityDarkColors.percentBarColor3};
      --color-percentBarColor4: ${accessibilityDarkColors.percentBarColor4};
      --color-verticalLineBlack: ${accessibilityDarkColors.verticalLineBlack};
      --color-verticalLineGray: ${accessibilityDarkColors.verticalLineGray};
      --color-percentBarBg: ${accessibilityDarkColors.percentBarBg};
      --color-percentBarFg: ${accessibilityDarkColors.percentBarFg};
      --color-percentBarComplete: ${accessibilityDarkColors.percentBarComplete};
    }

    body[data-theme="accessibility-light"] {
      --color-white: ${accessibilityLightColors.white};
      --color-black: ${accessibilityLightColors.black};
      --color-dark: ${accessibilityLightColors.dark};
      --color-darkGray: ${accessibilityLightColors.darkGray};
      --color-gray: ${accessibilityLightColors.gray};
      --color-lightGray: ${accessibilityLightColors.lightGray};
      --color-yellow: ${accessibilityLightColors.yellow};
      --color-light: ${accessibilityLightColors.light};
      --color-blue: ${accessibilityLightColors.blue};
      --color-red: ${accessibilityLightColors.red};
      --color-green: ${accessibilityLightColors.green};
      --color-orange: ${accessibilityLightColors.orange};
      --color-accessibilityLink: ${accessibilityLightColors.accessibilityLink};
      --color-accessibilityGrey: ${accessibilityLightColors.accessibilityGrey};
      --color-percentBarColor1: ${accessibilityLightColors.percentBarColor1};
      --color-percentBarColor2: ${accessibilityLightColors.percentBarColor2};
      --color-percentBarColor3: ${accessibilityLightColors.percentBarColor3};
      --color-percentBarColor4: ${accessibilityLightColors.percentBarColor4};
      --color-verticalLineBlack: ${accessibilityLightColors.verticalLineBlack};
      --color-verticalLineGray: ${accessibilityLightColors.verticalLineGray};
      --color-percentBarBg: ${accessibilityLightColors.percentBarBg};
      --color-percentBarFg: ${accessibilityLightColors.percentBarFg};
      --color-percentBarComplete: ${accessibilityLightColors.percentBarComplete};
    }
  }

  body {
    .MuiDialog-root {
      .MuiDialog-container {
        .MuiPickersBasePicker-container {
          .MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar.MuiToolbar-gutters {
            .MuiTypography-subtitle1 {
              font-size: 1.5rem;
              font-family: 'Itau Text Regular' !important;
            }
            .MuiTypography-h4 {
              font-size: 3rem !important;
              font-family: 'Itau Text Regular' !important;
            }
          }
          .MuiTypography-body1 {
            font-family: 'Itau Text Regular' !important;
            font-size: 1.5rem;
          }
          .MuiSvgIcon-root {
            font-size: 3rem;
          }
          .MuiPickersCalendarHeader-daysHeader {
            span {
              font-size: 1.125rem;
            }
          }
          .MuiPickersSlideTransition-transitionContainer {
            .MuiPickersCalendar-week {
              button {
                font-size: 1.5rem;
                span {
                  p {
                    font-size: 1rem;
                    font-weight: bold;
                  }
                }
              }
            }
          }
          .MuiButton-label {
            font-size: 20px
          }
        }
        .MuiDialogActions-root.MuiDialogActions-spacing {
          .MuiButton-root {
            .MuiButton-label {
              font-size: 1.5rem
            }
          }
        }

        .MuiPickersYearSelection-container {
          .MuiTypography-root.MuiPickersYear-root.MuiPickersYear-yearSelected.MuiTypography-h5.MuiTypography-colorPrimary {
            font-size: 1.850rem;
            font-weight: bold;
          }
          .MuiTypography-root.MuiPickersYear-root.MuiTypography-subtitle1 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  html, body, #root{
    max-height: 100vh;
    max-width: 100vw;

    height: 100%;
    width: 100%;

    min-height:100vh;

    display: flex;
    flex-direction: column;
    background: var(--color-light);
  }

  *, button, input{
    border: 0;
    background: none;
    font-family: 'Itau Text Regular';
    /* color:var(--color-dark); */
  }

  strong {
    font-family: 'Itau Text XBold';
    color:var(--color-black);
  }

  ::-webkit-scrollbar {
  -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-dark);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--color-light);
    border-radius: 5px;
  }

  .Collapsible__contentOuter {
    position: relative;
  }
`
