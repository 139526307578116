import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonTag } from '~/components'

import { fetchSelectedNotices } from '../../../services/requests'

function Notices({ param, setParam, onRemove, setParamCount }) {
  const [selecteds, setSelecteds] = useState([])
  const [notices, setNotices] = useState([])

  useEffect(() => {
    fetchSelectedNotices(selecteds, setNotices)
  }, [selecteds, setNotices])

  useEffect(() => {
    if (param) {
      if (!Array.isArray(param)) {
        const select = param.split(',')
        setParamCount(select.length)
        setSelecteds(select)
        setParam(select)
        return
      }

      setParamCount(param.length)
      setSelecteds(param)
      return
    }

    setSelecteds([])
    return () => setParamCount(0)
  }, [param, setParam, setParamCount])

  const changeSelected = useCallback(
    (value) => {
      const selected = !!selecteds.find(selected => Number(selected) === Number(value))
      if (selected) {
        return selecteds.filter((item) => Number(item) !== Number(value))
      }
    },
    [selecteds]
  )

  const data = useMemo(
    () => notices
        ? notices.filter((notice) =>
            selecteds.find((selected) => Number(selected) === Number(notice.id)))
        : [],
    [notices, selecteds]
  )

  return (
    <>
      {data.map((item, key) =>
        <ButtonTag
          key={key}
          aria-label={item.name}
          onClick={() => onRemove(null, changeSelected(item.id))}
        >
          {item.name}
        </ButtonTag>
      )}
    </>
  )
}

export default memo(Notices)
