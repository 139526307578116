import React from 'react'
import PropTypes from 'prop-types'
import colors from '~/utils/colors'

import fonts from '~/utils/fonts'

import { LabelStyle } from './styles'

function Label(props) {
  return <LabelStyle {...props} />
}

Label.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  htmlFor: PropTypes.string,
  font: PropTypes.string,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.number,
  color: PropTypes.string,
}

Label.defaultProps = {
  font: fonts.textRegular,
  fontSize: 14,
  lineHeight: 18,
  color: colors.dark,
}

export default Label
