import setForm from './setForm'
import updateForm from './updateForm'

export function treatInitialState(state) {
  let incrementalGroupId = 0

  return state
    ? {
        ...state,
        groups: state.groups
          ? state.groups.map((group) => {
              const keygroup = incrementalGroupId++
              let incrementalCriteriaId = 0
              return {
                ...group,
                key: keygroup,
                _control: keygroup,
                criterias: [
                  ...group.criterias.map((criteria) => {
                    const keycriteria = incrementalCriteriaId++
                    return {
                      ...criteria,
                      key: keycriteria,
                      _control: keycriteria,
                    }
                  }),
                ],
              }
            })
          : [],
      }
    : {}
}

export const handleChange = (callback) => {
  return setForm((prevState) => {
    return { ...callback(prevState) }
  })
}

export const handleUpdate = (callback = null) => {
  return updateForm(
    callback
      ? (prevState) => {
          return treatInitialState({ ...callback(prevState) })
        }
      : null
  )
}

export const groupTemplate = () => ({
  title: '',
  description: '',
  type: 'NORMAL',
  criterias: [],
  dirty: true,
})

export const criteriaTemplate = () => ({
  title: '',
  description: '',
  type: 'SINGLE_CHOICE',
  options: [],
  dirty: true,
})

export const addGroup = (state) => {
  return {
    ...state,
    groups: [...state.groups, groupTemplate()],
  }
}

export const removeGroup = (state, groupIndex) => {
  const newState = {
    groups: state.groups.map((group, index) => {
      if (index !== groupIndex) return group
      return {
        ...group,
        delete: true,
        dirty: true,
      }
    })
  }

  return newState
}

export const addCriteria = (state, groupIndex) => {
  return {
    ...state,
    groups: [
      ...state.groups.map((group, key) => {
        if (key !== groupIndex) {
          return group
        } else {
          return {
            ...group,
            dirty: true,
            criterias: [...group.criterias, criteriaTemplate()],
          }
        }
      }),
    ],
  }
}

export const removeCriteria = (state, groupIndex, criteriaIndex) => {
  return {
    ...state,
    groups: [
      ...state.groups.map((group, key) => {
        if (key !== groupIndex) {
          return group
        } else {
          return {
            ...group,
            dirty: true,
            criterias: group.criterias.map((criteria, index) => {
              if (index !== criteriaIndex) return criteria
              return {
                ...criteria,
                delete: true,
                dirty: true,
              }
            }),
          }
        }
      }),
    ],
  }
}

export const changeForm = (state, values) => {
  return {
    ...state,
    ...values,
    dirty: true,
  }
}

export const changeGroup = (state, groupIndex, values) => {
  return {
    ...state,
    groups: state.groups.map((group, key) => {
      if (key !== groupIndex) {
        return group
      } else {
        return {
          ...group,
          ...values,
          dirty: true,
        }
      }
    }),
  }
}

export const changeCriteria = (state, groupIndex, criteriaIndex, values) => {
  return {
    ...state,
    groups: state.groups.map((group, key) => {
      if (key !== groupIndex) {
        return group
      } else {
        return {
          ...group,
          dirty: true,
          criterias: group.criterias.map((criteria, key) => {
            if (key !== criteriaIndex) {
              return criteria
            } else {
              return {
                ...criteria,
                ...values,
                dirty: true,
              }
            }
          }),
        }
      }
    }),
  }
}

export const addCriteriaDependency = (state, groupIndex) => {
  return treatInitialState(
    state.map((group, key) => {
      if (key !== groupIndex) {
        return group
      } else {
        return {
          ...group,
          dirty: true,
          criterias: [
            ...group.criterias,
            { ...criteriaTemplate(), formCriteriaDependency: {} },
          ],
        }
      }
    })
  )
}
