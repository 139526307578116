import store from '~/boot/store'

import { setNoticeSubscriptionFormQuestionnaire } from '../store/actions'

const setQuestionnaire = (callback) => {
  const questionnaire = store.getState().noticeSubscriptionFormQuestionnaire
    .noticeQuestionnaire

  const newQuestionnaire = callback(questionnaire)

  store.dispatch(setNoticeSubscriptionFormQuestionnaire(newQuestionnaire))
}

export default setQuestionnaire
