import React from 'react'
import PropTypes from 'prop-types'

import { Content } from './styles'

import Image from '~/components/Image'

import colors from '~/utils/colors'
import classNames from 'classnames'
function CircleIcon({
  iconSize,
  color,
  colorActive,
  size,
  icon,
  iconActive,
  active,
  ...props
}) {
  return (
    <Content size={size} color={active ? colorActive : color} {...props} className={classNames(props.className, 'icon')}>
      <Image
        alt=""
        className={'accessibility_invert'}
        aria-hidden={true}
        height={iconSize}
        width={iconSize}
        src={active ? iconActive : icon}
      />
    </Content>
  )
}

CircleIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number.isRequired,
  iconSize: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  colorActive: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconActive: PropTypes.string,
  active: PropTypes.bool,
}

CircleIcon.defaultProps = {
  size: 30,
  iconSize: 24,
  color: colors.white,
  colorActive: colors.dark,
  active: false,
}

export default CircleIcon
