import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

import { getNotice } from '~/views/Notices/services/requests'
import { getSubscriptionByNoticeIdAndOrder } from '../services/requests'

import { getSubscriptionForm, setSubscription } from '../store/actions'
import { setNotice } from '~/views/Notices/store/actions'

export default function requirements(resolve, redirect, { match, history }) {
  return () => {
    const dispatch = useDispatch()
    const user = useShallowEqualSelector((state) => state.profile.user)

    useEffect(() => {
      async function findNoticeAndSubscription() {
        const notice = await getNotice(match.params.id)

        if (!user.cpf && !user.cnpj && !user.rne) return
        if (!notice) return history.push('/home/minhas-inscricoes')

        dispatch(setNotice(notice))
        dispatch(getSubscriptionForm(notice && notice.id))

        if (user.cpf || user.cnpj || user.rne) {
          if (match.params.order) {
            const subscription = await getSubscriptionByNoticeIdAndOrder(
              match.params.id,
              match.params.order
            )

            if (!subscription) return dispatch(setSubscription({}))

            if (
              subscription.subscription.status === 'WINNER' ||
              subscription.subscription.status === 'NOT_SELECTED' ||
              subscription.subscription.status === 'UNDER_EVALUATION'
            ) {
              return history.push(
                `/inscricao/${notice?.id}/visualizar-inscricao/${subscription?.subscription?.order}`
              )
            }
          }

          // return dispatch(setSubscription(subscription))
        }
      }

      findNoticeAndSubscription()
    }, [dispatch, user.cpf, user.cnpj, user.rne])

    return resolve()
  }
}
