import React from 'react'
import PropTypes from 'prop-types'

import { Content } from './styles'

function CircleText(props) {
  return (
    <Content size={props.size} color={props.color} {...props}>
      {props.children}
    </Content>
  )
}

CircleText.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

export default CircleText
