import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

import * as requests from '~/views/Notices/services/requests'

import { setNotice } from '../store/actions'

export default function getNotice(resolve, redirect, { match, history }) {
  return () => {
    const dispatch = useDispatch()
    const user = useShallowEqualSelector((state) => state.profile.user)

    useEffect(() => {
      async function findNotice() {
        if (user.profileType) {
          if (
            user.profileType === 'ADMIN' ||
            (user.noticesOccupations.length &&
              (user.noticesOccupations.includes('MANAGER') ||
                user.noticesOccupations.includes('CONSULTANT')))
          ) {
            const notice = await requests.getNotice(match.params.id)
            if (!notice) return history.push('/home/meus-editais')
            dispatch(setNotice(notice))
          } else {
            history.push('/')
          }
        }
      }

      findNotice()

      return () => dispatch(setNotice({}))
    }, [dispatch, user, user.profileType])

    return resolve()
  }
}
