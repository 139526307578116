import types from './types'

import { loadMySubscriptions } from '~/views/Home/subpages/MyRelatedSubscriptions/store/actions'

export function getSubscriptionForm(noticeId) {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: types.GET_SUBSCRIPTION_FORM,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/subscription-form`,
            method: 'GET',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

// User

export function publishSubscription() {
  return async (dispatch, getState) => {
    const { subscription } = getState().subscription

    try {
      await dispatch({
        type: types.PUBLISH_USER_SUBSCRIPTION,
        payload: {
          request: {
            url: `/api/subscriptions/${subscription.subscription.id}/publish`,
            method: 'POST',
          },
        },
      })

      return dispatch(loadMySubscriptions())
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function acceptTerm(subscriptionId) {
  return async (dispatch, getState) => {
    try {
      return await dispatch({
        type: types.ACCEPT_TERM_USER_SUBSCRIPTION,
        payload: {
          request: {
            url: `/api/subscriptions/${subscriptionId}/accept-term`,
            method: 'PUT',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function cancelUserSubscription(id) {
  return async (dispatch) => {
    try {
      const {
        payload: {
          data: { data },
        },
      } = await dispatch({
        type: types.CANCEL_USER_SUBSCRIPTION,
        payload: {
          request: {
            url: `/api/subscriptions/${id}`,
            method: 'DELETE',
          },
        },
      })
      if (data === String(id)) return dispatch(loadMySubscriptions())
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function saveUserSubscriptionAnswers(data) {
  return async (dispatch, getState) => {
    const { subscription } = getState().subscription
    const questionId = Object.keys(data)[0]
    try {
      await dispatch({
        type: types.SAVE_USER_SUBSCRIPTION_ANSWERS,
        payload: {
          request: {
            url: `/api/subscription-answers/${subscription.subscription.id}${questionId ? `/${questionId}` : ''}`,
            method: 'POST',
            data: { ...subscription.answers, ...data },
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function uploadUserSubscriptionFile(data, participantSubscriptionsId) {
  return async (dispatch, getState) => {
    const { subscription } = getState().subscription
    try {
      return await dispatch({
        type: types.UPLOAD_USER_SUBSCRIPTION_FILE,
        payload: {
          request: {
            url: `/api/subscription-files/${
              participantSubscriptionsId || subscription.subscription.id
            }`,
            method: 'POST',
            data,
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function deleteUserSubscriptionFile(usersSubscriptionsId, fileId) {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: types.DELETE_USER_SUBSCRIPTION_FILE,
        payload: {
          request: {
            url: `/api/subscription-files/${usersSubscriptionsId}/${fileId}`,
            method: 'DELETE',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function setUserSubscriptionAnswers(data) {
  return { type: types.SET_USER_SUBSCRIPTION_ANSWERS, data }
}

export function setSubscriptionForm(subscriptionForm) {
  return { type: types.SET_SUBSCRIPTION_FORM, subscriptionForm }
}

export function setSubscription(subscription) {
  return { type: types.SET_SUBSCRIPTION, subscription }
}

export function clearState() {
  return { type: types.CLEAR_STATE }
}

// Participant

export function updateParticipantSubscriptionAnswers(
  participantSubscriptionId,
  questionId
) {
  return async (dispatch, getState) => {
    const { participantSubscription } = getState().subscription
    try {
      return await dispatch({
        type: types.SAVE_PARTICIPANT_SUBSCRIPTION_ANSWERS,
        payload: {
          request: {
            url: `/api/subscription-answers/${participantSubscriptionId}/${questionId}`,
            method: 'PUT',
            data: participantSubscription.answers,
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function setInvisibleFileToSubscription(
  participantSubscriptionId,
  fileId
) {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: types.SET_INVISIBLE_FILE_TO_SUBSCRIPTION,
        payload: {
          request: {
            url: `/api/subscription-answers/${participantSubscriptionId}/${fileId}/file`,
            method: 'PUT',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function setParticipantSubscription(participantSubscription) {
  return { type: types.SET_PARTICIPANT_SUBSCRIPTION, participantSubscription }
}
