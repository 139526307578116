import styled from 'styled-components'
import breakpoints from '~/utils/breakpoints'

import fonts from '~/utils/fonts'

export const RequiredStyle = styled.small`
  display: flex;
  padding-top: 5px;
  color: var(--color-${({ error }) => (!error ? 'orange' : 'red')}) !important;
  font-family: ${fonts.textBold};
  font-size: 12px;
  line-height: 16px;
  min-height: 16px;

  ${({ inline }) =>
    inline
      ? `
      padding-top: 0px;
      @media (max-width: ${breakpoints.xs}) {
          max-width: 70px;
        }`
      : ''};
`
