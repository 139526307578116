import React from 'react'
import getNotice from '../Notices/middlewares/getNotice'
const EnrollmentForm = React.lazy(() => import('./'))

const routes = [
  {
    path: '/editais/:id/gestao/ficha-de-inscricao/:enrollmentFormId',
    component: EnrollmentForm,
    middlewares: [getNotice],
  },
]

export default routes
