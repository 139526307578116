import styled, { css } from 'styled-components'


const buttonCSS = css`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  color: var(--color-dark);
  cursor: pointer;
  background: var(--color-yellow);
  border-radius: 10px 10px 0 0;
  padding: 20px 30px;

  font-family: 'Itau Display Regular';
  font-size: 14px;
  line-height: 18px;

  > span {
    height: 30px;
    width: 30px;
    background: var(--color-white);
    border-radius: 50%;
    display:flex;
    align-items:center;
    justify-content:center;

    ${({ noText, right }) => !noText && !right && `margin-right: 10px;`}
    ${({ noText, right }) => !noText && right && `margin-left: 10px;`}

    ${({ size }) =>
      size &&
      `
      height: ${size}px;
      width: ${size}px;
      `}


    > img {
      width: 24px;
      height: 24px;
      display: block;

      :nth-child(1) {
        display: block;
      }

      :nth-child(2) {
        display: none;
      }
    }
  }

  &:hover,
  &:focus,
  &.active,
  &.hover {
    color: var(--color-black);

    > span {
      background: var(--color-dark);

      > img {
        :nth-child(1) {
          display: none;
        }

        :nth-child(2) {
          display: block;
        }
      }
    }
  }

  &:visited,
  &:active,
  &:link {
    color: var(--color-dark);
  }
`

export const Content = styled.button`
  ${buttonCSS}
`

export const ContentLink = styled.a`
  ${buttonCSS}
`
