import { useMemo } from 'react'
import store from '~/boot/store'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

import {
  MULTIPLE_CHOICE,
  MULTIPLE_OPTIONS,
} from '../components/Content/Section/Question/services/questionTypes'

import setQuestionnaire from './setQuestionnaire'
import updateQuestionnaire from './updateQuestionnaire'

export function treatInitialState(sections) {
  let incrementalSectionId = 0

  return sections.map((section) => {
    const keysection = incrementalSectionId++
    let incrementalQuestionId = 0
    return {
      ...section,
      dirty: false,
      key: keysection,
      _control: keysection,
      questions: [
        ...section.questions.map((question) => {
          const keyquestion = incrementalQuestionId++
          return {
            ...question,
            dirty: false,
            key: keyquestion,
            _control: keyquestion,
          }
        }),
      ],
    }
  })
}

export function treatInitialStateBeforeUpdate(sections) {
  let incrementalSectionId = 0

  return sections.map((section) => {
    const keysection = incrementalSectionId++
    let incrementalQuestionId = 0
    return {
      ...section,
      dirty: !!section.dirty,
      key: keysection,
      _control: keysection,
      questions: [
        ...section.questions.map((question) => {
          const keyquestion = incrementalQuestionId++
          return {
            ...question,
            dirty: !!question.dirty,
            key: keyquestion,
            _control: keyquestion,
          }
        }),
      ],
    }
  })
}

export const handleAction = (callback) => {
  setQuestionnaire((prevState) => {
    return [...callback(prevState)]
  })
}

export const handleUpdate = (callback = null) => {
  updateQuestionnaire(
    callback
      ? (prevState) => {
          return treatInitialStateBeforeUpdate([...callback(prevState)])
        }
      : null
  )
}

export const sectionTemplate = () => ({
  title: '',
  description: '',
  type: 'NORMAL',
  openInAnotherPage: true,
  questions: [],
  dirty: true,
})

export const questionTemplate = () => ({
  title: '',
  description: '',
  formQuestionTypesId: 1,
  includeOpenField: true,
  requiredComment: false,
  fileImageId: null,
  requiredQuestion: true,
  chosenCustomOption: {},
  questionData: {
    text: '',
  },
  dirty: true,
})

export const addSection = (state) => {
  return [...state, sectionTemplate()]
}

export const removeSection = (state, sectionIndex) => {
  const newState = state.map((section, index) => {
    if (index !== sectionIndex) return section
    return {
      ...section,
      delete: true,
      dirty: true,
    }
  })

  return newState
}

export const addQuestion = (state, sectionIndex) => {
  return state.map((section, key) => {
    if (key !== sectionIndex) {
      return section
    } else {
      return {
        ...section,
        dirty: true,
        questions: [...section.questions, questionTemplate()],
      }
    }
  })
}

export const removeQuestion = (state, sectionIndex, questionIndex) => {
  return state.map((section, key) => {
    if (key !== sectionIndex) {
      return section
    } else {
      return {
        ...section,
        dirty: true,
        questions: section.questions.map((question, index) => {
          if (index !== questionIndex) {
            return question
          } else {
            return {
              ...question,
              dirty: true,
              delete: true,
            }
          }
        })
      }
    }
  })
}

export const changeSection = (state, sectionIndex, values) => {
  return state.map((section, key) => {
    if (key !== sectionIndex) {
      return section
    } else {
      return {
        ...section,
        dirty: true,
        ...values,
      }
    }
  })
}

export const changeQuestion = (state, sectionIndex, questionIndex, values) => {
  return state.map((section, key) => {
    if (key !== sectionIndex) {
      return section
    } else {
      return {
        ...section,
        dirty: values.dirty ?? true,
        questions: section.questions.map((question, key) => {
          if (key !== questionIndex) {
            return question
          } else {
            return {
              ...question,
              dirty: true,
              ...values,
            }
          }
        }),
      }
    }
  })
}

export const addQuestionDependency = (state, sectionIndex) => {
  return treatInitialState(
    state.map((section, key) => {
      if (key !== sectionIndex) {
        return section
      } else {
        return {
          ...section,
          questions: [
            ...section.questions,
            { ...questionTemplate(), formQuestionDependency: {} },
          ],
        }
      }
    })
  )
}

export const getQuestionsAvailable = (sectionIndex) => {
  const questionnaire = store.getState().noticeSubscriptionFormQuestionnaire
    .noticeQuestionnaire

  return questionnaire
    .reduce((carry, section) => {
      return [...carry, ...section.questions]
    }, [])
    .filter((question) =>
      [MULTIPLE_CHOICE, MULTIPLE_OPTIONS].includes(question.formQuestionTypesId)
    )
}

export const useGetSectionQuestions = (sectionIndex) => {
  return useShallowEqualSelector(({ noticeSubscriptionFormQuestionnaire }) =>
    noticeSubscriptionFormQuestionnaire.noticeQuestionnaire.reduce(
      (carry, section) => [...carry, ...section.questions],
      []
    )
  )
}

export const useGetQuestionsAvailable = (questions) => {
  return useMemo(() => {
    return questions.filter((question) =>
      [MULTIPLE_CHOICE, MULTIPLE_OPTIONS].includes(question.formQuestionTypesId)
    )
  }, [questions])
}
