import React from 'react'

import { PaginationStyle } from '../styles'

import { Input, Inline, Button, T3 } from '~/components'

function Pagination({
  gotoPage,
  previousPage,
  canPreviousPage,
  nextPage,
  pageCount,
  canNextPage,
  pageOptions,
  pageIndex,
  setPageSize,
  pageSize,
}) {
  return (
    <PaginationStyle>
      <Inline>
        <Button
          aria-label="Voltar para primeira página"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="mr-1  mb-1"
          size={3}
        >
          {'<<'}
        </Button>
        <Button
          aria-label="Voltar página"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="mr-1  mb-1 "
          size={3}
        >
          {'<'}
        </Button>
        <Button
          aria-label="Avançar página"
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="mr-1  mb-1"
          size={3}
        >
          {'>'}
        </Button>
        <Button
          aria-label="Avançar para a última página"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="mr-1  mb-1"
          size={3}
        >
          {'>>'}
        </Button>
        <T3 style={{ lineHeight: '40px' }} className="mr-1">
          {pageIndex + 1} de {pageOptions.length} | Página:
        </T3>
        <Input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            gotoPage(page)
          }}
          style={{ height: '40px', width: '120px' }}
          className="mr-1 mb-1"
          min={1}
        />
        <T3 style={{ lineHeight: '40px' }} className="mr-1">
          Mostrar
        </T3>
        <Input
          type="number"
          defaultValue={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
          style={{ height: '40px', width: '120px' }}
          min={1}
        />
      </Inline>
    </PaginationStyle>
  )
}

export default Pagination
