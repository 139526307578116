import React from 'react'
import PropTypes from 'prop-types'

import { Content } from './styles'

import { AlertWhiteIcon } from '~/styles/Icons'
import { CheckWhiteIcon } from '~/styles/Icons'
import classNames from 'classnames'

function Alert(props) {
  const Icon =
    props.type === 'danger' || props.type === 'warning'
      ? AlertWhiteIcon
      : CheckWhiteIcon

  return (
    <Content {...props} className={classNames(props.className, 'alert')}>
      <img src={Icon} alt="" aria-hidden />
      <p>{props.children}</p>
    </Content>
  )
}

Alert.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(['success', 'warning', 'danger']),
}

export default Alert
