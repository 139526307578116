import styled from 'styled-components'

export const DropdownIndicatorStyle = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`

export const LabelStyle = styled.label`
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  margin-bottom: ${({ labelSpace }) =>
    labelSpace ? labelSpace + 'px' : '5px'};
  display: block;
`
