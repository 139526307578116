import { useEffect } from 'react'
import * as requests from '../services/requests'

export default function isActiveEvaluations(resolve, redirect, { match, history }) {
  return () => {

    useEffect(() => {
        async function evaluateActive(){
            const activesEvaluations = await requests.getActiveEvaluations()
            const pastEvaluations = await requests.getPastEvaluations({
              term: '',
              sortBy: [],
              pageIndex: 0,
              pageSize: 10
            })

            if (!activesEvaluations?.length && !pastEvaluations?.evaluations.length) {
              return history.push('/home/minhas-inscricoes')
            }
        }
        evaluateActive()
    }, [])

    return resolve()
  }
}
