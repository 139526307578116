import React from 'react'
import PropTypes from 'prop-types'
import colors from '~/utils/colors'

import fonts from '~/utils/fonts'

import {
  MenuStyle,
  ProfileStyle,
  ProfileDetails,
  Avatar,
  WelcomeMessage,
  ProfileName,
  MenuContentStyle,
  LeftStyle,
  LeftContentStyle,
  RightStyle,
  DivisorStyle,
  SidesStyle,
  RequiredStyle,
  RequiredErrorStyle,
} from './styles'

import {
  Row,
  Col,
  HorizontalRule,
  Container,
  ButtonCircleIconLink,
  ButtonCircleIconLinkExternal,
  Span,
  T3,
  AccessibilityDropdown,
} from '~/components'

import { ArrowLineLeftIcon, ArrowLineLeftLightIcon, AvatarAccessibility } from '~/styles/Icons'
import classNames from 'classnames'

function Menu(props) {
  return (
    <MenuStyle {...props}>
      <Container>
        <MenuContentStyle className='menu'>{props.children}</MenuContentStyle>
      </Container>
    </MenuStyle>
  )
}

Menu.propTypes = {
  backBarHeight: PropTypes.number,
}

Menu.defaultProps = {
  backBarHeight: 0,
}

function Profile(props) {
  return (
    <ProfileStyle>
      <Row>
        <Col sm={9} smoffset={1} xs={22} xsoffset={1}>
          <ProfileDetails>
            <Avatar
              hasBorderRadius={props.hasBorderRadius}
              src={props.avatar}
              alt="Imagem de perfil do usuário"
              aria-hidden={true}
              width="50"
              height="50"
            />
            <div>
              <WelcomeMessage>Olá!</WelcomeMessage>
              <ProfileName color={'yellow'}>{props.name}</ProfileName>
            </div>
          </ProfileDetails>
        </Col>
        <Col sm={6} smoffset={7} xs={22} xsoffset={1}>
        <AccessibilityDropdown>
          <ProfileDetails flexEnd>
            <div>
              <small>Opções de </small>
              <ProfileName color={'lightGray'}>Acessibilidade</ProfileName>
            </div>
            <Avatar
              hasBorderRadius={props.hasBorderRadius}
              src={AvatarAccessibility}
              alt="Opções de acessibilidade"
              aria-hidden={true}
              width="50"
              height="50"
              flexEnd
            />
          </ProfileDetails>
        </AccessibilityDropdown>
        </Col>
      </Row>
    </ProfileStyle>
  )
}

Profile.propTypes = {
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

function Bottom(props) {
  return (
    <div className={classNames(props.className, 'menu-bottom')} style={props.style}>
      <Row>
        <Col xs={22} xsoffset={1}>
          <HorizontalRule height={2} color={'gray'} opacity={0.2} />
        </Col>
      </Row>
      {props.children}
    </div>
  )
}

Bottom.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

function Sides(props) {
  return (
    <Row {...props}>
      <SidesStyle className='menu-sides'>{props.children}</SidesStyle>
    </Row>
  )
}

Sides.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
}

function Left(props) {
  return (
    <Col md={13} sm={12} xs={22} xsoffset={1} {...props} onClick={() => {}}>
      <LeftStyle>
        {props.backto ? (
          <ButtonCircleIconLink
            to={props.backto}
            icon={ArrowLineLeftIcon}
            iconHover={ArrowLineLeftLightIcon}
            aria-label={'Voltar'}
            className="mr-3"
          />
        ) : (
          <ButtonCircleIconLinkExternal
            className="mr-3"
            href={''}
            aria-label={'Voltar'}
            icon={ArrowLineLeftIcon}
            iconHover={ArrowLineLeftLightIcon}
            onClick={props.onClick}
          />
        )}
        <LeftContentStyle>
          <T3 color={colors.yellow} font={fonts.displayXBold}>
            {props.title}
          </T3>
          {props.children}
        </LeftContentStyle>
      </LeftStyle>
    </Col>
  )
}

Left.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
  lgoffset: PropTypes.number,
  mdoffset: PropTypes.number,
  smoffset: PropTypes.number,
  xsoffset: PropTypes.number,
  backto: PropTypes.string,
  title: PropTypes.string.isRequired,
}

function Right(props) {
  return (
    <Col sm={9} smoffset={0} xs={22} xsoffset={1} {...props}>
      <RightStyle className='menu-right'>
        <T3
          color={colors.white}
          font={fonts.textRegular}
          className="mobile-center"
        >
          <Span color={colors.white} font={fonts.textXBold}>
            Edital:{' '}
          </Span>
          {props.notice}
        </T3>
        {props.children}
      </RightStyle>
    </Col>
  )
}

Right.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  verticalLineSize: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
  lgoffset: PropTypes.number,
  mdoffset: PropTypes.number,
  smoffset: PropTypes.number,
  xsoffset: PropTypes.number,
  notice: PropTypes.string,
}

function Divisor(props) {
  return (
    <Col sm={1} className="mobile-hide" {...props}>
      <DivisorStyle />
    </Col>
  )
}

Divisor.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  verticalLineSize: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
  lgoffset: PropTypes.number,
  mdoffset: PropTypes.number,
  smoffset: PropTypes.number,
  lgsize: PropTypes.number,
  mdsize: PropTypes.number,
  smsize: PropTypes.number,
}

function Required(props) {
  if (props.visible) {
    if (!props.error) {
      return <RequiredStyle />
    } else {
      return <RequiredErrorStyle onClick={() => props.onClick() || null} />
    }
  } else {
    return null
  }
}

Required.propTypes = {
  error: PropTypes.bool,
  visible: PropTypes.bool,
}

Menu.Profile = Profile
Menu.Bottom = Bottom
Menu.Sides = Sides
Menu.Left = Left
Menu.Right = Right
Menu.Divisor = Divisor
Menu.Required = Required

export default Menu
