import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import useOutsideClick from '~/hooks/useOutsideClick'

import { T4 } from '~/components'
import colors from '~/utils/colors'

import { time } from '~/utils/masks'

import { Container, InputStyle, LabelStyle, List, Button } from './styles'

const hours = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
]

function TimePicker(props) {
  const wrapperRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState('')
  useOutsideClick(wrapperRef, () => setIsFocused(false))

  useEffect(() => {
    if (props.value) setInputValue(time(props.value).maskedString)
  }, [props.value])

  return (
    <Container
      ref={wrapperRef}
      className={classnames('time-picker', props.className)}
      aria-haspopup="true"
      aria-expanded={isFocused}
      fullWidth={props.fullWidth}
    >
      {props.labelInfo ||
        (props.label && (
          <LabelStyle htmlFor={props.id}>{props.label}</LabelStyle>
        ))}
      <InputStyle
        {...props}
        autoComplete={'off'}
        placeholder={props.placeholder || '00:00'}
        aria-label={props['aria-label']}
        onClick={() => {
          props.onClick && props.onClick(isFocused)
          setIsFocused(!isFocused)
        }}
        onBlur={() => props.onSelect && props.onSelect(inputValue)}
        onChange={({ target: { value } }) => {
          const { string, maskedString } = time(value)
          props.onChange && props.onChange(string, maskedString)
          setInputValue(maskedString)
        }}
        value={inputValue}
      />
      <List aria-labelledby={props.id} isOpen={isFocused}>
        <ul>
          {hours.map((child, key) => (
            <li
              key={key}
              onClick={() => {
                props.onSelect && props.onSelect(child, key)
                setInputValue(child)
                setIsFocused(false)
              }}
            >
              <Button active={null}>
                <T4 color={colors.darkGray}>{child}</T4>
              </Button>
            </li>
          ))}
        </ul>
      </List>
    </Container>
  )
}

TimePicker.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelInfo: PropTypes.element,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
}

export default TimePicker
