import Reports from '.'

const reportRoutes = [
  {
    path: '/relatorios',
    component: Reports,
    exact: true,
  },
]

export default reportRoutes
