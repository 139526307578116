import { useMemo } from 'react'

// import { Checkbox } from '~/components'
// import { TrashIcon, TrashLightIcon } from '~/styles/Icons'

export default function useCreateTableColumns() {
  return useMemo(
    () => [
      {
        Header: 'Instituições Inscritas',
        accessor: 'intitutionName',
        style: {
          width: '25%',
        },
      },
      {
        Header: 'Cidade',
        accessor: 'city',
        style: {
          width: '25%',
        },
      },
      {
        Header: 'Estado',
        accessor: 'state',
        style: {
          width: '25%',
        },
      },
      {
        Header: 'Inscrição',
        accessor: 'subscriptionCreatedAt',
        style: {
          width: '25%',
        },
      },
    ],
    []
  )
}
