import React, { memo, useCallback, useEffect, useState } from 'react'

import { Inline, Checkbox } from '~/components'

function Targets({ param, setParam }) {
  const [selecteds, setSelecteds] = useState([])

  const publics = [
    { public: 'PF', label: 'Pessoa Física' },
    { public: 'PJ', label: 'Pessoa Jurídica' },
  ]

  useEffect(() => {
    if (!Array.isArray(param) && param) {
      const select = param.split(',')

      setSelecteds(select.map((item) => item))

      setParam(select)
    } else {
      if (param) {
        setSelecteds(param.map((item) => item))
      }
    }
  }, [setParam, param])

  const changeSelected = useCallback(
    (value) => {
      const selected = selecteds.find(selected => selected === value)
      if (selected) {
        setParam(selecteds.filter((item) => item !== value))
      } else {
        setParam([...selecteds, value])
      }
    },
    [setParam, selecteds]
  )

  return (
    <>
      <div className="my-5 mx-6 px-3" style={{ minHeight: 50 }}>
        <Inline right={30} className="mb-5" mobileCenter style={{ justifyContent: 'center', flex: 1 }}>
          {publics.map((item, key) => (
            <Checkbox
              key={key}
              id={`checkbox-${item.label}`}
              aria-label={item.label}
              name="public"
              value={item.public}
              checked={selecteds.length && !!selecteds.find(i => i === item.public)}
              onChange={() => changeSelected(item.public)}
            >
              {item.label}
            </Checkbox>
          ))}
        </Inline>
      </div>
    </>
  )
}

export default memo(Targets)
