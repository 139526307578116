import React from 'react'
import PropTypes from 'prop-types'

import colors from '~/utils/colors'

import { WarningStyle } from './styles'

function Warning(props) {
  return <WarningStyle {...props} />
}

Warning.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  radius: PropTypes.number,
  color: PropTypes.string,
}

Warning.defaultProps = {
  radius: 10,
  color: colors.white,
}

export default Warning
