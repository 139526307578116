import types from './types'

export function loadNoticeSubscriptionFormInfo(noticeId) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.LOAD_NOTICE_SUBSCRIPTION_FORM_INFO,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/subscription-form/info`,
            method: 'GET',
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function createNoticeSubscriptionFormInfo(noticeId) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.CREATE_NOTICE_SUBSCRIPTION_FORM_INFO,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/subscription-form/info`,
            method: 'POST',
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function updateNoticeSubscriptionFormInfo(noticeId, info = {}) {
  return async (dispatch) => {
    try {
      const data = await dispatch({
        type: types.UPDATE_NOTICE_SUBSCRIPTION_FORM_INFO,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/subscription-form/info`,
            method: 'PUT',
            data: info,
          },
        },
      })

      console.log('payload', data)
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function setNoticeSubscriptionFormInfo(info) {
  return { type: types.SET_NOTICE_SUBSCRIPTION_FORM_INFO, info }
}

export function clearState() {
  return { type: types.CLEAR_STATE }
}
