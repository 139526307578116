import { combineActions, handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  stats: {},
}

const beginLoading = combineActions(types.STATS)

const stopLoading = combineActions(types.STATS_SUCCESS, types.STATS_FAIL)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.STATS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: stats },
        },
      }
    ) => ({
      ...state,
      stats: stats ? stats : {},
    }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
