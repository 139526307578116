import styled from 'styled-components'
import { NavLink as NavLinkComponent } from 'react-router-dom'



export const HeaderStyle = styled.header`
  border-top: 10px solid var(--color-yellow);
  background: var(--color-white);
  padding-bottom: 80px;
  position: relative;
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px 0 0 0;
  align-items: center;
`

export const Logo = styled.img`
  flex-shrink: 0;
  width: 116px;
  height: 50px;
  align-self: initial;

  @media (max-width: 1022px) {
    margin-right: 14px;
  }

  @media (min-width: 798px) {
    margin-right: 49px;
  }
`

export const Nav = styled.nav`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 798px) {
    * {
      display: none;
    }
  }
`

export const NavMobile = styled.nav`
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (min-width: 798px) {
    display: none;
  }

  @media (max-width: 510px) {
    flex-direction: column;
  }
`

export const NavLink = styled(NavLinkComponent)`
  color: var(--color-darkGray);
  font-family: 'Itau Display Regular';
  font-size: 18px;
  line-height: 23px;
  position: relative;
  padding: 3px;

  span {
    display: none;
    position: absolute;
    background: var(--color-yellow);
    height: 5px;
    width: 100%;
    top: 29px;
    left: 0;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--color-dark);
  }

  &.active {
    text-decoration: none;
    color: var(--color-dark);

    span {
      display: block;
    }
  }

  & + & {
    margin-left: 30px;

    @media (max-width: 510px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`
