import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD_RISK_ANALYSIS'),
    ...async('LOAD_NOTICE_PROCESS_STEPS_RISK_ANALYSIS'),
    ...async('UPDATE_NOTICE_PROCESS_STEPS_RISK_ANALYSIS'),
    'SET_NOTICE_PROCESS_STEPS_RISK_ANALYSIS_HORUS_INTEGRATION',
    'SET_NOTICE_PROCESS_STEPS_RISK_ANALYSIS_HOMOLOGATION_CODE',
    'CLEAR_STATE',
  ],
  'NOTICE_PROCESS_STEPS_RISK_ANALYSIS'
)
