import store from '~/boot/store'

import { setNoticeEvaluationCriteriaForm } from '../store/actions'

const setForm = (callback) => {
  const form = store.getState().noticeProcessStepsEvaluationCriteriaForm.form

  const newForm = callback(form)
  store.dispatch(setNoticeEvaluationCriteriaForm(newForm))
}

export default setForm
