import { useEffect } from 'react'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

export default function activeStep(stepName) {
  return (resolve, redirect, { match, history }) => {
    return () => {
      const processSteps = useShallowEqualSelector(
        ({ noticeManagement }) => noticeManagement.processSteps
      )

      useEffect(() => {
        if (processSteps && processSteps.length) {
          const found = processSteps.find(
            (step) => step.identifier === stepName
          )
          if (found && !found.active) {
            history.push('/')
          }
        }
      }, [processSteps])

      return resolve()
    }
  }
}
