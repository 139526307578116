import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${({ noWrap }) => noWrap && `flex-wrap: nowrap;`};
  ${({ verticalMiddle }) => verticalMiddle && `align-items: center;`};
  ${({ center }) => center && `justify-content: center;`};
  ${({ justify }) => justify && `justify-content: space-between;`};

  ${({ bottom }) => bottom && `margin-bottom: ${bottom}px`};

  > * {
    ${({ right }) => right && `margin-right: ${right}px`};
    ${({ bottom }) => bottom && `margin-bottom: ${bottom}px`};
  }

  > *:last-child {
    margin-right: 0px;
  }

  ${({ mobileCenter }) =>
    mobileCenter &&
    `
    @media (max-width: 1022px) {
      flex-direction: column;

      > * {
        margin-top: 10px;
        margin-right: 0px;
      }

      > *:first-child {
        margin-top: 0px;
      }
    }
    `}

  ${({ mobileJustifyCenter }) =>
    mobileJustifyCenter &&
    `
    @media (max-width: 1022px) {
      flex-direction: row;
      justify-content: center;
    }
    `}
`
