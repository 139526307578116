import axios from '~/boot/client'
import baseConfig from '~/utils/baseConfig'

const exts = [
  'png',
  'jpg',
  'jpeg',
  'pdf',
  'docx',
  'doc',
  'txt',
  'xls',
  'xlsx',
  'pptx',
  'ppt',
]

const uploadFile = async (file) => {
  if (!file) {
    return null
  }
  const reExtension = /(?:\.([^.]+))?$/
  const reFilename = /^[^<>:;,?"*|/]+$/

  const data = new FormData()
  data.append('file', file)
  const size = file.size / 1024 / 1024
  if (size > 20) return alert('Arquivo excede o tamanho de 20MB')
  let extension = reExtension.exec(file.name)[1];
  extension = extension.toLowerCase();

  if (!exts.includes(extension)) {
    alert('Tipo (extensão) de documento não suportado.')
    return {
      success: false,
    }
  }

  if (!reFilename.test(file.name)) {
    alert('Nome do arquivo não suportado.')
    return {
      success: false,
    }
  }

  try {
    const { data: response } = await axios.post(
      `${baseConfig.url}/api/upload`,
      data
    )
    const { id } = response.data

    return {
      success: response.success,
      data: {
        id,
        url: `${baseConfig.url}/${response.data.relativeUrl}`,
        fullUrl: response.data.fullUrl,
        name: response.data.name,
        mimeType: response.data.mimeType,
      },
    }
  } catch (e) {
    if (e?.response?.status === 429) {
      alert('O limite máximo de arquivos enviados por dia foi atingido')
    } else {
      alert('Não foi possível realizar o upload')
    }

    return {
      success: false,
    }
  }
}

export const handleUpload = async (file) => {
  const failedUpload = {
    success: false,
    file: {
      isLoading: false,
    },
  }

  try {
    const uploadedFile = await uploadFile(file)

    if (!uploadedFile) {
      return failedUpload
    }

    if (!uploadedFile.success) {
      alert('Não foi possível enviar o arquivo')
      return failedUpload
    }

    return {
      success: true,
      file: {
        ...uploadedFile.data,
        isLoading: false,
      },
    }
  } catch (err) {
    alert('Não foi possível enviar o arquivo')
    return failedUpload
  }
}
