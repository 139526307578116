import { handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isOpen: false,
}

const reducer = handleActions(
  {
    [types.SET_IS_OPEN]: (state, { isOpen }) => ({
      isOpen,
    }),
  },
  INITIAL_STATE
)

export default reducer
