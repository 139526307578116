import React from 'react'

import { Filters } from '~/components'

import Notices from './Notices'
import TypeNotices from './TypeNotices'
import NoticeStatus from './NoticeStatus'

function FiltersSubs(props) {
  return (
    <Filters {...props}>
      <Filters.Filter paramName={'notices'}>
        {({ param, onRemove, setParam, setParamCount }) => (
          <Notices
            param={param}
            onRemove={onRemove}
            setParam={setParam}
            setParamCount={setParamCount}
          />
        )}
      </Filters.Filter>
      <Filters.Filter paramName={'status'}>
        {({ param, onRemove, setParam, setParamCount }) => (
          <NoticeStatus
            param={param}
            onRemove={onRemove}
            setParam={setParam}
            setParamCount={setParamCount}
          />
        )}
      </Filters.Filter>
      <Filters.Filter paramName={'target'}>
        {({ param, onRemove, setParam, setParamCount }) => (
          <TypeNotices
            param={param}
            onRemove={onRemove}
            setParam={setParam}
            setParamCount={setParamCount}
          />
        )}
      </Filters.Filter>
    </Filters>
  )
}

export default FiltersSubs
