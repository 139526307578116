import React from 'react'
import { useState } from 'react'
import socketIo from 'socket.io-client'
import baseConfig from '~/utils/baseConfig'

import { Button, Inline, Modal, T3 } from '~/components'
import { CheckIcon, CheckLightIcon } from '~/styles/Icons'

function ModalSessionAlert() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const socket = socketIo(baseConfig.url, {
    transports: /* ['websocket'] */ undefined,
    reconnect: true,
    secure: true,
    withCredentials: true,
  })

  socket.on('connect_error', (err) => {
    console.log(`connect_error due to ${err.message}`)
  })

  socket.on('sessionStarted', () => {
    setIsOpen(true)
  })

  return (
    <Modal id="modal-session-alert" md isOpen={isOpen} toggle={toggle}>
      <Modal.Header type="warning" title="Nova sessão iniciada" />

      <Modal.Body>
        <T3 style={{ textAlign: 'center' }}>
          Uma nova sessão foi iniciada em outro dispositivo. Você pode continuar
          navegando normalmente.
        </T3>
      </Modal.Body>

      <Modal.Footer>
        <Inline right={10}>
          <Button
            aria-label="Continuar"
            className="mb-3"
            onClick={toggle}
            icon={CheckLightIcon}
            iconHover={CheckIcon}
          >
            Continuar
          </Button>
        </Inline>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSessionAlert
