import React, { Suspense } from 'react'
import SuspenseLoading from '~/components/SuspenseLoading'

import {
  Route as ReactRoute,
  Redirect as ReactRedirect,
} from 'react-router-dom'

function CustomRoute({
  component: Component,
  exact,
  path,
  middlewares,
  match,
  history,
  ...rest
}) {
  function resolve(props) {
    return {
      component: Component && (
        <Suspense fallback={<SuspenseLoading />}>
          <Component match={match} history={history} {...rest} {...props} />
        </Suspense>
      ),
      success: true,
    }
  }

  function redirect(to) {
    return { component: <ReactRedirect to={to} {...rest} />, success: false }
  }

  if (middlewares && middlewares.length) {
    let response = null

    for (let i = 0; i < middlewares.length; i++) {
      const md = middlewares[i](resolve, redirect, { match, history })
      response = md()
      if (response.success === false) {
        break
      }
    }

    return response.component
  } else {
    return (
      Component && (
        <Suspense fallback={<SuspenseLoading />}>
          <Component match={match} history={history} {...rest} />
        </Suspense>
      )
    )
  }
}

function Route(props) {
  return (
    <ReactRoute
      {...props}
      component={(rest) => <CustomRoute {...props} {...rest} />}
    />
  )
}

export default Route
