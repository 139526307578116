import React, { useRef, useState } from 'react'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

import baseConfig from '~/utils/baseConfig'
import { onKeyPress, onShiftTabPress } from '~/utils/onKeyPress'

import useOutsideClick from '~/hooks/useOutsideClick'

import { LogoutIcon } from '~/styles/Icons'

import {
  DropdownStyle,
  MainButton,
  List,
  Link,
  LinkLogout,
  LinkExternal,
} from './styles'

function ProfileDropdown({ name, avatar, ...props }) {
  const [isOpen, setIsOpen] = useState(false)
  const profileDropdownRef = useRef(null)
  const user = useShallowEqualSelector(({ profile: { user } }) => user)

  useOutsideClick(profileDropdownRef, () => setIsOpen(false))

  return (
    <DropdownStyle {...props} ref={profileDropdownRef}>
      <MainButton
        className='dropdown-button'
        aria-label="Abrir opções do perfil"
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{name}</span>
        <img
          src={avatar}
          width="40"
          height="40"
          alt="Imagem do perfil do usuário"
          aria-hidden={true}
        />
      </MainButton>
      <List className={`dropdown ${isOpen && ' opened'}`}>
        <span></span>
        <ul>
          <li>
            <LinkExternal
              href={`${baseConfig.url}/api/profile/edit`}
              onKeyDown={(e) => onShiftTabPress(e, () => setIsOpen(false))}
              onClick={() => setIsOpen(false)}
            >
              Meu perfil
            </LinkExternal>
          </li>

          <li>
            <Link to="/home/minhas-inscricoes" onClick={() => setIsOpen(false)}>
              Minhas inscrições
            </Link>
          </li>
          {/*
          {user.noticesOccupations.includes('EVALUATOR') ? (
            <li>
              <Link to="/minhas-avaliacoes" onClick={() => setIsOpen(false)}>
                Minhas avaliações
              </Link>
            </li>
          ) : null}
          */}
          {user.profileType === 'ADMIN' ||
          (user.noticesOccupations &&
            user.noticesOccupations.some((item) =>
              ['MANAGER', 'CONSULTANT'].includes(item)
            )) ? (
            <li>
              <Link to="/" onClick={() => setIsOpen(false)}>
                Meus editais
              </Link>
            </li>
          ) : null}
          <li>
            <LinkLogout
              href={`${baseConfig.url}/api/logout`}
              onKeyDown={(e) => onKeyPress(e, 9, () => setIsOpen(false))}
              onClick={() => setIsOpen(false)}
            >
              Sair{' '}
              <img
                className='accessibility_invert'
                src={LogoutIcon}
                alt="Sair do sistema"
                width="30"
                height="30"
                aria-hidden={true}
              />
            </LinkLogout>
          </li>
        </ul>
      </List>
    </DropdownStyle>
  )
}

export default ProfileDropdown
