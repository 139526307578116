import styled from 'styled-components'


export const Content = styled.div`
  display: flex;
  align-items: center;
  border-radius: 25px;
  background: var(--color-${({ type }) =>
    type === 'danger'
      ? 'red'
      : type === 'warning'
      ? 'orange'
      : 'green'}) !important;
  padding: 5px 51px 5px 10px;

  > img {
    height: 40px;
    width: 40px;
  }

  > p {
    color: var(--color-white);
    font-family: 'Itau Text Regular';
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 100%;
  }
`
