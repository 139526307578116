import styled, { css } from 'styled-components'

import { Link } from 'react-router-dom'

const buttonCSS = css`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  color: var(--color-darkDark);
  cursor: pointer;

  font-family: 'Itau Display Regular';
  font-size: 14px;
  line-height: 18px;

  > span {
    height: 30px;
    width: 30px;
    background: ${({ color }) => color};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ size }) =>
      size &&
      `
      height: ${size}px;
      width: ${size}px;
      `}

    > img {
      width: 24px;
      height: 24px;
      display: block;

      :nth-child(1) {
        display: block;
      }

      :nth-child(2) {
        display: none;
      }
    }
  }

  &:hover,
  &:focus,
  &.active,
  &.hover {
    color: var(--color-black);

    > span {
      background: ${({ coloractive }) => coloractive};

      > img {
        :nth-child(1) {
          display: none;
        }

        :nth-child(2) {
          display: block;
        }
      }
    }
  }

  &:visited,
  &:active,
  &:link {
    color: var(--color-dark);
  }

  &:disabled {
    opacity: 0.5;
  }
`

export const ButtonStyle = styled.button`
  ${buttonCSS}
`

export const LinkStyle = styled(Link)`
  ${buttonCSS}
`

export const LinkExternalStyle = styled.a`
  ${buttonCSS}
`

export const ContentButton = styled.div`
  ${ButtonStyle} {
    > span {
      ${({ noText, right }) => !noText && !right && `margin-right: 8px;`}
      ${({ noText, right }) => !noText && right && `margin-left: 8px;`}
    }
  }
`

export const ContentLink = styled.div`
  ${LinkStyle} {
    > span {
      ${({ noText, right }) => !noText && !right && `margin-right: 8px;`}
      ${({ noText, right }) => !noText && right && `margin-left: 8px;`}
    }
  }
`

export const ContentLinkExternal = styled.div`
  ${LinkExternalStyle} {
    > span {
      ${({ noText, right }) => !noText && !right && `margin-right: 8px;`}
      ${({ noText, right }) => !noText && right && `margin-left: 8px; padding: 5px;`}
    }
  }
`
