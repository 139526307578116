import { async, types } from '~/utils/typeCreator'

export default types([
  ...async('SEARCH_SUBSCRIPTIONS'),
  ...async('STATS'),
  'CLEAR_STATE',
  'NOTICE_MANAGEMENT_SUBSCRIPTIONS',
  'SET_SUBSCRIPTIONS_SELECTED',
],
)
