import styled from 'styled-components'
import colors from '~/utils/colors'

export const TableWrap = styled.div`
  display: block;
  max-width: 100%;
  overflow-x: auto;
`

export const TableStyle = styled.table`
  width: 100%;
`

export const CaptionStyle = styled.caption`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
`

export const THead = styled.thead`
  ${({ hidden }) =>
    hidden &&
    `
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  `}
`

export const TBody = styled.tbody``

export const THeadRow = styled.tr`
  background: var(--color-white);
`

export const THeader = styled.th`
  background: var(--color-white);
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '20px')};
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-dark);
  text-align: ${({ align }) => (align ? align : 'left')};
  display: flex;
  align-items: center;
  justify-content: ${({ align }) =>
    align
      ? align === 'right'
        ? 'flex-end'
        : align === 'left'
        ? 'flex-start'
        : align === 'center'
        ? 'center'
        : 'flex-start'
      : 'flex-start'};

  &:last-child {
    padding-right: 20px;
  }
`

export const TBodyRow = styled.tr`
  border-top: 5px solid var(--color-white);
  cursor: ${({ hasClick }) => (hasClick ? 'pointer' : 'initial')};
`

export const TData = styled.td`
  background: ${colors.light};
  min-height: 61px;
  padding-left: 20px;
  vertical-align: middle;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-darkGray);
  text-align: ${({ align }) => (align ? align : 'left')};
  display: flex;
  align-items: center;

  justify-content: ${({ align }) =>
    align
      ? align === 'right'
        ? 'flex-end'
        : align === 'left'
        ? 'flex-start'
        : align === 'center'
        ? 'center'
        : 'flex-start'
      : 'flex-start'};

  &:first-child {
    border-radius: 10px 0 0 10px;
  }

  &:last-child {
    border-radius: 0 10px 10px 0;
    padding-right: 20px;
  }
`

export const SortArrowStyle = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 5px;
`

export const TDLoading = styled.td`
  background: ${colors.light};
  height: 61px;
  padding: 0 20px;
  vertical-align: middle;
  font-family: 'Itau Text Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-darkGray);
  text-align: ${({ align }) => (align ? align : 'left')};
  border-radius: 10px;
`
