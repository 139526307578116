import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import * as requests from '~/views/Notices/services/requests'
import { setNotice } from '~/views/Notices/store/actions'

export default function getNotice(resolve, redirect, { match, history }) {
  return () => {
    const dispatch = useDispatch()

    useEffect(() => {
      async function findNotice() {
        const notice = await requests.getNotice(match.params.id)
        if (!notice) {
          return history.push('/home/meus-editais')
        }
        dispatch(setNotice(notice))
      }

      findNotice()

      return () => dispatch(setNotice({}))
    }, [dispatch])

    return resolve()
  }
}
