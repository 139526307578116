import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD_EVALUATIONS'),
    ...async('SEARCH_EVALUATIONS'),
    ...async('SEARCH_PAST_EVALUATIONS'),
    'CLEAR_STATE',
  ],
  'HOME_MY_EVALUATIONS'
)
