const fonts = {
  displayRegular: 'Itau Display Regular',
  displayBold: 'Itau Display Bold',
  displayXBold: 'Itau Display XBold',
  textRegular: 'Itau Text Regular',
  textBold: 'Itau Text Bold',
  textXBold: 'Itau Text XBold',
}

export default fonts
