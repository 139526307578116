import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD_NOTICE_QUESTIONNAIRE'),
    ...async('UPDATE_NOTICE_QUESTIONNAIRE'),
    'SET_NOTICE_QUESTIONNAIRE',
    'CLEAR_STATE',
  ],
  'NOTICE_SUBSCRIPTION_FORM'
)
