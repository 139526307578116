import client from '~/boot/client'

export async function getSubscription(id, noticeId) {
  try {
    const response = await client.get(
      `/api/subscriptions/${id}/notice/${noticeId}`
    )
    return response.data.data
  } catch (e) {
    return null
  }
}

export async function viewedSubscriptionStatus(id) {
  try {
    await client.put(`/api/subscriptions/${id}/view-subscription`)
  } catch (e) {
    return null
  }
}
