import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
function Col({ innerRef, ...props }) {
  return (
    <div
      {...props}
      ref={innerRef}
      className={classnames(
        props.className,
        { [`col-lg-${props.lg}`]: props.lg },
        { [`col-md-${props.md}`]: props.md },
        { [`col-sm-${props.sm}`]: props.sm },
        { [`col-xs-${props.xs}`]: props.xs },
        { [`col-lg-offset-${props.lgoffset}`]: props.lgoffset !== null },
        { [`col-md-offset-${props.mdoffset}`]: props.mdoffset !== null },
        { [`col-sm-offset-${props.smoffset}`]: props.smoffset !== null },
        { [`col-xs-offset-${props.xsoffset}`]: props.xsoffset !== null }
      )}
    />
  )
}

Col.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
  lgoffset: PropTypes.number,
  mdoffset: PropTypes.number,
  smoffset: PropTypes.number,
  xsoffset: PropTypes.number,
}

Col.defaultProps = {
  lg: null,
  md: null,
  sm: null,
  xs: 24,
  lgoffset: null,
  mdoffset: null,
  smoffset: null,
  xsoffset: null,
}

export default Col
