import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD_SUBSCRIPTION_FORM_QUESTIONS'),
    ...async('LOAD_EVALUATION_CRITERIA_FORM'),
    ...async('UPDATE_EVALUATION_CRITERIA_FORM'),
    'SET_EVALUATION_CRITERIA_FORM',
    'CLEAR_STATE',
  ],
  'NOTICE_EVALUATION_CRITERIA_FORM'
)
