import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import colors from '~/utils/colors'

export const TabLinksStyle = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding: 20px 0px;
  ${({ center }) => center && `justify-content: center;`}

  ${({ mobileCenter }) =>
    mobileCenter &&
    `
    @media (max-width: 798px) {
      justify-content: center;
      flex-direction: column;

      ${TabLinkStyle} {
          width: fit-content!important;
          margin-left: auto!important;
          margin-right: auto!important;
          span {
            bottom: -5px!important;
          }
      }

      ${TabLinkStyle} + ${TabLinkStyle} {
            margin-top: 30px!important;
      }
    }
    `}

  ${({ light }) =>
    light
      ? `
        background: var(--color-white);

        ${TabLinkStyle}{
          color: var(--color-darkGray);

          :hover,
          &.hover {
            color: var(--color-yellow);
          }

          &:active,
          &:focus,
          &.active  {
            color: var(--color-dark);
          }
        }
        `
      : `
        background: var(--color-dark);

        ${TabLinkStyle}{
          color: var(--color-white);
          opacity: 0.5;

          :hover,
          &.hover {
            color: var(--color-yellow);
            opacity: 1;
          }

          &:active,
          &:focus,
          &.active  {
            color: var(--color-white);
          }
        }
        `}
`

export const TabLinkStyle = styled(NavLink)`
  font-family: 'Itau Display Regular';
  font-size: 18px;
  line-height: 23px;
  position: relative;
  text-align: center;

  span {
    display: none;
    height: ${({ heightspan }) => heightspan + 'px' || '10px'};
    background: ${({ heightspancolor }) =>
      heightspancolor ? heightspancolor : colors.yellow};
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    border-radius: 6px 6px 0 0;
  }

  & + & {
    margin-left: ${({ marginleft }) => marginleft || '50px'};
  }

  &:hover,
  &:active,
  &:visited,
  &:link {
    text-decoration: none;
  }

  &.active {
    opacity: 1;

    span {
      display: flex;
    }
  }
`
