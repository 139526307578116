import types from './types'

export function loadNoticeManagementProcessSteps(noticeId) {
  return async (dispatch) => {
    try {
      await dispatch({
        type: types.LOAD_NOTICE_MANAGEMENT_PROCESS_STEPS,
        payload: {
          request: {
            url: `/api/notices/${noticeId}/management/process-steps`,
            method: 'GET',
          },
        },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function setSubscription(subscription) {
  return { type: types.SET_SUBSCRIPTION, subscription }
}

export function clearState() {
  return { type: types.CLEAR_STATE }
}
