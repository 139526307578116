import styled from 'styled-components'

export const Content = styled.p`
  font-size: 14px;
  line-height: 18px;
  min-height: 18px;
  ${({ font }) => font && `font-family: ${font};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ size }) => size && `font-size: ${size}px;`}
  ${({ line }) => line && `line-height: ${line}px;`}
`
