import React from 'react'
import PropTypes from 'prop-types'

import { FileStyle } from './styles'
import classNames from 'classnames'

function File({ className, style, noTrash, ...props }) {
  return (
    <FileStyle className={classNames(className, 'file-card')} style={style}>
      <p>{props.name || 'Insira um arquivo'}</p>
      {noTrash ? null : <button type="button" {...props} />}
    </FileStyle>
  )
}

File.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  name: PropTypes.string,
}

export default File
