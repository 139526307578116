import { useEffect } from 'react'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

export default function isUserManager(resolve, redirect, { history }) {
  return () => {
    const user = useShallowEqualSelector(({ profile }) => profile.user)

    useEffect(() => {
      if (user.name) {
        if (user.profileType !== 'ADMIN' && (user.noticesOccupations.length &&
          !user.noticesOccupations.includes('MANAGER') &&
          !user.noticesOccupations.includes('CONSULTANT'))) {
          history.push('/')
        } else if (user.profileType !== 'ADMIN' && !user.noticesOccupations.length) {

          history.push('/')
        }
      }
    }, [user])

    return resolve()
  }
}
