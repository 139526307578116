import { handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
}

// const beginLoading = combineActions()

// const stopLoading = combineActions()

const reducer = handleActions(
  {
    // [beginLoading]: (state) => ({
    //   ...state,
    //   isLoading: true,
    // }),
    // [stopLoading]: (state) => ({
    //   ...state,
    //   isLoading: false,
    // }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
