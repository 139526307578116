import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getSubscriptionByNoticeId } from '../services/requests'

import { getSubscriptionForm, setSubscription } from '../store/actions'

import { getNotice } from '~/views/Notices/services/requests'
import { setNotice } from '~/views/Notices/store/actions'

export default function getData(resolve, redirect, { match, history }) {
  return () => {
    const dispatch = useDispatch()

    useEffect(() => {
      async function getData() {
        const notice = await getNotice(match.params.id)
        const subscription = await getSubscriptionByNoticeId(match.params.id)
        if (!notice) return history.push('/home/minhas-inscricoes')
        await dispatch(setNotice(notice))
        await dispatch(getSubscriptionForm(notice && notice.id))
        return await dispatch(setSubscription(subscription))
      }

      getData()
    }, [dispatch])

    return resolve()
  }
}
