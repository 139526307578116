import { handleActions, combineActions } from 'redux-actions'
import types from './types'

import { treatInitialState } from '../services/helpers'

const INITIAL_STATE = {
  isLoading: true,
  pendingUpdatesCount: 0,
  form: {
    groups: [],
  },
  questions: [],
}

const beginLoading = combineActions(
  types.LOAD_SUBSCRIPTION_FORM_QUESTIONS,
  types.LOAD_EVALUATION_CRITERIA_FORM,
  types.UPDATE_EVALUATION_CRITERIA_FORM
)

const stopLoading = combineActions(
  types.LOAD_SUBSCRIPTION_FORM_QUESTIONS_SUCCESS,
  types.LOAD_SUBSCRIPTION_FORM_QUESTIONS_FAIL,
  types.LOAD_EVALUATION_CRITERIA_FORM_SUCCESS,
  types.LOAD_EVALUATION_CRITERIA_FORM_FAIL,
  types.UPDATE_EVALUATION_CRITERIA_FORM_SUCCESS,
  types.UPDATE_EVALUATION_CRITERIA_FORM_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_SUBSCRIPTION_FORM_QUESTIONS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      questions: data,
      isLoading: false,
    }),
    [types.LOAD_EVALUATION_CRITERIA_FORM_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      form: treatInitialState(data),
      isLoading: false,
    }),
    [types.UPDATE_EVALUATION_CRITERIA_FORM]: (state) => {
      return {
        ...state,
        pendingUpdatesCount: state.pendingUpdatesCount + 1,
      }
    },
    [types.UPDATE_EVALUATION_CRITERIA_FORM_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => {
      const updatedPendingUpdatesCount =  state.pendingUpdatesCount - 1

      return {
        ...state,
        form: updatedPendingUpdatesCount > 0 ? treatInitialState(state.form) : treatInitialState(data),
        isLoading: false,
        pendingUpdatesCount: updatedPendingUpdatesCount,
      }
    },
    [types.SET_EVALUATION_CRITERIA_FORM]: (state, { form }) => ({
      ...state,
      form: form,
    }),
    [types.CLEAR_STATE]: () => {
      return { ...INITIAL_STATE }
    },
  },
  INITIAL_STATE
)

export default reducer
