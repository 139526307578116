import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useOutsideClick from '~/hooks/useOutsideClick'

import { T4 } from '~/components'

import colors from '~/utils/colors'

import { Button, InputStyle, LabelStyle, List, ContentStyle } from './styles'

function Picker(props) {
  const wrapperRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState('')
  useOutsideClick(wrapperRef, () => setIsFocused(false))

  useEffect(() => setInputValue(props.value), [props.value])

  return (
    <ContentStyle
      ref={wrapperRef}
      className={classnames(props.className, 'picker')}
      aria-haspopup="true"
      aria-expanded={isFocused}
    >
      {props.labelInfo ||
        (props.label && (
          <LabelStyle htmlFor={props.id} labelSpace={props.labelSpace}>
            {props.label}
          </LabelStyle>
        ))}
      <InputStyle
        id={props.id}
        name={props.name}
        aria-label={props['aria-label']}
        placeholder={props.placeholder || 'Clique para selecionar'}
        autoComplete="off"
        onFocus={({ target: { value } }) => {
          props.onFocus && props.onFocus(value)
          setIsFocused(true)
        }}
        readOnly={props.readOnly || !props.onChange}
        disabled={props.disabled}
        onBlur={() => {
          setInputValue(props.value)
        }}
        onChange={({ target: { value } }) => {
          props.onChange && props.onChange(value)
          setInputValue(value)
        }}
        value={inputValue}
      />
      {props.data && props.data.length && isFocused ? (
        <List aria-labelledby={props.id} isOpen={isFocused}>
          <ul>
            {props.data.map((child, key) => (
              <li
                key={key}
                onClick={() => {
                  if (props.onSelect) props.onSelect(child, key)
                  setInputValue(child)
                  setIsFocused(false)
                }}
              >
                <Button active={null}>
                  <T4 color={colors.darkGray}>{child}</T4>
                </Button>
              </li>
            ))}
          </ul>
        </List>
      ) : null}
    </ContentStyle>
  )
}

Picker.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelInfo: PropTypes.element,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  data: PropTypes.array,
  labelSpace: PropTypes.number,
}

export default Picker
