import { handleActions, combineActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: false,
  introPage: {
    id: null,
    noticeId: null,
    active: false,
    introText: '',
    content: '',
    includeAutoWarning: false,
    headerFileId: null,
    File: {
      id: null,
      name: '',
    },
  },
}

const beginLoading = combineActions(
  types.LOAD_NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE
)

const stopLoading = combineActions(
  types.LOAD_NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE_SUCCESS,
  types.LOAD_NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE_FAIL,
  types.UPDATE_NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE_SUCCESS,
  types.UPDATE_NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      introPage: data,
      isLoading: false,
    }),
    [types.UPDATE_NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      introPage: data,
      isLoading: false,
    }),
    [types.SET_NOTICE_SUBSCRIPTION_FORM_INTRO_PAGE]: (
      state,
      { introPage }
    ) => ({
      ...state,
      introPage,
    }),
    [types.CLEAR_STATE]: () => {
      return { ...INITIAL_STATE }
    },
  },
  INITIAL_STATE
)

export default reducer
