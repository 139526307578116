import React, { memo, useCallback, useMemo, useState } from 'react'
import queryString from 'query-string'

function Filter({
  history,
  children,
  paramName,
  extraParams = {},
  resetPageIndexSortyBy = true,
}) {
  const [params, setParams] = useState(
    queryString.parse(history.location.search) || {}
  )

  const setParam = useCallback(
    (value) => {
      if (
        paramName !== 'pageIndex' &&
        paramName !== 'sortBy' &&
        resetPageIndexSortyBy
      ) {
        setParams((prev) => ({
          ...prev,
          ...extraParams,
          pageIndex: null,
          sortBy: null,
        }))
      }

      setParams((prev) => ({ ...prev, ...extraParams, [paramName]: value }))
    },
    [paramName, setParams, resetPageIndexSortyBy, extraParams]
  )

  const search = useCallback(
    (cb = () => {}, newParams = {}) => {
      let finalParams = {}

      if (resetPageIndexSortyBy) {
        finalParams = {
          ...params,
          ...extraParams,
          ...newParams,
          pageIndex: null,
          sortBy: null,
        }
      } else {
        finalParams = { ...params, ...extraParams, ...newParams }
      }

      const newSearch = queryString.stringify(finalParams, {
        arrayFormat: 'comma',
        skipNull: true,
        skipEmptyString: true,
      })

      history.push({
        search: newSearch,
      })

      if (cb) {
        cb(finalParams)
      }
    },
    [history, params, extraParams, resetPageIndexSortyBy]
  )

  const childrenProps = useMemo(
    () => ({
      param: params[paramName],
      setParam,
      search,
    }),
    [params, paramName, setParam, search]
  )

  return typeof children === 'function' ? <>{children(childrenProps)}</> : null
}

export default memo(Filter)
