import styled from 'styled-components'

export const Content = styled.h1`
  font-family: ${({ font }) => font};
  color: ${({ color }) => color};
  font-size: 46px;
  line-height: 59px;
  min-height: 59px;
  margin: 0;
`
