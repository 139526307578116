import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTable, usePagination } from 'react-table'

import {
  TableStyle,
  TBodyRow,
  TData,
  THeader,
  TableWrap,
  THeadRow,
  THead,
  TBody,
  TDLoading,
} from './styles'

import getHeaderProps from './services/getHeaderProps'
import getCellProps from './services/getCellProps'

import Pagination from './components/Pagination'

function Table({
  data,
  columns,
  hasPagination,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  pageSize: initialPageSize,
  ...props
}) {
  /*

  */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: hasPagination && initialPageSize,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    hasPagination && usePagination
  )

  useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize })
    }
  }, [fetchData, pageIndex, pageSize])

  return (
    <>
      <TableWrap {...props}>
        <TableStyle {...getTableProps()}>
          <THead>
            {headerGroups.map((headerGroup) => (
              <THeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <THeader {...getHeaderProps(column)}>
                    {column.render('Header')}
                  </THeader>
                ))}
              </THeadRow>
            ))}
          </THead>
          <TBody {...getTableBodyProps()}>
            {(hasPagination ? page : rows).map((row) => {
              prepareRow(row)
              return (
                <TBodyRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TData {...getCellProps(cell)}>
                        {cell.render('Cell')}
                      </TData>
                    )
                  })}
                </TBodyRow>
              )
            })}
            {loading && (
              <TBodyRow>
                <TDLoading colSpan="10000">Carregando...</TDLoading>
              </TBodyRow>
            )}
          </TBody>
        </TableStyle>
      </TableWrap>
      {hasPagination && (
        <Pagination
          {...{
            gotoPage,
            previousPage,
            canPreviousPage,
            nextPage,
            pageCount,
            canNextPage,
            pageOptions,
            pageIndex,
            setPageSize,
            pageSize,
          }}
        />
      )}
    </>
  )
}

Table.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  hasPagination: PropTypes.bool,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
}

Table.defaultProps = {
  hasPagination: false,
  pageCount: 0,
  pageSize: 10,
}

export default Table
