import React, { Children, cloneElement, useCallback, useState } from 'react'
import queryString from 'query-string'

import { FilterIcon, FilterLightIcon, TrashIcon } from '~/styles/Icons'
import { Button, Inline, Modal } from '~/components'

import Filter from './components/Filter'

function ModalFilters({
  isOpen,
  toggle,
  title,
  children,
  extraParams = {},
  onConfirm = () => {},
  onClean = () => {},
  history,
}) {
  const [params, setParams] = useState(
    queryString.parse(history.location.search) || {}
  )

  const onConfirmCb = useCallback(() => {
    const searchParams = {
      ...params,
      ...extraParams,
      pageIndex: null,
      sortBy: null,
    }

    const newSearch = queryString.stringify(searchParams, {
      arrayFormat: 'comma',
      skipNull: true,
      skipEmptyString: true,
    })
    if (`?${newSearch}` !== history.location.search) {
      history.push({
        search: newSearch,
      })
    }

    onConfirm(params)
    toggle()
  }, [params, onConfirm, toggle, history, extraParams])

  const onCleanCb = useCallback(() => {
    if (`` !== history.location.search) {
      history.push({
        search: '',
      })
    }

    onClean()
    toggle()
  }, [onClean, toggle, history])

  return (
    <Modal id="modal-filters" lg isOpen={isOpen} toggle={toggle}>
      <Modal.Header type="normal" title={title} icon={FilterLightIcon} />

      <Modal.Body>
        {Children.map(children, (child, key) => {
          return cloneElement(child, {
            params,
            setParams,
          })
        })}
      </Modal.Body>

      <Modal.Footer className="pt-0">
        <Inline right={30} mobileCenter>
          <Button
            aria-label="Concluir"
            icon={FilterLightIcon}
            iconHover={FilterIcon}
            size={1}
            className="mt-1 block-sm block-xs"
            buttonClassName="block-sm block-xs"
            onClick={onConfirmCb}
          >
            Aplicar filtros
          </Button>

          <Button
            aria-label="Limpar tudo"
            outlined
            icon={TrashIcon}
            iconHover={TrashIcon}
            size={1}
            className="mt-1 block-sm block-xs"
            buttonClassName="block-sm block-xs"
            onClick={onCleanCb}
          >
            Limpar tudo
          </Button>
        </Inline>
      </Modal.Footer>
    </Modal>
  )
}

ModalFilters.Filter = Filter

export default ModalFilters
