import styled from 'styled-components'


export const FilterStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 5px solid var(--color-light);
  border-radius: 10px;
  margin-bottom: 20px;
`

export const FilterHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px 50px;
`
