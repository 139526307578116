import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD_NOTICE_PROCESS_STEPS'),
    ...async('UPDATE_NOTICE_PROCESS_STEPS'),
    'CLEAR_STATE',
  ],
  'NOTICE_PROCESS_STEPS'
)
