import { useEffect } from 'react'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

export default function hasEvaluations(resolve, redirect, { match, history }) {
  return () => {
    const notice = useShallowEqualSelector((state) => state.notice.notice)
    useEffect(() => {
      if (notice.id) {
        if (notice?.evaluations?.length) {
          history.push('/home/meus-editais')
        }
      }
    }, [notice])

    return resolve()
  }
}
