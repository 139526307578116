import { handleActions, combineActions } from 'redux-actions'
import types from './types'

import { treatInitialState } from '../services/helpers'

const INITIAL_STATE = {
  isLoading: true,
  pendingUpdatesCount: 0,
  noticeQuestionnaire: [],
}

const beginLoading = combineActions(
  types.LOAD_NOTICE_QUESTIONNAIRE,
  types.UPDATE_NOTICE_QUESTIONNAIRE
)

const stopLoading = combineActions(
  types.LOAD_NOTICE_QUESTIONNAIRE_SUCCESS,
  types.LOAD_NOTICE_QUESTIONNAIRE_FAIL,
  types.UPDATE_NOTICE_QUESTIONNAIRE_SUCCESS,
  types.UPDATE_NOTICE_QUESTIONNAIRE_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_NOTICE_QUESTIONNAIRE_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      noticeQuestionnaire: treatInitialState(data),
      isLoading: false,
    }),
    [types.UPDATE_NOTICE_QUESTIONNAIRE_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => {
      const updatedPendingUpdatesCount =  state.pendingUpdatesCount - 1

      return {
        ...state,
        noticeQuestionnaire: updatedPendingUpdatesCount > 0 ? treatInitialState(state.noticeQuestionnaire) : treatInitialState(data),
        isLoading: false,
        pendingUpdatesCount: updatedPendingUpdatesCount,
      }
    },
    [types.UPDATE_NOTICE_QUESTIONNAIRE]: (state) => {
      return {
        ...state,
        pendingUpdatesCount: state.pendingUpdatesCount + 1,
      }
    },
    [types.SET_NOTICE_QUESTIONNAIRE]: (state, { questionnaire }) => ({
      ...state,
      noticeQuestionnaire: questionnaire,
    }),
    [types.CLEAR_STATE]: () => {
      return { ...INITIAL_STATE }
    },
  },
  INITIAL_STATE
)

export default reducer
