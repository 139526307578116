import React from 'react'
import getNotice from './middlewares/getNotice'
import getSubscription from './middlewares/getSubscription'
import isEvaluator from './middlewares/isEvaluator'

const ParticipantData = React.lazy(() => import('./subpages/ParticipantData'))
const Evaluation = React.lazy(() => import('./subpages/Evaluation'))
const NoticeData = React.lazy(() => import('./subpages/NoticeData'))
const SubscriptionForm = React.lazy(() => import('./subpages/SubscriptionForm'))
const PreviewEvaluation = React.lazy(() =>
  import('./subpages/PreviewEvaluation')
)

const noticeEvaluationRoutes = [
  {
    path: '/editais/:id/avaliacao/:subscriptionId/dados-do-participante',
    component: ParticipantData,
    exact: true,
    middlewares: [getNotice, isEvaluator, getSubscription],
  },
  {
    path: '/editais/:id/avaliacao/:subscriptionId/formulario-de-inscricao',
    component: SubscriptionForm,
    exact: true,
    middlewares: [getNotice, isEvaluator, getSubscription],
  },
  {
    path: '/editais/:id/avaliacao/:subscriptionId/avaliacao',
    component: Evaluation,
    exact: true,
    middlewares: [getNotice, isEvaluator, getSubscription],
  },
  {
    path: '/editais/:id/avaliacao/visualizar-formulario',
    component: PreviewEvaluation,
    exact: true,
    middlewares: [getNotice],
  },
  {
    path: '/editais/:id/avaliacao/:subscriptionId/dados-edital',
    component: NoticeData,
    exact: true,
    middlewares: [getNotice, isEvaluator, getSubscription],
  },
]

export default noticeEvaluationRoutes
