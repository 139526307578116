import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

import * as requests from '../services/requests'

import { setSubscription } from '../store/actions'

export default function getSubscription(resolve, redirect, { match, history }) {
  return () => {
    const dispatch = useDispatch()
    const user = useShallowEqualSelector((state) => state.profile.user)

    useEffect(() => {
      async function findSubscription() {
        if (user && user.id) {
          const subscription = await requests.getSubscription(
            match.params.subscriptionId,
            match.params.id
          )
          if (!subscription) return history.push('/home/meus-editais')
          dispatch(setSubscription(subscription))
        }
      }

      findSubscription()

      return () => dispatch(setSubscription(null))
    }, [dispatch, user])

    return resolve()
  }
}
