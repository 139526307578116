import React from 'react'

import { Button, Inline, Modal, T1 } from '~/components'
import { ArrowLineLeftIcon, ArrowLineLeftLightIcon } from '~/styles/Icons'
import colors from '~/utils/colors'

function ModalInfo({ isOpen, toggle, message, title }) {
  return (
    <Modal id="modal-info" md isOpen={isOpen} toggle={toggle}>
      <Modal.Header type="normal" title={title} />

      <Modal.Body>
        <T1 color={colors.darkGray} className="mb-5 text-center">
          {message}
        </T1>
      </Modal.Body>

      <Modal.Footer>
        <Inline right={10}>
          <Button
            aria-label="Concluir"
            className="mb-3"
            onClick={toggle}
            icon={ArrowLineLeftLightIcon}
            iconHover={ArrowLineLeftIcon}
          >
            Concluir
          </Button>
        </Inline>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalInfo
