import React from 'react'
import PropTypes from 'prop-types'

import { CountStyle, CircleStyle, TextStyle } from './styles'
import colors from '~/utils/colors'
import fonts from '~/utils/fonts'

function Count({
  count,
  text,
  size,
  countSize,
  color,
  textFont,
  textSize,
  textLineHeight,
  textColor,
  ...props
}) {
  return (
    <CountStyle {...props}>
      <CircleStyle className='icon' size={size} color={color} countSize={countSize}>
        {count}
      </CircleStyle>
      {text && (
        <TextStyle
          textFont={textFont}
          textSize={textSize}
          textLineHeight={textLineHeight}
          textColor={textColor}
        >
          {text}
        </TextStyle>
      )}
    </CountStyle>
  )
}

Count.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  size: PropTypes.number,
  countSize: PropTypes.number,
  textFont: PropTypes.string,
  textSize: PropTypes.number,
  textLineHeight: PropTypes.number,
  textColor: PropTypes.string,
  text: PropTypes.string,
}

Count.defaultProps = {
  count: 0,
  color: colors.white,
  size: 30,
  textFont: fonts.textRegular,
  textSize: 14,
  textLineHeight: 18,
  textColor: colors.dark,
}

export default Count
