import { handleActions, combineActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  users: [],
}

const beginLoading = combineActions(types.LOAD)

const stopLoading = combineActions(types.LOAD_SUCCESS, types.LOAD_FAIL)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.SEARCH_USERS]: (state, { users }) => ({
      ...state,
      users,
    }),
    [types.LOAD_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      data,
    }),
    [types.SEND_DRAFT_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      data,
    }),
    [types.SET_DRAFT]: (state, { data }) => ({
      ...state,
      data,
    }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
