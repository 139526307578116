import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {TabLinksStyle, TabLinkStyle} from './styles'

function TabLinks(props) {
  return <TabLinksStyle {...props} />
}

TabLinks.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  light: PropTypes.bool,
  center: PropTypes.bool,
  mobileCenter: PropTypes.bool,
}

function TabLink(props) {
  return (
    <TabLinkStyle
      {...props}
      heightspan={props.heightspan}
      className={classnames(
        props.className,
        { active: props.active },
        { hover: props.hover }
      )}
      activeClassName="active"
      hover={null}
      active={null}
    >
      {props.children}
      <span />
    </TabLinkStyle>
  )
}

TabLink.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  hover: PropTypes.bool,
  active: PropTypes.bool,
  heightspan: PropTypes.number,
}

TabLinks.Link = TabLink

export default TabLinks
