const getOccupationUserRoute = (user) => {
  if(user.noticesOccupations.length !== 0) {
    return user.noticesOccupations[0]
  } else {
    if (user.profileType === 'PARTICIPANT') {
      if (user.evaluations.length !== 0) {
        return 'EVALUATOR'
      } else {
        return 'PARTICIPANT'
      }
    } else {
      return user.profileType === 'ADMIN' ? 'ADMIN' : user.noticesOccupations[0]
    }
  }
}

export { getOccupationUserRoute }
