import { async, types } from '~/utils/typeCreator'

export default types(
  [
    ...async('GET_BRAZIL_REGIONS'),
    ...async('GET_PROCESS_STEPS'),
    ...async('GET_SUBSCRIPTION_FORM_QUESTIONS'),
    ...async('GET_EVALUATORS'),
    ...async('SET_EVALUATORS'),
    ...async('SAVE_EVALUATORS'),
    ...async('SEARCH_USERS'),
    ...async('FILTER_EVALUATORS'),
    ...async('FILTER_SELECTED_EVALUATORS'),
    ...async('CLEAR_SELECTED_EVALUATORS'),
    ...async('GET_NOTICE_PROCESS_STEPS'),
    ...async('GET_SUBSCRIPTION_STEPS_STATUS'),
    'CLEAR_STATE',
  ],
  'NOTICE_MANAGEMENT_STEPS_MANAGEMENT'
)
