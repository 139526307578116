import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import colors from '~/utils/colors'
import { ArrowDownIcon } from '~/styles/Icons'

import { DropdownIndicatorStyle, LabelStyle } from './styles'
import classNames from 'classnames'

const DropdownIndicator = () => {
  return <DropdownIndicatorStyle src={ArrowDownIcon} alt="" aria-hidden />
}

const customStyles = (props, listHeight) => ({
  control: (base, state) => ({
    ...base,
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: colors.light,
    minHeight: '50px',
    paddingLeft: '20px',
    borderRadius: '0',
    '&:hover': {
      borderColor: colors.light,
    },
    fontSize: '16px',
    lineHeight: '21px',
    fontFamily: 'Itau Text Regular',
    color: colors.dark,
    backgroundColor: state.isDisabled ? 'rgba(243, 237, 223, 0.7)' : null,
    ...props.style,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),
  singleValue: (base) => ({
    ...base,
    margin: 0,
    color: colors.dark,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '16px',
    lineHeight: '21px',
    fontFamily: 'Itau Text Regular',
    color: colors.gray,
    margin: 0,
  }),
  menu: (base) => ({
    ...base,
    background: colors.light,
    marginTop: 0,
    borderRadius: '0 0 10px 10px',
    padding: '12px 5px 31px 5px',
    boxShadow: 'none',
  }),
  menuList: (base) => ({
    ...base,
    height: listHeight ? `${listHeight}px` : null,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    width: 0,
  }),
  option: (base, state) => ({
    ...base,
    fontSize: '14px',
    lineHeight: '18px',
    color: colors.dark,
    fontFamily: 'Itau Text Regular',
    padding: '8px 15px 9px 15px',
    borderRadius: '10px',
    marginBottom: '5px',

    backgroundColor: state.isDisabled
      ? colors.light
      : state.isSelected
      ? colors.yellow
      : state.isFocused
      ? colors.yellow
      : colors.light,

    opacity: state.isDisabled
      ? 0.6
      : state.isFocused
      ? state.isSelected
        ? 1
        : 0.6
      : 1,

    '&:hover': {
      background: state.isDisabled ? colors.light : colors.yellow,
    },
  }),
})

function Select(props) {
  return (
    <>
      {props.label && (
        <LabelStyle htmlFor={props.id} labelSpace={props.labelSpace}>
          {props.label}
        </LabelStyle>
      )}
      <ReactSelect
        {...props}
        className={classNames(props.className)}
        classNamePrefix="select"
        isDisabled={props.disabled}
        styles={customStyles(props, props.listHeight)}
        components={{ DropdownIndicator }}
      />
    </>
  )
}

Select.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  labelSpace: PropTypes.number,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.object,
  defaultMenuIsOpen: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  listHeight: PropTypes.number,
}

Select.defaultProps = {
  placeholder: 'Selecione',
  isSearchable: false,
  noOptionsMessage: () => 'Nenhuma opção',
}

export default memo(Select)
