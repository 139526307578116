import styled, { css } from 'styled-components'

import { Link } from 'react-router-dom'

export const LinkStyle = styled(Link)``

const buttonCSS = css`
    width: fit-content;

    ${({ block }) => block && `width:100%;`}

  > button, > ${LinkStyle}, > a {

    width: fit-content;
    ${({ block }) => block && `width:100%;`}
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Itau Display Regular';

    ${({ light }) =>
      light
        ? `
          color: var(--color-dark);
          background: var(--color-yellow);
          `
        : `
          color: var(--color-white);
          background: var(--color-dark);
          `}


    ${({ outlined }) =>
      outlined &&
      `
      color: var(--color-dark);
      background: var(--color-white);
      border: 5px solid var(--color-light);
      `}

    ${({ size, icon, iconHover, right, outlined }) =>
      size === 1
        ? `
          min-height:60px;
          font-size: 16px;
          line-height: 21px;
          ${
            (icon && iconHover) || icon || (icon && outlined)
              ? !right
                ? !outlined
                  ? 'padding: 15px 30px 15px 20px;'
                  : 'padding: 10px 25px 10px 15px;'
                : !outlined
                ? 'padding: 15px 20px 15px 30px;'
                : 'padding: 10px 15px 10px 25px;'
              : 'padding: 15px 30px;'
          }
          `
        : size === 2
        ? `
          min-height:40px;
          font-size: 16px;
          line-height: 21px;
          ${
            (icon && iconHover) || icon || (icon && outlined)
              ? !right
                ? !outlined
                  ? 'padding: 10px 20px 10px 17px;'
                  : 'padding: 5px 15px 5px 12px;'
                : !outlined
                ? 'padding: 10px 17px 10px 20px;'
                : 'padding: 5px 12px 5px 15px;'
              : 'padding: 10px 20px;'
          }
          `
        : size === 3 &&
          `
          min-height:40px;
          font-size: 14px;
          line-height: 18px;
          ${
            (icon && iconHover) || icon || (icon && outlined)
              ? !right
                ? !outlined
                  ? 'padding: 8px 20px 8px 10px;'
                  : 'padding: 3px 15px 3px 5px;'
                : !outlined
                ? 'padding: 8px 10px 8px 20px;'
                : 'padding: 3px 5px 3px 15px;'
              : 'padding: 8px 10px;'
          }
          `}

      > img {
      display: block;

      ${({ size, right, onlyIcon }) =>
        size === 1
          ? `
            height: 30px;
            width: 30px;
            ${
              onlyIcon
                ? ''
                : !right
                ? 'margin-right: 10px;'
                : 'margin-left: 10px;'
            }
            `
          : size === 2
          ? `
            height: 30px;
            width: 30px;
            ${
              onlyIcon
                ? ''
                : !right
                ? 'margin-right: 10px;'
                : 'margin-left: 10px;'
            }
            `
          : size === 3 &&
            `
            height: 24px;
            width: 24px;
            ${
              onlyIcon
                ? ''
                : !right
                ? 'margin-right: 5px;'
                : 'margin-left: 5px;'
            }
            `}

      :nth-child(1) {
        display: block;
      }

      :nth-child(2) {
        display: none;
      }


    }

    ${({ color }) =>
      color &&
      `
      background: ${color};
    `}

    &:hover,
    &:focus,
    &.active,
    &.hover {
      text-decoration: none;

      ${({ light, ignoreHover }) =>
        !ignoreHover && light
          ? `
            color: var(--color-white);
            background: var(--color-dark);
            `
          : `
            color: var(--color-dark);
            background: var(--color-yellow);
            `}


      ${({ outlined, ignoreHover }) =>
        !ignoreHover &&
        outlined &&
        `
        color: var(--color-dark);
        background: var(--color-yellow);
        border-color: var(--color-yellow);
        `}


      > img {
        ${({ outlined, ignoreHover }) =>
          !ignoreHover &&
          !outlined &&
          `
          :nth-child(1) {
            display: none;
          }

          :nth-child(2) {
            display: block;
          }
          `}
      }
    }

    :disabled {
      opacity: 0.5;
    }
  }
`

export const ButtonStyle = styled.div`
  ${buttonCSS}
`

export const ButtonLinkStyle = styled.div`
  ${buttonCSS}
`

export const ButtonLinkExternalStyle = styled.div`
  ${buttonCSS}
`
