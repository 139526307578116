import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
  Content,
  List,
  MainButton,
  LinkStyle,
  ExternalLinkStyle,
  ButtonStyle,
} from './styles'

function Dropdown({ id, buttonStyle, listStyle, ...props }) {
  const [isOpen, setIsOpen] = useState(props.active || false)
  return (
    <Content {...props}>
      <MainButton
        onMouseLeave={() => setIsOpen(false)}
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        width={props.width}
        right={props.right}
        children={props.children}
        icon={props.icon}
        aria-label={props['aria-label']}
        aria-haspopup="true"
        aria-expanded={isOpen}
        id={id}
        type="button"
        style={buttonStyle}
      >
        {props.right && props.label}
        {props.icon && <img src={props.icon} aria-hidden alt="" />}
        {!props.right && props.label}
        <span></span>
      </MainButton>

      <List
        aria-labelledby={id}
        isOpen={isOpen}
        width={props.width}
        onMouseLeave={() => setIsOpen(false)}
        onMouseEnter={() => setIsOpen(true)}
        style={listStyle}
      >
        <ul>
          {props.children ? (
            Array.isArray(props.children) ? (
              props.children.map((child, key) => <li key={key}>{child}</li>)
            ) : (
              <li>{props.children}</li>
            )
          ) : null}
        </ul>
      </List>
    </Content>
  )
}

Dropdown.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  right: PropTypes.bool,
  active: PropTypes.bool,
}

function Link(props) {
  return (
    <LinkStyle
      {...props}
      className={classnames(props.className, { active: props.active })}
      active={null}
    >
      {props.icon && !props.right && (
        <>
          <img src={props.icon} alt="" aria-hidden />
          <img src={props.iconhover} alt="" aria-hidden />
        </>
      )}
      {props.children}
      {props.icon && props.right && (
        <>
          <img src={props.icon} alt="" aria-hidden />
          <img src={props.iconhover} alt="" aria-hidden />
        </>
      )}
    </LinkStyle>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  active: PropTypes.bool,
  icon: PropTypes.string,
  iconhover: PropTypes.string,
  right: PropTypes.bool,
}

function ExternalLink(props) {
  return (
    <ExternalLinkStyle
      {...props}
      className={classnames(props.className, { active: props.active })}
      active={null}
    >
      {props.icon && !props.right && (
        <>
          <img src={props.icon} alt="" aria-hidden />
          <img src={props.iconHover} alt="" aria-hidden />
        </>
      )}
      {props.children}
      {props.icon && props.right && (
        <>
          <img src={props.icon} alt="" aria-hidden />
          <img src={props.iconHover} alt="" aria-hidden />
        </>
      )}
    </ExternalLinkStyle>
  )
}

ExternalLink.propTypes = {
  active: PropTypes.bool,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  icon: PropTypes.string,
  iconHover: PropTypes.string,
  right: PropTypes.bool,
}

function Button(props) {
  return (
    <ButtonStyle
      {...props}
      className={classnames(props.className, { active: props.active })}
      active={null}
    >
      {props.icon && !props.right && (
        <>
          <img src={props.icon} alt="" aria-hidden />
          <img src={props.iconHover} alt="" aria-hidden />
        </>
      )}
      {props.children}
      {props.icon && props.right && (
        <>
          <img src={props.icon} alt="" aria-hidden />
          <img src={props.iconHover} alt="" aria-hidden />
        </>
      )}
    </ButtonStyle>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  icon: PropTypes.string,
  iconHover: PropTypes.string,
  right: PropTypes.bool,
}

Dropdown.Link = memo(Link)
Dropdown.ExternalLink = memo(ExternalLink)
Dropdown.Button = memo(Button)

export default Dropdown
