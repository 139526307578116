import styled from 'styled-components'


export const Content = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-height: 30px;
  border-radius: 15px;
  padding: 5px 10px 5px 20px;
  cursor: pointer;

  text-decoration: none;
  font-family: 'Itau Display Regular';
  font-size: 14px;
  line-height: 18px;
  color: var(--color-dark);
  background: var(--color-light);

  > img {
    display: block;
    height: 20px;
    width: 20px;
    margin-left: 5px;
  }

  &:hover,
  &:focus,
  &.active,
  &.hover {
    text-decoration: none;
    background: var(--color-yellow);
  }
`
