import { useEffect } from 'react'
import loadOrCreateForm from '../services/loadOrCreateForm'

export default function loadOrCreateEvaluationCriteriaForm(
  resolve,
  redirect,
  { match }
) {
  return () => {
    useEffect(() => {
      loadOrCreateForm(match.params.id)
    }, [])

    return resolve()
  }
}
