import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
  ButtonStyle,
  ButtonLinkStyle,
  ButtonLinkExternalStyle,
  LinkStyle,
} from './styles'

function Button(props) {
  return (
    <ButtonStyle
      className={classnames(props.className, { active: props.active }, 'accessibility-button')}
      style={props.style}
      icon={props.icon}
      iconHover={props.iconHover}
      right={props.right}
      light={props.light}
      size={props.size}
      outlined={props.outlined}
      active={props.active}
      ignoreHover={props.ignoreHover}
      block={props.block}
      color={props.color}
      onlyIcon={props.onlyIcon}
    >
      <button
        onClick={props.onClick}
        disabled={props.disabled}
        type={props.type}
        aria-label={props['aria-label']}
        style={props.buttonStyle}
        className={props.buttonClassName}
      >
        {props.right && props.children}
        {((props.icon && props.iconHover) ||
          (props.icon && props.outlined) ||
          (props.icon && props.ignoreHover)) && (
          <>
            <img src={props.icon} aria-hidden alt="" />
            {!props.ignoreHover && (
              <img src={props.iconHover} aria-hidden alt="" />
            )}
          </>
        )}
        {!props.right && props.children}
      </button>
    </ButtonStyle>
  )
}

function ButtonLink(props) {
  return (
    <ButtonLinkStyle
      className={classnames(props.className, { active: props.active }, 'accessibility-button')}
      style={props.style}
      icon={props.icon}
      iconHover={props.iconHover}
      right={props.right}
      light={props.light}
      size={props.size}
      outlined={props.outlined}
      active={props.active}
      ignoreHover={props.ignoreHover}
      block={props.block}
      color={props.color}
    >
      <LinkStyle
        to={props.to}
        onClick={props.onClick}
        disabled={props.disabled}
        type={props.type}
        aria-label={props['aria-label']}
        style={props.buttonStyle}
        className={props.buttonClassName}
      >
        {props.right && props.children}
        {((props.icon && props.iconHover) ||
          (props.icon && props.outlined) ||
          (props.icon && props.ignoreHover)) && (
          <>
            <img src={props.icon} aria-hidden alt="" />
            {!props.ignoreHover && (
              <img src={props.iconHover} aria-hidden alt="" />
            )}
          </>
        )}
        {!props.right && props.children}
      </LinkStyle>
    </ButtonLinkStyle>
  )
}

function ButtonLinkExternal(props) {
  return (
    <ButtonLinkExternalStyle
      className={classnames(props.className, { active: props.active })}
      style={props.style}
      icon={props.icon}
      iconHover={props.iconHover}
      right={props.right}
      light={props.light}
      size={props.size}
      outlined={props.outlined}
      active={props.active}
      ignoreHover={props.ignoreHover}
      block={props.block}
      color={props.color}
    >
      <a
        href={props.href}
        onClick={props.onClick}
        disabled={props.disabled}
        type={props.type}
        aria-label={props['aria-label']}
        style={props.buttonStyle}
        className={props.buttonClassName}
      >
        {props.right && props.children}
        {((props.icon && props.iconHover) ||
          (props.icon && props.outlined) ||
          (props.icon && props.ignoreHover)) && (
          <>
            <img src={props.icon} aria-hidden alt="" />
            {!props.ignoreHover && (
              <img src={props.iconHover} aria-hidden alt="" />
            )}
          </>
        )}
        {!props.right && props.children}
      </a>
    </ButtonLinkExternalStyle>
  )
}

Button.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  iconHover: PropTypes.string,
  icon: PropTypes.string,
  light: PropTypes.bool,
  size: PropTypes.oneOf([1, 2, 3]),
  right: PropTypes.bool,
  outlined: PropTypes.bool,
  active: PropTypes.bool,
  ignoreHover: PropTypes.bool,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.object,
  block: PropTypes.bool,
  color: PropTypes.string,
  buttonClassName: PropTypes.string,
  onlyIcon: PropTypes.bool,
}

ButtonLink.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  to: PropTypes.string.isRequired,
  children: PropTypes.string,
  iconHover: PropTypes.string,
  icon: PropTypes.string,
  light: PropTypes.bool,
  size: PropTypes.oneOf([1, 2, 3]),
  right: PropTypes.bool,
  outlined: PropTypes.bool,
  active: PropTypes.bool,
  ignoreHover: PropTypes.bool,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.object,
  block: PropTypes.bool,
  color: PropTypes.string,
  buttonClassName: PropTypes.string,
}

ButtonLinkExternal.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  href: PropTypes.string.isRequired,
  children: PropTypes.string,
  iconHover: PropTypes.string,
  icon: PropTypes.string,
  light: PropTypes.bool,
  size: PropTypes.oneOf([1, 2, 3]),
  right: PropTypes.bool,
  outlined: PropTypes.bool,
  active: PropTypes.bool,
  ignoreHover: PropTypes.bool,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.object,
  block: PropTypes.bool,
  color: PropTypes.string,
  buttonClassName: PropTypes.string,
}

Button.defaultProps = {
  size: 1,
  type: 'button',
}

ButtonLink.defaultProps = {
  size: 1,
}

ButtonLinkExternal.defaultProps = {
  size: 1,
}

export { Button, ButtonLink, ButtonLinkExternal }
