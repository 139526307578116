import { useMemo } from 'react'

export default function useShouldShowNoticesTab({ profile }) {
  return useMemo(() => {
    const isAdmin = profile?.profileType === 'ADMIN'

    const isManager = profile?.noticesOccupations?.includes('MANAGER')
    const isConsultant = profile?.noticesOccupations?.includes('CONSULTANT')

    return isAdmin || isManager || isConsultant
  }, [profile])
}
