import React from 'react'
import PropTypes from 'prop-types'

import fonts from '~/utils/fonts'
import colors from '~/utils/colors'

import { Content } from './styles'

function H3(props) {
  return <Content {...props} />
}

H3.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  font: PropTypes.string,
  color: PropTypes.string,
}

H3.defaultProps = {
  fonts: fonts.displayRegular,
  color: colors.dark,
}

export default H3
