import styled from 'styled-components'
import colors from '~/utils/colors'

export const ModalStyle = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 50px 0;
  background: var(--color-dark);
  overflow: auto;
`

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ModalContent = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(102, 102, 102, 0.05);
  background: var(--color-white);
  padding-bottom: 30px;
  ${({ lg, md }) => (md && `width: 870px;`) || (lg && `width: 100%;`)};
`

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 50px;
  border-radius: 10px 10px 0 0;

  background: ${({ type }) =>
    (type === 'normal' && colors.yellow) ||
    (type === 'warning' && colors.orange) ||
    (type === 'danger' && colors.red)};

  @media (max-width: 510px) {
    padding: 20px 20px;
  }
`

export const HeaderIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: var(--color-dark);
  border-radius: 50px;

  ${({ noIcon }) =>
    noIcon &&
    `
    opacity:0;
    `};

  img {
    width: 30px;
    height: 30px;
  }
`

export const CloseButton = styled.button`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: var(--color-white);
  border-radius: 50px;

  img {
    width: 24px;
    height: 24px;
  }
`

export const FooterStyle = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 50px 0 50px;

  @media (max-width: 510px) {
    padding: 30px 20px 0 20px;
  }

  ${({ borderTop }) =>
    borderTop &&
    `
    margin-top: 50px;
    border-top: 2px solid var(--color-light);
    `};
`

export const BodyStyle = styled.div`
  flex-shrink: 0;

  ${({ center }) =>
    center &&
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 50px 50px 0 50px;
    @media (max-width: 510px) {
      padding: 50px 20px 0 20px;
    }
    `};
`

export const modalStyles = {
  overlay: {
    zIndex: '4',
  },
  content: {
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    padding: '50px 0',
    background: colors.dark,
    border: 'none',
  },
}
