import { handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  feedback: '',
}

const reducer = handleActions(
  {
    [types.SET_FEEDBACK]: (state, { content }) => ({
      ...state,
      feedback: content,
    }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
