import { types, async } from '~/utils/typeCreator'

export default types(
  [
    ...async('LOAD'),
    ...async('SEND_DRAFT'),
    ...async('SEARCH_USERS'),
    'SET_DRAFT',
    'CLEAR_STATE',
  ],
  'NOTICE_MANAGERS_AND_CONSULTANTS'
)
