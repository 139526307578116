import React, { useEffect, useRef, useState } from 'react'
import useOutsideClick from '~/hooks/useOutsideClick'

import { AccessibilityIcon, BrightnessIcon, LoopIcon } from '~/styles/Icons'

import { ModalInfo } from '~/components';
import useModal from '~/hooks/useModal';

import {
  DropdownStyle,
  MainButton,
  List,
  ButtonContainer,
  FontButton,
} from './styles'
import { useCookies } from 'react-cookie';

function AccessibilityDropdown({ name, ...props }) {
  let textMessage = 'Para aumentar ou diminuir a fonte no nosso site, utilize os atalhos Ctrl+ (para aumentar) e Ctrl- (para diminuir) no seu teclado.';
  const profileDropdownRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)
  const [modalInfoOpen, toggleModalInfo] = useModal(false)
  const [cookies, setCookie, removeCookie] = useCookies(['platform_theme'])

  useOutsideClick(profileDropdownRef, () => setIsOpen(false))

  useEffect(() => {
    if (cookies['platform_theme']) {
      document.body.setAttribute('data-theme', cookies.platform_theme)
    }
  }, [cookies])

  try {
    if (navigator?.userAgentData?.platform === 'macOS' || navigator?.platform.includes('Mac')) {
      textMessage = 'Para aumentar ou diminuir a fonte no nosso site, utilize os atalhos Command+ (para aumentar) e Command- (para diminuir) no seu teclado.'
    }
  } catch(e) {}

  const setDataTheme = (theme) => {
    if (theme) {
      setCookie('platform_theme', theme, { path: '/' })
      document.body.setAttribute('data-theme', theme)
    } else {
      removeCookie('platform_theme', { path: '/' })
      document.body.removeAttribute('data-theme')
    }
    setIsOpen(false)
  };

  return (
    <>
      <DropdownStyle {...props} ref={profileDropdownRef}>
        <MainButton
          aria-label="Abrir opções de acessibilidade"
          title="Abrir opções de acessibilidade"
          aria-haspopup="true"
          aria-expanded={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          {
            props.children ? props.children : (
              <img className='accessibility_invert' aria-hidden={true} alt="Acessibilidade" src={AccessibilityIcon} />
            )
          }
        </MainButton>
        <List className={`dropdown ${isOpen && ' opened'}`}>
          <span></span>
          <ul>
            <li>Acessibilidade</li>
            <li className='no-hover'>
              <div>
                Fonte
                <ButtonContainer>
                  <FontButton aria-label="Aumentar fonte da plataforma" onClick={() => {
                    setIsOpen(false)
                    toggleModalInfo()
                  }}>
                    A+
                  </FontButton>
                  <FontButton aria-label="Diminuir fonte da plataforma" onClick={() => {
                    setIsOpen(false)
                    toggleModalInfo()
                  }}>
                    A-
                  </FontButton>
                </ButtonContainer>
              </div>
            </li>
            <li onClick={() => setDataTheme('accessibility-light')}>
              <button onClick={() => setDataTheme('accessibility-light')} aria-label='Alto Contraste (Claro)'>
                <img className='accessibility_invert' alt="Alto Contraste (Claro)" width={18} height={18} src={BrightnessIcon} />
                Alto Contraste (Claro)
              </button>
            </li>
            <li onClick={() => setDataTheme('accessibility-dark')}>
              <button onClick={() => setDataTheme('accessibility-dark')} aria-label='Alto Contraste (Escuro)'>
                <img className='accessibility_invert' alt="Alto Contraste (Escuro)" width={18} height={18} src={BrightnessIcon} />
                Alto Contraste (Escuro)
              </button>
            </li>
            <li onClick={() => setDataTheme()}>
              <button onClick={() => setDataTheme()} aria-label='Redefinir'>
                <img className='accessibility_invert' alt="Redefinir" width={18} height={18} src={LoopIcon} />
                Redefinir
              </button>
            </li>
          </ul>
        </List>
      </DropdownStyle>
      <ModalInfo
        isOpen={modalInfoOpen}
        toggle={toggleModalInfo}
        message={textMessage}
        title={'Acessibilidade'}
      />
    </>
  )
}

export default AccessibilityDropdown
