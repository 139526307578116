import { useEffect } from 'react'
import useShallowEqualSelector from '~/hooks/useShallowEqualSelector'

export default function getNotice(resolve, redirect, { match, history }) {
  return () => {
    const notice = useShallowEqualSelector((state) => state.notice.notice)

    useEffect(() => {
      if (notice) {
        if (notice.status === 'DRAFT') {
          return history.push('/editais')
        }
      }
    }, [notice])

    return resolve()
  }
}
