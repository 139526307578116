import React, { Children, cloneElement, useRef } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

import fonts from '~/utils/fonts'
import colors from '~/utils/colors'

import useEscListener from '~/hooks/useEscListener'
import useOutsideClick from '~/hooks/useOutsideClick'
import useScrollLock from '~/hooks/useScrollLock'

import {
  BodyStyle,
  CloseButton,
  FooterStyle,
  HeaderIcon,
  HeaderStyle,
  ModalContainer,
  ModalContent,
  modalStyles,
} from './styles'

import { CloseIcon } from '~/styles/Icons'

import { H3 } from '~/components'

ReactModal.setAppElement(`#root`)
function Modal(props) {
  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.toggle}
      style={modalStyles}
      id={props.id}
    >
      <ModalComponent {...props} />
    </ReactModal>
  )
}

function ModalComponent(props) {
  const toggle = props.toggle
  const modalRef = useRef()

  useEscListener(toggle)
  useOutsideClick(modalRef, toggle)
  useScrollLock()

  return (
    <ModalContainer className="container" ref={modalRef}>
      <ModalContent lg={props.lg} md={props.md}>
        {Children.map(props.children, (child) =>
          cloneElement(child, { toggle: toggle, ...child.props })
        )}
      </ModalContent>
    </ModalContainer>
  )
}

function Header(props) {
  return (
    <HeaderStyle type={props.type} className='header'>
      <HeaderIcon noIcon={!props.icon}>
        <img src={props.icon} alt="" aria-hidden />
      </HeaderIcon>
      <H3
        font={fonts.displayXBold}
        color={
          (props.type === 'normal' && colors.dark) ||
          (props.type === 'warning' && colors.white) ||
          (props.type === 'danger' && colors.white)
        }
        className="text-center"
      >
        {props.title}
      </H3>
      <CloseButton
        onClick={() => {
          props.toggle()
          if (props.onClose) return props.onClose()
        }}
        className='close'
        aria-label="Fechar caixa de diálogo"
      >
        <img className='accessibility_invert' src={CloseIcon} alt="" aria-hidden />
      </CloseButton>
    </HeaderStyle>
  )
}

function Footer({ borderTop, ...props }) {
  return (
    <FooterStyle borderTop={borderTop} {...props}>
      {props.children}
    </FooterStyle>
  )
}

function Body(props) {
  return <BodyStyle {...props}>{props.children}</BodyStyle>
}

Modal.propTypes = {
  id: PropTypes.string,
  lg: PropTypes.bool,
  md: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

Modal.defaultProps = {
  lg: true,
  md: false,
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  type: PropTypes.oneOf(['normal', 'warning', 'danger']),
}

Header.defaultProps = {
  type: 'normal',
  onClick: () => null,
}

Footer.propTypes = {
  borderTop: PropTypes.bool.isRequired,
}

Footer.defaultProps = {
  borderTop: false,
}

Body.propTypes = {
  center: PropTypes.bool.isRequired,
}

Body.defaultProps = {
  center: true,
}

Modal.Header = Header
Modal.Body = Body
Modal.Footer = Footer

export default Modal
