import styled from 'styled-components'

export const Content = styled.span`
  display: inline-block;
  text-align: center;
  line-height: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  width: ${({ size }) => size + 'px'};
  background: ${({ color }) => color};
  border-radius: 50%;
  font-size: ${({ fontSize }) => fontSize + 'px'};
`
