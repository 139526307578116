import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Content } from './styles'

import { UploadIcon } from '~/styles/Icons'

function InputFile({ buttonClassName, className, ...props }) {
  const inputRef = useRef(null)

  const onClickSpan = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }, [])

  const onChange = useCallback(
    (e) => {
      if (props.onChange) {
        props.onChange(e)
      }
      if (inputRef && inputRef.current) {
        inputRef.current.value = ''
      }
    },
    [props]
  )

  return (
    <Content
      className={classnames(className, { active: props.active })}
      block={props.block}
    >
      <input
        type="file"
        {...props}
        tabIndex={0}
        active={null}
        ref={inputRef}
        onChange={onChange}
      />
      <span onClick={onClickSpan} className={classnames(buttonClassName, 'input-file')}>
        <img src={UploadIcon} aria-hidden alt="" />
        Escolher arquivo
      </span>
    </Content>
  )
}

InputFile.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string.isRequired,
  block: PropTypes.bool,
}

export default InputFile
