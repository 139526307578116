import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { HelpCircleDarkIcon } from '~/styles/Icons'
import { Row, Label, Box, T4 } from './styles'

function LabelInfo(props) {
  const labelEl = useRef(null)
  const [showBox, onClickHelper] = useState(false)

  useEffect(() => {
    function handleClickOutside(event) {
      if (labelEl.current && !labelEl.current.contains(event.target)) {
        return onClickHelper(false)
      }
    }
    if (showBox) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showBox, labelEl])

  return (
    <Row
      className={classnames(props.className)}
      style={props.style}
      size={props.size || 20}
    >
      <Label htmlFor={props.id} ref={labelEl}>
        {props.label}
      </Label>
      <span onClick={() => onClickHelper(!showBox)}>
        <img src={HelpCircleDarkIcon} aria-hidden alt="helper-icon" className='accessibility_invert' />
      </span>
      {showBox && (
        <Box className='input-file' style={{ marginBottom: labelEl.current.offsetHeight + 10 }}>
          <T4>{props.content}</T4>
        </Box>
      )}
    </Row>
  )
}

LabelInfo.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  content: PropTypes.string,
}

/* <LabelInfo
  content="Informação aberta do tooltip lorem ipsum dolor sit amt"
  label="Tipo do edital"
  className="mb-2"
/> */

export default LabelInfo
