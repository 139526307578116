import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

function Container(props) {
  return <div {...props} className={classnames('container', props.className)} />
}

Container.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Container
