export const cellphone = (v) => {
  const maskedString = v
    .slice(0, 15)
    .replace(/[^\d*]/g, '')
    .replace(/^([\d*][\d*])([\d*])/g, '($1) $2')
    .replace(/([\d*]{4,5})([\d*]{4})$/, '$1-$2')
  const string = maskedString.replace(/[^\d*]+/g, '')
  return { string, maskedString }
}

export const cpf = (v) => {
  const maskedString = v
    .slice(0, 14)
    .replace(/[^\d*]/g, '')
    .replace(/([\d*]{3})([\d*])/, '$1.$2')
    .replace(/([\d*]{3})([\d*])/, '$1.$2')
    .replace(/([\d*]{3})([\d*]{1,2})$/, '$1-$2')
  const string = maskedString.replace(/[^\d*]+/g, '')
  return { string, maskedString }
}

export const cep = (v) => {
  const maskedString = v
    .slice(0, 9)
    .replace(/[^\d*]+/g, '')
    .replace(/^([\d*]{5})([\d*])/, '$1-$2')
  const string = maskedString.replace(/[^\d*]+/g, '')
  return { string, maskedString }
}

export const cnpj = (v) => {
  const maskedString = v
    .slice(0, 18)
    .replace(/[^\d*]/g, '')
    .replace(/([\d*]{2})([\d*])/, '$1.$2')
    .replace(/([\d*]{3})([\d*])/, '$1.$2')
    .replace(/([\d*]{3})([\d*])/, '$1/$2')
    .replace(/([\d*]{4})([\d*]{1,2})$/, '$1-$2')
  const string = maskedString.replace(/[^\d*]+/g, '')
  return { string, maskedString }
}

export const date = (v) => {
  const maskedString = v
    .slice(0, 10)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d{2})$/, '$1$2')
  const string = maskedString.replace(/\D+/g, '')
  return { string, maskedString }
}

export const money = (v) => {
  const toNumber = Number(v.replace('.', '').replace(/\D/g, '')) / 100
  const maskedString = toNumber
    ? toNumber.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
      })
    : ''
  return {
    string: v !== '*****' ? v.replace(/\./g, '').replace(/,/g, '') : v,
    maskedString: v !== '*****' ? String(maskedString) : v,
  }
}

export const time = (v) => {
  const maskedString = v
    .slice(0, 5)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1:$2')
    .replace(/(\d{2})(\d)/, '$1')
  const string = maskedString.replace(/\D+/g, '')
  return { string, maskedString }
}

export const percent = (v) => {
  const maskedString = v.slice(0, 3).replace(/[^\d*]/g, '')
  const string = maskedString.replace(/[^\d*]/g, '')
  return { string, maskedString }
}

export const number = (v) => {
  const maskedString = v.replace(/[^\d*]/g, '')
  return { string: maskedString, maskedString }
}

export const numberAndX = (v) => {
  const maskedString = String(v.replace(/[^0-9Xx*]/g, '')).toLocaleUpperCase()
  return { string: maskedString, maskedString }
}

export function replaceSpecialChars(str) {
  return str.normalize('NFD').replace(/[^a-z0-9]/gi, '')
}
