import { combineActions, handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  users: [],
  pageIndex: 0,
  pageSize: 10,
  pageCount: 1,
  resultsCount: 0,
}

const beginLoading = combineActions(types.SEARCH_USERS)

const stopLoading = combineActions(
  types.SEARCH_USERS_SUCCESS,
  types.SEARCH_USERS_FAIL,
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.SEARCH_USERS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: result },
        },
      }
    ) => ({
      ...state,
      users: result.users,
      filterCount: result.filterCount,
      pageCount: result.pageCount,
    }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
