import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import colors from '~/utils/colors'

import {
  ButtonStyle,
  ContentButton,
  ContentLink,
  ContentLinkExternal,
  LinkExternalStyle,
  LinkStyle,
} from './styles'

function ButtonCircleIcon({
  className,
  style,
  icon,
  iconHover,
  right,
  children,
  active,
  ...props
}) {
  return (
    <ContentButton
      className={className}
      style={style}
      noText={!children}
      right={right}
    >
      <ButtonStyle
        {...props}
        className={classnames('button', { active: active })}
        right={right}
      >
        {right && children}
        {icon && iconHover && (
          <span className='icon'>
            <img src={icon} aria-hidden alt="" />
            <img src={iconHover} aria-hidden alt="" />
          </span>
        )}
        {!right && children}
      </ButtonStyle>
    </ContentButton>
  )
}

function ButtonCircleIconLink({
  className,
  style,
  icon,
  iconHover,
  right,
  children,
  active,
  ...props
}) {
  return (
    <ContentLink
      className={className}
      style={style}
      noText={!children}
      right={right}
    >
      <LinkStyle {...props} className={classnames({ active: active })}>
        {right && children}
        {icon && iconHover && (
          <span>
            <img src={icon} aria-hidden alt="" />
            <img src={iconHover} aria-hidden alt="" />
          </span>
        )}
        {!right && children}
      </LinkStyle>
    </ContentLink>
  )
}
function ButtonCircleIconLinkExternal({
  className,
  style,
  icon,
  iconHover,
  right,
  children,
  active,
  ...props
}) {
  return (
    <ContentLinkExternal
      className={className}
      style={style}
      noText={!children}
      right={right}
    >
      <LinkExternalStyle {...props} className={classnames({ active: active })}>
        {right && children}
        {icon && iconHover && (
          <span>
            <img src={icon} aria-hidden alt="" />
            <img src={iconHover} aria-hidden alt="" />
          </span>
        )}
        {!right && children}
      </LinkExternalStyle>
    </ContentLinkExternal>
  )
}

ButtonCircleIcon.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string,
  children: PropTypes.string,
  iconHover: PropTypes.string,
  icon: PropTypes.string,
  right: PropTypes.bool,
  active: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  coloractive: PropTypes.string,
}

ButtonCircleIconLink.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.string,
  iconHover: PropTypes.string,
  icon: PropTypes.string,
  right: PropTypes.bool,
  active: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  coloractive: PropTypes.string,
}

ButtonCircleIconLinkExternal.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.string,
  iconHover: PropTypes.string,
  icon: PropTypes.string,
  right: PropTypes.bool,
  active: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  coloractive: PropTypes.string,
}

ButtonCircleIcon.defaultProps = {
  type: 'button',
  color: colors.yellow,
  coloractive: colors.dark,
}

ButtonCircleIconLink.defaultProps = {
  color: colors.yellow,
  coloractive: colors.dark,
}

ButtonCircleIconLinkExternal.defaultProps = {
  color: colors.yellow,
  coloractive: colors.dark,
}

export { ButtonCircleIcon, ButtonCircleIconLink, ButtonCircleIconLinkExternal }
