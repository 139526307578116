import styled from 'styled-components'
import colors from '~/utils/colors'

import { RadioOnIcon, RadioOnWhiteIcon, RadioOnCheckIcon } from '~/styles/Icons'

export const Content = styled.div`
  display: flex;
  align-items: center;

  > label {
    margin-left: 10px;
    font-family: 'Itau Display Regular';
    font-size: 16px;
    line-height: 21px;
  }

  > input {
    position: relative;
    cursor: pointer;

    min-width: 30px;
    width: 30px;
    height: 30px;
  }

  > input:before {
    content: '';
    display: block;
    position: absolute;

    top: 0;
    left: 0;

    width: 30px;
    height: 30px;

    ${({ light, background }) =>
      light
        ? `
          background-color: ${background ? background : colors.light};
          `
        : `
          background-color: ${background ? background : colors.white};
          `}
  }

  > input:after {
    content: '';
    display: block;
    position: absolute;
    overflow: hidden;

    top: 0;
    left: 0;

    width: 30px;
    height: 30px;

    flex-shrink: 0;
    appearance: none;
    width: 30px;
    height: 30px;
    border: 5px solid;
    border-radius: 50px;

    ${({ light, background }) =>
      light
        ? `
          border-color: var(--color-white);
          background-color: ${background ? background : colors.light};
          `
        : `
          border-color: var(--color-light);
          background-color: ${background ? background : colors.white};
          `}

    ${({ borderColor }) => borderColor && `border-color: ${borderColor};`}
  }

  > input:hover:after,
  > input:focus:after,
  &.active input {
    ${({ dark, disabled }) =>
      dark
        ? `border-color: var(--color-dark);`
        : `border-color: ${disabled ? colors.light : colors.yellow};`}

    ${({ borderColor }) => borderColor && `border-color: ${borderColor};`}
  }

  > input:checked:after {
    border: none;
    ${({ check, light }) =>
      check
        ? `
          background-image: url(${RadioOnCheckIcon});
          `
        : light
        ? `
          background-image: url(${RadioOnWhiteIcon});
          `
        : `
          background-image: url(${RadioOnIcon});
          `}
    background-repeat: no-repeat;
    background-size: 30px 30px;

    ${({ checkedColor }) =>
      checkedColor &&
      `
      border: 5px solid;
      border-radius: 50px;
      background-image: none;
      border-color: ${checkedColor};
      background-color: ${checkedColor};
      `}
  }

  > input::-ms-check {
    border: 5px solid;
    border-radius: 50px;
    flex-shrink: 0;

    color: transparent;

    ${({ light }) =>
      light
        ? `
          border-color: var(--color-white);
          `
        : `
          border-color: var(--color-light);
          `}
  }

  > input:hover::-ms-check,
  > input:focus::-ms-check,
  &.active input::-ms-check {
    ${({ dark }) =>
      dark
        ? `
          border-color: var(--color-dark);
          `
        : `
          border-color: var(--color-yellow);
          `}
  }

  > input:checked::-ms-check {
    border: none;
    ${({ light }) =>
      light
        ? `
          border-color: var(--color-white);
          background-image: url(${RadioOnWhiteIcon});
          `
        : `
          border-color: var(--color-yellow);
          background-image: url(${RadioOnIcon});
          `}
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: -5px;
  }
`
