import { handleActions, combineActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  id: null,
  documents: [],
  noticeDocuments: [],
  description: '',
  horusIntegration: false,
}

const beginLoading = combineActions(
  types.LOAD_DOCUMENT_ANALYSIS,
  types.LOAD_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS,
  types.UPDATE_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS,
  types.LOAD_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DOCUMENTS,
  types.UPDATE_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DOCUMENTS
)

const stopLoading = combineActions(
  types.LOAD_DOCUMENT_ANALYSIS_SUCCESS,
  types.LOAD_DOCUMENT_ANALYSIS_FAIL,
  types.LOAD_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_SUCCESS,
  types.LOAD_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_FAIL,
  types.UPDATE_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_SUCCESS,
  types.UPDATE_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_FAIL,
  types.LOAD_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DOCUMENTS_SUCCESS,
  types.LOAD_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DOCUMENTS_FAIL,
  types.UPDATE_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DOCUMENTS_SUCCESS,
  types.UPDATE_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DOCUMENTS_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.LOAD_DOCUMENT_ANALYSIS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      documents: data,
      isLoading: false,
    }),
    [types.LOAD_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      id: data.id,
      description: data.description,
      horusIntegration: data.horusIntegration,
      isLoading: false,
    }),
    [types.LOAD_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DOCUMENTS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      noticeDocuments: data,
      isLoading: false,
    }),
    [types.UPDATE_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      horusIntegration: data?.horusIntegration,
      isLoading: false,
    }),
    [types.UPDATE_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DOCUMENTS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      }
    ) => ({
      ...state,
      noticeDocuments: data,
      isLoading: false,
    }),
    [types.SET_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_DESCRIPTION]: (
      state,
      { description }
    ) => ({
      ...state,
      description,
    }),
    [types.SET_NOTICE_PROCESS_STEPS_DOCUMENT_ANALYSIS_HORUS_INTEGRATION]: (
      state,
      { horusIntegration }
    ) => ({
      ...state,
      horusIntegration,
    }),
    [types.CLEAR_STATE]: () => {
      return { ...INITIAL_STATE }
    },
  },
  INITIAL_STATE
)

export default reducer
