import React, { memo, useCallback, useMemo, useState } from 'react'

import fonts from '~/utils/fonts'
import { ButtonCircleIcon, Collapsible, HorizontalRule, T3 } from '~/components'
import {
  ArrowDownIcon,
  ArrowDownLightIcon,
  ArrowUpIcon,
  ArrowUpLightIcon,
} from '~/styles/Icons'

import { FilterHeader, FilterStyle } from './styles'


function Filter({
  children,
  title,
  params,
  setParams,
  paramName,
  initialOpen,
  hide,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(initialOpen)

  const setParam = useCallback(
    (value) => setParams((prev) => ({ ...prev, [paramName]: value })),
    [paramName, setParams]
  )

  const childrenProps = useMemo(
    () =>
      paramName
        ? {
            param: params[paramName],
            setParam,
          }
        : {
            params,
            setParams,
          },
    [params, setParams, paramName, setParam]
  )

  return !hide ? (
    <FilterStyle {...props}>
      <FilterHeader>
        <T3 font={fonts.displayXBold}>{title}</T3>
        {!isOpen ? (
          <ButtonCircleIcon
            aria-label="Expandir filtro"
            right
            icon={ArrowDownIcon}
            iconHover={ArrowDownLightIcon}
            onClick={() => setIsOpen(true)}
          >
            Expandir
          </ButtonCircleIcon>
        ) : (
          <ButtonCircleIcon
            aria-label="Fechar filtro"
            right
            icon={ArrowUpIcon}
            iconHover={ArrowUpLightIcon}
            onClick={() => setIsOpen(false)}
          >
            Fechar
          </ButtonCircleIcon>
        )}
      </FilterHeader>
      <Collapsible
        open={isOpen}
        transitionTime={50}
        /* contentHiddenWhenClosed={true} bug in production */
        /*  overflowWhenOpen={'visible'} */
      >
        <HorizontalRule height={5} color={'light'} />
        {typeof children === 'function' ? children(childrenProps) : children}
      </Collapsible>
    </FilterStyle>
  ) : null
}

export default memo(Filter)
