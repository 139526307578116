import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import colors from '~/utils/colors'
import { PlusIcon, MinusIcon } from '~/styles/Icons'

export const TabsMenuStyle = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitleStyle = styled.div`
  margin: 0 0 15px 20px;
  color: var(--color-darkGray);
  font-family: 'Itau Display Regular';
  font-size: 16px;
  line-height: 21px;
`

const LinkCSS = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-dark);
  font-family: 'Itau Display Regular';
  font-size: 18px;
  line-height: 23px;
  border-left: 5px solid transparent;
  padding: 15px 10px;

  > span {
    background: var(--color-white);
    border-radius: 50px;
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;

    > img {
      height: 30px;
      width: 30px;
    }
  }

  &:hover,
  &:focus,
  &:link,
  &:visited,
  &:active {
    color: var(--color-dark);
    text-decoration: none;
  }

  &:focus,
  &:hover,
  &.hover {
    > span {
      background: var(--color-yellow);
    }
  }

  &.active {
    border-left-color: var(--color-dark);
    background: linear-gradient(
      90deg,
      var(--color-white) 95%,
      var(--color-light) 99%,
      rgba(243, 237, 223, 0) 100%
    );

    > span {
      background: var(--color-yellow);
    }
  }

  &.disabled {
    pointer-events: none;
  }
`

export const LinkStyle = styled(NavLink)`
  ${LinkCSS}
`

export const ExpandIcon = styled.span``

export const LinkExpansiveStyle = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-dark);
  font-family: 'Itau Display Regular';
  font-size: 18px;
  line-height: 23px;
  border-left: 5px solid transparent;
  padding: 15px 10px;

  > span {
    background: var(--color-white);
    border-radius: 50px;
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;

    > img {
      height: 30px;
      width: 30px;
    }
  }

  width: 100%;

  > ${ExpandIcon} {
    background: var(--color-white);
    border-radius: 50px;
    height: 15px;
    width: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

    background-image: url(${PlusIcon});
    background-repeat: no-repeat;
    background-size: 15px 15px;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    > ${ExpandIcon} {
      background: var(--color-yellow);
      background-image: url(${MinusIcon});
      background-repeat: no-repeat;
      background-size: 15px 15px;
    }

    border-left-color: var(--color-dark);
    background: linear-gradient(
      90deg,
      var(--color-white) 95%,
      var(--color-light) 99%,
      rgba(243, 237, 223, 0) 100%
    );

    > span {
      background: var(--color-yellow);
    }
  `}

  ${({ isSubPage, isOpen }) =>
    !isSubPage &&
    `
    > ${ExpandIcon} {
      background: var(--color-white);
      background-image: url(${MinusIcon});
      background-repeat: no-repeat;
      background-size: 15px 15px;
    }

    border-left-color: transparent;
    background: none;

    > span {
      background: var(--color-white);
    }

    &:hover,
    &:focus {
      > span {
        background: var(--color-white);
      }
      ${
        isOpen &&
        `
        > ${ExpandIcon} {
          background: var(--color-white);
          background-image: url(${MinusIcon});
          background-repeat: no-repeat;
          background-size: 15px 15px;
        }
      `
      }
    }
  `}

  &:hover,
    &:focus {
    > span {
      background: var(--color-yellow);
    }

    ${({ isOpen, isSubPage }) =>
      (isOpen &&
        `
          > ${ExpandIcon} {
            background: ${isSubPage ? colors.yellow : colors.white};
            background-image: url(${MinusIcon});
            background-repeat: no-repeat;
            background-size: 15px 15px;
          }
          `) ||
      `
          > ${ExpandIcon} {
            background: var(--color-white);
            background-image: url(${PlusIcon});
            background-repeat: no-repeat;
            background-size: 15px 15px;
          }
      `}
  }
`

export const LinkExpansiveItemStyle = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  color: var(--color-darkGray);
  font-family: 'Itau Display Regular';
  font-size: 14px;
  line-height: 18px;
  border-left: 3px solid var(--color-yellow);
  padding: 5px 0px 7px 36px;
  border-radius: 0 50px 50px 0;

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: var(--color-light);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 50px;
    height: 100%;
    width: fit-content;
    min-width: 45px;
    padding: 6px;

    color: var(--color-dark);
    font-family: 'Itau Text XBold';
    font-size: 14px;
    line-height: 18px;
  }

  &:link,
  &:visited,
  &:active {
    text-decoration: none;
  }

  &:focus,
  &:hover,
  &.hover,
  &.active {
    text-decoration: none;
    color: var(--color-dark);
    background: var(--color-yellow);
    border-left-color: var(--color-dark);

    > span {
      background: var(--color-dark);
      color: var(--color-white);
    }
  }

  & + & {
    margin-top: 9px;
  }
`

export const ExpandList = styled.div`
  display: none;
  flex-direction: column;
  border-left: 5px solid transparent;
  padding: 0px 20px 20px 30px;

  ${({ isOpen }) =>
    isOpen &&
    `
    padding-top: 5px;
    display: flex;
    border-left-color: var(--color-dark);
    background: linear-gradient(
      90deg,
      var(--color-white) 95%,
      var(--color-light) 99%,
      rgba(243, 237, 223, 0) 100%
    );
  `}

  ${({ isSubPage }) =>
    !isSubPage &&
    `
    border-left-color: transparent;
    background: none;

    ${LinkExpansiveItemStyle} {
      border-left-color: var(--color-white);

      > span {
        background: var(--color-white);
      }

      &:focus,
      &:hover,
      &.hover,
      &.active {
        text-decoration: none;
        color: var(--color-dark);
        background: var(--color-yellow);
        border-left-color: var(--color-dark);

        > span {
          background: var(--color-dark);
          color: var(--color-white);
        }
      }
    }
  `}
`
