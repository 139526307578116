const colors = {
  black: '#000000',
  white: '#FFFFFF',
  dark: '#2A2A2A',
  darkGray: '#666666',
  gray: '#999999',
  lightGray: '#D8D8D8',
  yellow: '#FCDF9B',
  light: '#F3EDDF',
  blue: '#6B95BF',
  red: '#B53A3A',
  green: '#63A142',
  orange: '#E68E00',
  transparent: 'transparent',
  accessibilityLink: '#fcc419',
  accessibilityGrey: '#808080',
  percentBarColor1: '#294e73',
  percentBarColor2: '#6B95BF',
  percentBarColor3: '#A0C9F2',
  percentBarColor4: '#D9ECFF',
  verticalLineBlack: '#000000',
  verticalLineGray: '#999999',
  percentBarBg: '#F3EDDF',
  percentBarFg: '#000000',
  percentBarComplete: '#000000',
}

export default colors
