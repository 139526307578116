import React, { memo, useCallback, useMemo } from 'react'

function Filter({
  children,
  params,
  setParams,
  setParamsCount,
  paramName,
  onRemove,
  hide,
}) {
  const setParam = useCallback(
    (value) => setParams((prev) => ({ ...prev, [paramName]: value })),
    [paramName, setParams]
  )

  const setParamCount = useCallback(
    (count) => {
      setParamsCount((prev) => ({ ...prev, [paramName]: count }))
    },
    [paramName, setParamsCount]
  )

  const onRemoveCb = useCallback(
    (cb, param) => {
      onRemove({ ...params, [paramName]: param }, cb)
    },
    [onRemove, params, paramName]
  )

  const childrenProps = useMemo(
    () => ({
      param: params[paramName],
      setParam,
      setParamCount,
      onRemove: onRemoveCb,
    }),
    [params, paramName, onRemoveCb, setParam, setParamCount]
  )

  return typeof children === 'function' && params[paramName] && !hide ? (
    <>{children(childrenProps)}</>
  ) : null
}

export default memo(Filter)
