import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import locale from 'date-fns/locale/pt-BR'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import { Container, DatePicker as SDatePicker} from './styles'

const customTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#FCDF9B',
		},
		secondary: {
			main: '#FCDF9B',
		},
	},
})

function DatePicker(props) {
  return (
    <MuiThemeProvider theme={customTheme}>
      <Container>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <SDatePicker
            inputVariant="outlined"
            minDate={props.minDate}
            disabled={props.disabled}
            maxDate={props.maxDate}
            margin="normal"
            id={`date-picker-dialog ${props.id}`}
            name={props.name}
            error={false}
            helperText={``}
            inputProps={{ disabled: true, placeholder: "00/00/0000" }}
            format={props.format || 'dd/MM/yyyy'}
            InputLabelProps={{ variant: 'standard' }}
            onChange={(date) => props.onChange && props.onChange(date)}
            value={props.value}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            onSelect={(date) => props.onSelect && props.onSelect(date)}
          />
        </MuiPickersUtilsProvider>
      </Container>
    </MuiThemeProvider>
  )
}

export default DatePicker
