import styled from 'styled-components'


export const SkipLinkStyle = styled.a`
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;

  &:focus {
    background-color: var(--color-white);
    border-radius: 5px;
    box-shadow: 0 0 2px 2px rgba(102, 102, 102, 0.05);
    clip: auto !important;
    color: var(--color-dark);
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
  }
`
