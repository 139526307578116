import { combineActions, handleActions } from 'redux-actions'
import types from './types'

const INITIAL_STATE = {
  isLoading: true,
  subscriptions: [],
  pageIndex: 1,
  pageSize: 1,
  pageCount: 1,
  resultsCount: 1,
  stats: null,
  total: 1,
  subsSelected: [],
  subsSelectedAll: false,
}

const beginLoading = combineActions(types.SEARCH_SUBSCRIPTIONS, types.STATS)

const stopLoading = combineActions(
  types.SEARCH_SUBSCRIPTIONS_SUCCESS,
  types.SEARCH_SUBSCRIPTIONS_FAIL,
  types.STATS_SUCCESS,
  types.STATS_FAIL
)

const reducer = handleActions(
  {
    [beginLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.SEARCH_SUBSCRIPTIONS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: result },
        },
      }
    ) => ({
      ...state,
      subscriptions: result.subscriptions,
      pageIndex: result.pageIndex,
      pageSize: result.pageSize,
      pageCount: result.pageCount,
      resultsCount: result.resultsCount,
      total: result.total,
      subsSelected: result.subsSelected,
      subsSelectedAll: result.subsSelectedAll,
    }),
    [types.STATS_SUCCESS]: (
      state,
      {
        payload: {
          data: { data: stats },
        },
      }
    ) => ({
      ...state,
      stats,
    }),
    [types.SET_SUBSCRIPTIONS_SELECTED]: (
      state,
      { subscriptions, subsSelected }
    ) => ({
      ...state,
      subscriptions: subscriptions,
      subsSelected: subsSelected,
    }),
    [types.CLEAR_STATE]: () => INITIAL_STATE,
  },
  INITIAL_STATE
)

export default reducer
