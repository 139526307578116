import React from 'react'
const StyleGuide = React.lazy(() => import('./'))

const styleGuideRoutes = [
  {
    path: '/style-guide',
    component: StyleGuide,
    exact: true,
  },
]

export default styleGuideRoutes
