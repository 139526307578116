import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'



export const Content = styled.div`
  position: relative;
`

export const List = styled.div`
  position: absolute;
  bottom: 0;
  top: 30px;
  z-index: 2;
  margin-top: 0px;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding-top: 3px;
  min-width: ${({ width }) => width + 'px'};
  width: fit-content;

  ul {
    list-style: none;
    padding: 15px 10px 15px 10px;
    margin: 0;
    background: var(--color-dark);
    border-radius: 10px;
  }

  li + li {
    margin-top: 3px;
    width: 100%;
  }
`

const OptionStyle = css`
  display: flex;
  flex-direction: row;
  color: var(--color-white);
  font-size: 14px;
  line-height: 18px;
  padding: 8px 10px;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  text-align: left;

  > span {
    margin-left: 10px;
    color: var(--color-white);
    text-decoration: underline;
  }

  &:hover,
  &:focus,
  &.active {
    color: var(--color-dark);
    background: var(--color-yellow);

    > span {
      color: var(--color-dark);
    }

    img {
      :nth-child(1) {
        display: none;
      }
      :nth-child(2) {
        display: initial;
      }
    }
  }
`

export const LinkStyle = styled(Link)`
  ${OptionStyle}
`

export const ExternalLinkStyle = styled.a`
  ${OptionStyle}
`

export const ButtonStyle = styled.button`
  ${OptionStyle}
`

export const ArrowStyle = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 5px;
`

export const MainButton = styled.button`
  position: relative;
  width: 54px;
  > img {
    position: absolute;
    top: 3px;
    right: 0px;
  }
`
