import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { Content } from './styles'

function Radio({
  className,
  style,
  active,
  light,
  background,
  check,
  children,
  inputStyle,
  borderColor,
  checkedColor,
  labelStyle,
  ...props
}) {
  return (
    <Content
      className={classnames(className, { active: props.active })}
      style={style}
      light={light}
      background={background}
      borderColor={borderColor}
      checkedColor={checkedColor}
      check={check}
      disabled={props.disabled}
      defaultChecked={props.defaultChecked}
    >
      <input type="radio" {...props} style={inputStyle} />
      {children ? <label htmlFor={props.id} style={labelStyle}>{children}</label> : null}
    </Content>
  )
}

Radio.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  light: PropTypes.bool,
  background: PropTypes.string,
  borderColor: PropTypes.string,
  checkedColor: PropTypes.string,
  check: PropTypes.bool,
}

export default Radio
